import {
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core'
import React, { useState, useEffect, useContext } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import PlataformaPadrao from '../../components/plataforma-padrao'
import api from '../../services/api'
import { UserContext } from '../../contexts/user'
import './styles.css'

import Sol from '../../assets/sol_sol_chuva.png'
import Chuva from '../../assets/chuva_sol_chuva.png'

export default function FormularioSolChuva() {
  const { user } = useContext(UserContext)
  const history = useHistory()
  const [segunda, setSegunda] = useState(0)
  const [terca, setTerca] = useState(0)
  const [quarta, setQuarta] = useState(0)
  const [quinta, setQuinta] = useState(0)
  const [sexta, setSexta] = useState(0)
  const [sabado, setSabado] = useState(0)
  const [domingo, setDomingo] = useState(0) //0 = sol (sem xixi) e 1 = chuva (xixi)
  const [data_inicial, setDataInicial] = useState([])
  const [idPaciente, setIdPaciente] = useState('')

  let { id } = useParams()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getData() {
      await api.get(`sol_chuva/${id}`).then((response) => {
        setSegunda(response.data.segunda.toString())
        setTerca(response.data.terca.toString())
        setQuarta(response.data.quarta.toString())
        setQuinta(response.data.quinta.toString())
        setSexta(response.data.sexta.toString())
        setSabado(response.data.sabado.toString())
        setDomingo(response.data.domingo.toString())
        setDataInicial(response.data.data_inicial.toString())
        setIdPaciente(response.data.id_paciente)
        api.get(`paciente/${response.data.id_paciente}`).then(({ data }) => {
          if (
            user.id_global === response.data.id_paciente ||
            user.id_global === data.id_pesquisador
          ) {
            setLoading(false)
          } else {
            history.push(`/sem-permissao`)
          }
        })
      })
    }
    getData()
  }, [])

  async function handleSubmit(e) {
    e.preventDefault()

    const data = {
      segunda,
      terca,
      quarta,
      quinta,
      sexta,
      sabado,
      domingo,
      data_inicial,
    }

    await api
      .put(`sol_chuva/${id}`, data)
      .then(() => {
        api
          .post('sol_chuva_warn', { id_paciente: idPaciente })
          .catch((error) => {
            console.log(error)
            alert(
              'Não foi possível notificar a(o) enfermeira(o), contate a administração'
            )
          })
      })
      .catch((error) => {
        console.log(error)
        alert('Erro! Tente novamente.')
      })

    alert('Operação realizada com sucesso.')
    history.goBack()
  }

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  return (
    <PlataformaPadrao
      title="Sol e Chuva"
      legend="Para cada dia da semana, selecione Sol (se não fez xixi) ou Chuva (se fez xixi)."
    >
      <p id="datainicial">
        Semana do dia: {new Date(data_inicial).getDate()}/
        {new Date(data_inicial).getMonth() + 1}
      </p>
      <div className="imagens-legenda">
        <div className="imagens-legenda-sol">
          <img className="imagem_copo" src={Sol} thumbnail />
          <p>Não fez xixi na cama!</p>
        </div>
        <div className="imagens-legenda-sol">
          <img className="imagem_copo" src={Chuva} thumbnail />
          <p>Fez xixi na cama!</p>
        </div>
      </div>
      <Form>
        <FormControl component="fieldset">
          <FormLabel component="legend">Segunda-Feira</FormLabel>
          <RadioGroup
            aria-label="segunda"
            name="segunda"
            value={segunda}
            onChange={(e) => setSegunda(e.target.value)}
          >
            <FormControlLabel value="0.1" control={<Radio />} label="Sol" />
            <FormControlLabel value="1" control={<Radio />} label="Chuva" />
          </RadioGroup>
          <FormLabel component="legend">Terça-Feira</FormLabel>
          <RadioGroup
            aria-label="terca"
            name="terca"
            value={terca}
            onChange={(e) => setTerca(e.target.value)}
          >
            <FormControlLabel value="0.1" control={<Radio />} label="Sol" />
            <FormControlLabel value="1" control={<Radio />} label="Chuva" />
          </RadioGroup>
          <FormLabel component="legend">Quarta-Feira</FormLabel>
          <RadioGroup
            aria-label="quarta"
            name="quarta"
            value={quarta}
            onChange={(e) => setQuarta(e.target.value)}
          >
            <FormControlLabel value="0.1" control={<Radio />} label="Sol" />
            <FormControlLabel value="1" control={<Radio />} label="Chuva" />
          </RadioGroup>
          <FormLabel component="legend">Quinta-Feira</FormLabel>
          <RadioGroup
            aria-label="quinta"
            name="quinta"
            value={quinta}
            onChange={(e) => setQuinta(e.target.value)}
          >
            <FormControlLabel value="0.1" control={<Radio />} label="Sol" />
            <FormControlLabel value="1" control={<Radio />} label="Chuva" />
          </RadioGroup>
          <FormLabel component="legend">Sexta-Feira</FormLabel>
          <RadioGroup
            aria-label="sexta"
            name="sexta"
            value={sexta}
            onChange={(e) => setSexta(e.target.value)}
          >
            <FormControlLabel value="0.1" control={<Radio />} label="Sol" />
            <FormControlLabel value="1" control={<Radio />} label="Chuva" />
          </RadioGroup>
          <FormLabel component="legend">Sábado</FormLabel>
          <RadioGroup
            aria-label="sabado"
            name="sabado"
            value={sabado}
            onChange={(e) => setSabado(e.target.value)}
          >
            <FormControlLabel value="0.1" control={<Radio />} label="Sol" />
            <FormControlLabel value="1.1" control={<Radio />} label="Chuva" />
          </RadioGroup>
          <FormLabel component="legend">Domingo</FormLabel>
          <RadioGroup
            aria-label="domingo"
            name="domingo"
            value={domingo}
            onChange={(e) => setDomingo(e.target.value)}
          >
            <FormControlLabel value="0.1" control={<Radio />} label="Sol" />
            <FormControlLabel value="1" control={<Radio />} label="Chuva" />
          </RadioGroup>
        </FormControl>
      </Form>
      <div id="btn-send2">
        <Button onClick={handleSubmit} variant="contained" color="secondary">
          concluir
        </Button>
      </div>
    </PlataformaPadrao>
  )
}
