import './styles.css'

import React, { useContext, useState } from 'react'

import Button from '@material-ui/core/Button'
import Modal from 'react-bootstrap/Modal'
import { Spinner } from 'react-bootstrap'
import { UserContext } from '../../contexts/user.js'
import api from '../../services/api'
import { useHistory } from 'react-router-dom'

export default function ItemListaNovoPaciente({
  paciente,
  consulta,
  pesquisador,
}) {
  const history = useHistory()
  const { user } = useContext(UserContext)

  const hora_consulta = new Date(consulta.hora_inicio).toLocaleString('pt-BR')
  const [showAceitar, setShowAceitar] = useState(false)
  const [showRecusar, setShowRecusar] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleCloseAceitar = () => setShowAceitar(false)
  const handleShowAceitar = () => setShowAceitar(true)
  const handleCloseRecusar = () => setShowRecusar(false)
  const handleShowRecusar = () => setShowRecusar(true)

  const handleInserirPacientenoPesquisador = async () => {
    let quantidade_pacientes_deste_pesquisador =
      pesquisador.quantidade_pacientes + 1
    let atualizandoQtdPacientesPesquisador = await api.put(
      `pesquisador/${pesquisador.id_global}`,
      {
        quantidade_pacientes: quantidade_pacientes_deste_pesquisador,
      }
    )
    setShowAceitar(false)
    handleSendMail()
    history.go(0)
  }

  const handleRecusar = async () => {
    setLoading(true)
    //? Gisele é o id 1

    //! Etapa de retirada de paciente deste pesquisador
    const pacientesPesquisador = pesquisador.id_paciente.toString().split(',')

    //* retira do id paciente o paciente cujo id global seja igual ao id global do paciente escolhido
    const pacientesPesquisadorStr = pacientesPesquisador
      .filter(
        (idPaciente) => idPaciente.toString() !== paciente.id_global.toString()
      )
      .join(',')

    console.log(pacientesPesquisadorStr)

    //* atualizar pesquisador no bd
    try {
      await api.put(`pesquisador/${pesquisador.id_global}`, {
        id_paciente: pacientesPesquisadorStr,
      })
    } catch (error) {
      alert('Erro ao recusar paciente, tente novamente')
      return
    }

    //! Etapa de designação do paciente para o administrador
    //* Recuperar pacientes do administrador
    var pacientesAdministrador

    await api.get('pesquisador/1').then(async (response) => {
      pacientesAdministrador = response.data.id_paciente
        .toString()
        .split(',')
        .filter((val) => val !== '')
      pacientesAdministrador.push(paciente.id_global)
      const pacientesAdministradorStr = pacientesAdministrador.join(',')

      console.log(pacientesAdministradorStr)

      //* Adicionar paciente escolhido no id paciente do administrador e somar na quantidade de pacientes
      await api.put('pesquisador/1', {
        id_paciente: pacientesAdministradorStr,
        quantidade_pacientes: response.data.quantidade_pacientes + 1,
      })

      //* Adicionar administrador no id pesquisador do paciente escolhido
      await api.put(`paciente/${paciente.id_global}`, { id_pesquisador: '1' })
    })

    //! Alerta de email
    handleSendMailRecusa()

    setLoading(false)

    //!Saída
    handleCloseRecusar()
    history.go(0)
  }

  const handleSendMailRecusa = () => {
    api
      .get('global/1')
      .then((response) => {
        const admin_email = response.data.email
        const admin_nome = response.data.nome

        const dados_email = {
          nome_destinatario: admin_nome,
          endereco_email: admin_email,
          assunto_do_email: 'Um paciente foi recusado',
          texto_do_email: `O paciente "${paciente.nome}" foi recusado e atribuído a você, por favor atribua um(a) nova(o) enfermeira(o) a esse paciente`,
        }

        api
          .post('send_mail', dados_email)
          .catch((error) => alert(error + '\nErro ao enviar email'))
      })
      .catch((error) =>
        alert(error + '\nErro ao recuperar os dados do servidor')
      )
  }

  const handleSendMail = () => {
    api
      .post('send_mail', {
        nome_destinatario: paciente.nome,
        endereco_email: paciente.email,
        assunto_do_email: 'A sua consulta foi aceita',
        texto_do_email: `A sua consulta com ${pesquisador.nome} foi confirmada para ${hora_consulta}`,
      })
      .catch((error) => {
        console.log(error)
        alert('Não foi possível notificar o paciente, avise-o manualmente')
      })

    api
      .post('send_mail', {
        nome_destinatario: user.nome,
        endereco_email: user.email,
        assunto_do_email: `Uma consulta foi aceita`,
        texto_do_email: `A consulta com ${paciente.nome} foi confirmada para ${hora_consulta}`,
      })
      .catch((error) => {
        console.log(error)
        alert('Não foi possível notificar o enfermeira(o)...')
      })
  }

  return (
    <>
      <tr>
        {/* <div className="itens-da-linha">*/}
        <td>{paciente.nome}</td>
        <td>{hora_consulta}</td>
        <td>{paciente.id_global}</td>
        {/* </div>*/}
        {/*<div className="botoes-lista">*/}
        <td>
          {' '}
          <Button variant='primary' onClick={handleShowAceitar}>
            ACEITAR
          </Button>
        </td>
        <td>
          {' '}
          <Button variant='primary' onClick={handleShowRecusar}>
            RECUSAR
          </Button>
        </td>

        {/* </div>} */}
      </tr>

      {/* Modal de aceitar */}
      <Modal id='modalnp' show={showAceitar} onHide={handleCloseAceitar}>
        <Modal.Header closeButton>
          <Modal.Title>Aceitando {paciente.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja aceitar consulta de {paciente.nome}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseAceitar}>
            Fechar
          </Button>
          <Button
            style={{ color: 'green' }}
            variant='primary'
            onClick={handleInserirPacientenoPesquisador}
          >
            Aceitar!
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de recusar */}
      <Modal show={showRecusar} onHide={handleCloseRecusar}>
        <Modal.Header closeButton>
          <Modal.Title>Recusando {paciente.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja recusar {paciente.nome}? Você deverá aguardar
          que o administrador da plataforma remaneje este paciente.
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseRecusar}>
            Fechar
          </Button>
          <Button
            style={{ color: 'red' }}
            variant='primary'
            onClick={handleRecusar}
          >
            Recusar!
          </Button>
          {loading && <Spinner animation='border' />}
        </Modal.Footer>
      </Modal>
    </>
  )
}
