import React, { useEffect, useContext } from 'react'
import { UserContext } from '../../contexts/user.js'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import api from '../../services/api'

export default function WrapperPage() {
  const { token } = useParams()
  const history = useHistory()
  const { login } = useContext(UserContext)

  useEffect(() => {
    /**
     * Chama tokenController.getToken
     * caso o token exista chama doToken,
     * caso contrário mostra alert
     */
    const getData = () => {
      api
        .get(`getToken/${token}`)
        .then(async ({ data }) => {
          doToken(data)
        })
        .catch((error) =>
          alert(error + '\nEsse token não existe, o link está incorreto.')
        )
    }
    getData()
  }, [])

  /**
   *  Deleta o token recebido e faz a ação que seu tipo especifica
   *  cada ação fica especificada em seu handler
   *  obs: em casos de handlers async a chamada da função deve conter await
   *
   * @param {*} tokenData token: string , id_global: int, expiracao: Date.toISOString, tipo: 'recuperacao' / 'validacao'
   */
  const doToken = (tokenData) => {
    //Caso a data de expiração seja menor que a data atual
    if (new Date(tokenData.expiracao).to < new Date()) {
      api.delete(`/token/${tokenData.id_token}`).catch(console.log)
      alert('Esse link expirou.')
      return
    }

    api
      .post(`/login/${tokenData.id_global}`)
      .then(({ data }) => {
        login(data)

        switch (tokenData.tipo) {
          case 'recuperacao':
            api.delete(`/token/${tokenData.id_token}`).catch(console.log)
            handleRecuperar()
            break
          case 'validacao':
            api.delete(`/token/${tokenData.id_token}`).catch(console.log)
            handleValidar(tokenData)
            break
          case 'sol_chuva':
            handleSolChuva(tokenData)
            break
          case 'dvss':
            handleDvss(tokenData)
            break
          case 'diario':
            handleDiarioEliminacoes(tokenData)
            break
          case 'romaiv':
            handleRomaiv(tokenData)
            break
          default:
            console.log('O tipo de token está incorreto')
        }
      })
      .catch(console.log)
  }

  const handleValidar = (tokenData) => {
    api
      .put(`/global-validar/${tokenData.id_global}`)
      .then(() => {
        alert('Seu e-mail foi confirmado')
        history.push('/')
      })
      .catch(() => alert('Houve um erro, tente novamente'))
  }

  const handleRecuperar = () => {
    history.push('/redefinir-senha')
  }

  const handleSolChuva = (tokenData) => {
    history.push(`/sol-chuva/${tokenData.token.split('++++++++')[1]}`)
  }

  const handleDvss = (tokenData) => {
    history.push(`/dvss/${tokenData.token.split('++++++++')[1]}`)
  }

  const handleDiarioEliminacoes = (tokenData) => {
    history.push(`/diario-eliminacoes/${tokenData.token.split('++++++++')[1]}`)
  }

  const handleRomaiv = (tokenData) => {
    history.push(`/romaiv/${tokenData.token.split('++++++++')[1]}`)
  }

  return <h1>Redirecionando...</h1>
}
