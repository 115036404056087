import './styles.css'

import { Container, Image } from 'react-bootstrap'
import { MDBCol, MDBRow } from 'mdbreact'
import React, { useCallback, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import ReactPlayer from 'react-player'
import TagsBlog from '../../components/TagsBlog'
import gfm from 'remark-gfm'

export default function Noticiapostagem({
  diadata,
  noticiaTitulo,
  noticiaImagem,
  noticiaVideo,
  noticiaTexto,
  noticiaAutor,
  noticiaTags0,
  noticiaRevisor,
}) {
  const [tagsArray, setTagsArray] = useState([])

  const components = {
    del: 'u',
    link: ({ href, children }) => (
      <a href={href} target='_blank'>
        {children}
      </a>
    ),
    p: ({ children }) => (
      <p style={{ textIndent: '8.09%', textAlign: 'justify' }}>{children}</p>
    ),
  }
  useEffect(() => {
    let tagsLidas = noticiaTags0.split(',')
    tagsLidas = tagsLidas.map((tagAtual) => tagAtual.trim().toLowerCase())
    setTagsArray(tagsLidas)
  }, [])

  return (
    <>
      <Container className='noticiapostagem11'>
        <MDBRow>
          <MDBCol sm='10'>
            <MDBRow>
              <section className='noticia'>
                <p className='dia-data'>
                  {diadata.slice(8, 10) +
                    '/' +
                    diadata.slice(5, 7) +
                    '/' +
                    diadata.slice(0, 4)}
                </p>
                <h1 className='noticia-titulo'>{noticiaTitulo}</h1>
                <div className='noticia-imagem'>
                  <Image
                    className='w-50'
                    src={`${process.env.REACT_APP_API_URL}/files/uploads/${noticiaImagem}`}
                    thumbnail
                  />
                </div>
                {/* {!(noticiaVideo === "") ?
                  (<div className="div-react-player">
                    <video width="320" height="240" controls>
                      <source
                        src={`${process.env.REACT_APP_API_URL}/files/uploads/${noticiaVideo}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>) :
                  (<div></div>)} */}
                {noticiaVideo !== '' && (
                  <div className='youtubediv'>
                    <iframe
                      className='youtubevideo'
                      src={`https://www.youtube.com/embed/${noticiaVideo.slice(
                        -11
                      )}`}
                      title='YouTube video player'
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                    />
                  </div>
                )}
                <section className='noticia-texto'>
                  <ReactMarkdown
                    plugins={[gfm]} //adiciona suporte para texto riscado (strikethrough), tabelas, listas de tarefas e URLs diretamente
                    components={components}
                    children={noticiaTexto}
                    linkTarget='_blank'
                  />
                </section>
                <p>
                  <b>Autor(es): </b>
                  {noticiaAutor}
                </p>
                <p>
                  <b>Revisor(es): </b>
                  {noticiaRevisor}
                </p>
                <p>
                  {tagsArray.map((tag) => {
                    return (
                      <Link className='linktag' to={'/blog/' + tag}>
                        {'#' + tag}
                      </Link>
                    )
                  })}
                </p>
              </section>
            </MDBRow>
          </MDBCol>
          <TagsBlog />
        </MDBRow>
      </Container>
    </>
  )
}
