import './styles.css'

import { Form, Spinner } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import React, { useContext, useState } from 'react'

import CadastroPadrao from '../../components/CadastroPadrao'
import InputMask from 'react-input-mask'
import { TextField } from '@material-ui/core'
import { UserContext } from '../../contexts/user'
import api from '../../services/api'
import { differenceInDays } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import politica_privacidade from '../../assets/politica_privacidade_enfuroped.pdf'
import termos_de_uso from '../../assets/termos_de_uso_enfuroped.pdf'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    justifyContent: 'center',
    color: '#000000',
  },
})

export default function CadastroFamilia() {
  const classes = useStyles()
  const history = useHistory()

  //Cadastro global
  const [nome, setNome] = useState('')
  const [data_nascimento, setDataNascimento] = useState('')
  const [email, setEmail] = useState('')
  const [cpf, setCpf] = useState('')
  const [senha, setSenha] = useState('')
  const [senhaConfirmada, setSenhaConfirmada] = useState('')
  const [telefone1, setTelefone1] = useState('')
  const [telefone2, setTelefone2] = useState('')
  const [sexo, setSexo] = useState('Feminino') //começa com Feminino pois é a primeira opção do select no front
  const tipo = 'paciente'
  const autenticado_pelo_admin = true //como o paciente não precisa ser autenticado que nem o pesquisador e o estudante, já setamos aqui para true, o que significa que ele já é automaticamente autenticado ao se cadastrar (diferentemente do pesquisador e do estudante)
  const [loading, setLoading] = useState(false)

  //Cadastro Familia
  const id_pesquisador = '' //string vazia quer dizer que não tem nenhum pesquisador associado ainda

  const [escola, setEscola] = useState('')
  const [rg, setRg] = useState('')
  const [peso, setPeso] = useState('')
  const [altura, setAltura] = useState('')
  const [cidade_estado, setCidadeEstado] = useState('')
  const [nome_responsavel, setNomeResponsavel] = useState('')
  const [email_responsavel, setEmailResponsavel] = useState('')
  const [cpf_responsavel, setCpfResponsavel] = useState('')
  const [data_nascimento_responsavel, setDataNascimentoResponsavel] = useState(
    ''
  )
  const [parentesco, setParentesco] = useState('')
  const status = 'primeira_consulta'
  const { login } = useContext(UserContext)

  function getIdadeEmDias(birthday) {
    const from = birthday.split('/')
    const birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0])
    const cur = new Date()
    return differenceInDays(cur, birthdateTimeStamp)
  }

  function getNivelDesenvolvimento(idade) {
    if (idade >= 0 && idade <= 28) {
      //0 até 28 dias
      return 'Recém-nascido'
    } else if (idade > 28 && idade <= 730) {
      //29 dias até 2 anos
      return 'Lactente'
    } else if (idade > 730 && idade <= 1824) {
      //de 2 até 4 anos
      return 'Pré-escolar'
    } else if (idade > 1824 && idade <= 4014) {
      //de 5 até 10 anos
      return 'Escolar'
    } else if (idade > 4014) {
      //a partir de 11 anos
      return 'Adolescente'
    }
  }

  function isEmailAndCpfAlreadyBeingUsed(data) {
    for (const iter of data) {
      if (iter.email == email) {
        return true
      }
      if (iter.cpf == cpf) {
        return true
      }
    }

    return false
  }

  async function handleValidation() {
    const global = await api
      .get('global')
      .then(({ data }) => data)
      .catch(console.log)

    let formIsValid = true

    if (telefone1.includes('_') || telefone1.length === 0) {
      formIsValid = false
    }

    if (
      data_nascimento_responsavel.includes('_') ||
      data_nascimento_responsavel.length === 0
    ) {
      formIsValid = false
    }

    if (data_nascimento.includes('_') || data_nascimento.length === 0) {
      formIsValid = false
    }

    if (cpf_responsavel.includes('_') || cpf_responsavel.length === 0) {
      formIsValid = false
    }

    if (cpf.includes('_') || cpf.length === 0) {
      formIsValid = false
    }
    if (
      altura <= 0 ||
      peso <= 0 ||
      getIdadeEmDias(data_nascimento) < 0 ||
      getIdadeEmDias(data_nascimento_responsavel) < 0
    ) {
      alert('Altura, peso, ou data de nascimento estão incorretas')
      formIsValid = false
    }

    if (isEmailAndCpfAlreadyBeingUsed(global)) {
      alert('Esse email ou cpf já estão cadastrados')
      formIsValid = false
    }

    return formIsValid
  }

  async function handleCadastro(e) {
    e.preventDefault()

    const formIsValid = await handleValidation()

    if (!formIsValid) {
      alert('Preencha todos os campos necessários antes de continuar.')
      return
    }

    if (senha !== senhaConfirmada) {
      alert('As Senhas Digitadas Não Batem. Por Favor, Tente Novamente.')
      return
    }

    setLoading(true)

    const responseGlobal = await api
      .post('global', {
        nome,
        data_nascimento,
        email,
        cpf,
        senha,
        sexo,
        telefone1,
        telefone2,
        tipo,
        autenticado_pelo_admin,
      })
      .then(({ data }) => data)
      .catch(console.log)

    api
      .post('paciente', {
        id_global: responseGlobal.id,
        id_pesquisador,
        nome,
        data_nascimento,
        nivel_desenvolvimento: getNivelDesenvolvimento(
          getIdadeEmDias(data_nascimento)
        ),
        escola,
        cpf,
        rg,
        peso,
        altura,
        imc:
          10000 *
          (parseFloat(peso) / (parseFloat(altura) * parseFloat(altura))),
        sexo,
        cidade_estado,
        nome_responsavel,
        email_responsavel,
        cpf_responsavel,
        data_nascimento_responsavel,
        parentesco,
        status,
      })
      .catch(console.log)

    const informacoesGlobal = await api
      .get('global/' + responseGlobal.id)
      .then(({ data }) => data)
      .catch(console.log)

    await login(informacoesGlobal)

    api
      .post('/validar-email/', { email })
      .then(handleSendMail)
      .catch(console.log)

    history.replace('/marcar-primeira-consulta')
  }

  function handleSendMail() {
    api
      .get('global/1')
      .then((response) => {
        const admin_email = response.data.email
        const admin_nome = response.data.nome

        const dados_email = {
          nome_destinatario: admin_nome,
          endereco_email: admin_email,
          assunto_do_email: 'Um novo paciente se cadastrou',
          texto_do_email: `O paciente "${nome}" acabou de se cadastrar.`,
        }

        api.post('send_mail', dados_email).catch(console.log)
      })
      .catch(console.log)
  }

  return (
    <CadastroPadrao
      title='CADASTRO PACIENTE'
      onSubmit={handleCadastro}
      className={classes.root}
    >
      <div className='entrada'>
        <TextField
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          className={classes.input}
          required
          label='Nome Completo do Paciente'
          variant='outlined'
        />
      </div>
      <div className='entrada'>
        <TextField
          value={nome_responsavel}
          onChange={(e) => setNomeResponsavel(e.target.value)}
          className={classes.input}
          required
          label='Nome Completo do Responsável'
          variant='outlined'
        />
      </div>
      <div className='entrada'>
        <InputMask
          mask='(99)99999-9999'
          value={telefone1}
          onChange={(e) => setTelefone1(e.target.value)}
        >
          {() => (
            <TextField
              className={classes.input}
              required
              label='Telefone para contato 1'
              variant='outlined'
            />
          )}
        </InputMask>
      </div>
      <div className='entrada'>
        <InputMask
          mask='(99)99999-9999'
          value={telefone2}
          onChange={(e) => setTelefone2(e.target.value)}
        >
          {() => (
            <TextField
              className={classes.input}
              label='Telefone para contato 2 (Opcional)'
              variant='outlined'
            />
          )}
        </InputMask>
      </div>
      <div className='entrada'>
        <TextField
          value={cidade_estado}
          onChange={(e) => setCidadeEstado(e.target.value)}
          className={classes.input}
          required
          label='Cidade/Estado'
          variant='outlined'
        />
      </div>
      <div className='entrada'>
        <TextField
          value={email_responsavel}
          onChange={(e) => setEmailResponsavel(e.target.value)}
          className={classes.input}
          required
          label='E-mail do Responsável'
          variant='outlined'
          type='email'
        />
      </div>
      <div className='entrada'>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={classes.input}
          label='E-mail do Paciente'
          helperText='Caso o paciente não tenha e-mail, repita neste campo o e-mail do responsável.'
          required
          variant='outlined'
          type='email'
        />
      </div>
      <div className='entrada'>
        <InputMask
          mask='99/99/9999'
          value={data_nascimento}
          onChange={(e) => setDataNascimento(e.target.value)}
        >
          {() => (
            <TextField
              className={classes.input}
              required
              label='Data de Nascimento do Paciente'
              variant='outlined'
            />
          )}
        </InputMask>
      </div>
      <div className='entrada'>
        <InputMask
          mask='99/99/9999'
          value={data_nascimento_responsavel}
          onChange={(e) => setDataNascimentoResponsavel(e.target.value)}
        >
          {() => (
            <TextField
              className={classes.input}
              required
              label='Data de Nascimento do Responsável'
              variant='outlined'
            />
          )}
        </InputMask>
      </div>
      <div className='entrada'>
        <Form.Group>
          <Form.Label className='form-select-label'>
            Sexo do Paciente
          </Form.Label>
          <Form.Control as='select' onChange={(e) => setSexo(e.target.value)}>
            <option value='Feminino'>Feminino</option>
            <option value='Masculino'>Masculino</option>
            <option value='Outro'>Outro</option>
          </Form.Control>
        </Form.Group>
      </div>
      <div className='entrada'>
        <TextField
          value={altura}
          onChange={(e) => setAltura(e.target.value)}
          className={classes.input}
          label='Altura do Paciente (em centímetros)'
          variant='outlined'
          required
        />
      </div>
      <div className='entrada'>
        <TextField
          value={peso}
          onChange={(e) => setPeso(e.target.value)}
          className={classes.input}
          label='Peso do Paciente (em quilogramas)'
          variant='outlined'
          required
        />
      </div>
      <div className='entrada'>
        <TextField
          value={escola}
          onChange={(e) => setEscola(e.target.value)}
          className={classes.input}
          label='Escola do Paciente (caso tenha)'
          variant='outlined'
        />
      </div>
      <div className='entrada'>
        <InputMask
          mask='999.999.999-99'
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
        >
          {() => (
            <TextField
              className={classes.input}
              required
              label='CPF do Paciente'
              variant='outlined'
            />
          )}
        </InputMask>
      </div>
      <div className='entrada'>
        <InputMask
          mask='9.999.999'
          value={rg}
          onChange={(e) => setRg(e.target.value)}
        >
          {() => (
            <TextField
              className={classes.input}
              label='RG do Paciente (caso tenha)'
              variant='outlined'
            />
          )}
        </InputMask>
      </div>
      <div className='entrada'>
        <TextField
          value={parentesco}
          onChange={(e) => setParentesco(e.target.value)}
          className={classes.input}
          required
          label='Grau de Parentesco entre Responsável e Paciente'
          variant='outlined'
          InputLabelProps={{ style: { fontSize: 14.5 } }}
        />
      </div>
      <div className='entrada'>
        <InputMask
          mask='999.999.999-99'
          value={cpf_responsavel}
          onChange={(e) => setCpfResponsavel(e.target.value)}
        >
          {() => (
            <TextField
              className={classes.input}
              label='CPF do Responsável'
              required
              variant='outlined'
            />
          )}
        </InputMask>
      </div>
      <div className='entrada'>
        <TextField
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
          className={classes.input}
          id='outlined-password-input'
          label='Senha para Login na Plataforma'
          type='password'
          variant='outlined'
          required
        />
      </div>
      <div className='entrada'>
        <TextField
          value={senhaConfirmada}
          onChange={(e) => setSenhaConfirmada(e.target.value)}
          className={classes.input}
          id='outlined-password-input'
          label='Confirme a Senha'
          type='password'
          variant='outlined'
          required
        />
      </div>
      <div className='entrada termo_politica'>
        <p>
          → Ao se cadastrar, você confirma que leu e aceita os{' '}
          <Link target='_blank' to={termos_de_uso}>
            Termos de Uso
          </Link>{' '}
          e a{' '}
          <Link target='_blank' to={politica_privacidade}>
            Política de Privacidade
          </Link>
          .
        </p>
      </div>
      {loading && <Spinner animation='border' />}
    </CadastroPadrao>
  )
}
