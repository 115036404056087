import React, { useState } from 'react'
import ReactMde from 'react-mde'
import './styles.css'
import 'react-mde/lib/styles/css/react-mde-all.css'
import gfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'

export default function MarkdownEditor({ value, setValue }) {
  const [actualTab, setActualTab] = useState('write')

  const components = {
    del: 'u',
    link: ({ href, children }) => (
      <a href={href} target='_blank'>
        {children}
      </a>
    ),
    p: ({ children }) => (
      <p
        style={{
          wordBreak: 'break-all',
          textIndent: '8.09%',
          textAlign: 'justify',
          fontSize: 14,
        }}
      >
        {children}
      </p>
    ),
  }

  return (
    <div className='textarea'>
      <ReactMde
        toolbarCommands={[['bold', 'italic', 'strikethrough']]}
        value={value}
        onChange={setValue}
        selectedTab={actualTab}
        onTabChange={() =>
          actualTab === 'write'
            ? setActualTab('preview')
            : setActualTab('write')
        }
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(
            <ReactMarkdown
              plugins={[gfm]} //adiciona suporte para texto riscado (strikethrough), tabelas, listas de tarefas e URLs diretamente
              components={components}
              children={markdown}
            />
          )
        }
        childProps={{
          writeButton: {
            tabIndex: -1,
          },
        }}
      />
    </div>
  )
}
