import { FiTrash2, FiEye } from 'react-icons/fi'
import { FaRegEdit } from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal'
import { Form } from 'react-bootstrap'
import api from '../../services/api'
import './styles.css'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Container, Spinner } from 'react-bootstrap'
import FormularioPreenchidoEstudante from '../FormularioPreenchidoEstudante'
import FormularioPreenchidoEstudanteEditavel from '../FormularioPreenchidoEstudanteEditavel'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    justifyContent: 'center',
    color: '#000000',
  },
  botao: {
    backgroundColor: '#F54C4C',
  },
  botaosecundario: {
    color: '#F54C4C',
  },
}))

export default function ItemListaEstudante({
  excluir,
  editar,
  olhar,
  estudante,
  globalEstudante,
}) {
  const classes = useStyles()
  const history = useHistory()

  const [showVer, setShowVer] = useState(false)
  const [showEditar, setShowEditar] = useState(false)
  const [showDeletar, setShowDeletar] = useState(false)

  const handleCloseVer = () => setShowVer(false)
  const handleShowVer = () => setShowVer(true)
  const handleCloseEditar = () => setShowEditar(false)
  const handleShowEditar = () => setShowEditar(true)
  const handleCloseDeletar = () => setShowDeletar(false)
  const handleShowDeletar = () => setShowDeletar(true)

  //Cadastro global
  const [nome, setNome] = useState(globalEstudante.nome)
  const [data_nascimento, setDataNascimento] = useState(
    globalEstudante.data_nascimento
  )
  const [email, setEmail] = useState(globalEstudante.email)
  const [cpf, setCpf] = useState(globalEstudante.cpf)
  const [telefone1, setTelefone1] = useState(globalEstudante.telefone1)
  const [telefone2, setTelefone2] = useState(globalEstudante.telefone2)
  const [sexo, setSexo] = useState(globalEstudante.sexo) //começa com Feminino pois é a primeira opção do select no front

  //Cadastro Estudante
  const [id_global, setIdglobal] = useState(globalEstudante.id_global)
  const [rg, setRg] = useState(estudante.rg)
  const [instituicao, setInstituicao] = useState(estudante.instituicao)
  const [matricula, setMatricula] = useState(estudante.matricula)
  const [semestre, setSemestre] = useState(estudante.semestre)
  const [acesso_cadastros, setAcessocadastros] = useState(
    estudante.acesso_cadastros
  )
  const [acesso_blog, setAcessoblog] = useState(estudante.acesso_blog)

  async function handleEdicao(f) {
    f.preventDefault()

    const data = {
      nome,
      data_nascimento,
      cpf,
      rg,
      instituicao,
      matricula,
      semestre,
      sexo,
      acesso_cadastros,
      acesso_blog,
    }
    const data2 = {
      nome,
      data_nascimento,
      email,
      cpf,
      sexo,
      telefone1,
      telefone2,
    }

    try {
      const response = await api.put(`alunos_graduacao/${id_global}`, data)
      const response1 = await api.put(`global/${id_global}`, data2)
      setShowEditar(false)
      history.go(0)
    } catch (err) {
      alert('Erro ao editar. Tente Novamente.')
    }
  }

  async function handleExclusao() {
    try {
      const response1 = await api.delete(`/alunos_graduacao/${id_global}`)
      const response2 = await api.delete(`/global/${id_global}`)
      setShowDeletar(false)
      history.go(0)
    } catch (err) {
      alert('Erro ao deletar. Tente Novamente.')
    }
  }

  return (
    <>
      <tr>
        {/* <div className="itens-da-linha">*/}
        <td>{nome}</td>
        <td>{email}</td>
        <td>{telefone1}</td>
        {/* </div>*/}
        {/*<div className="botoes-lista">*/}
        <td>
          {' '}
          <Button size="small" variant="primary" onClick={handleShowVer}>
            VER
          </Button>
        </td>
        <td>
          {' '}
          <Button size="small" variant="primary" onClick={handleShowEditar}>
            EDITAR
          </Button>
        </td>
        <td>
          <Button size="small" variant="primary" onClick={handleShowDeletar}>
            EXCLUIR
          </Button>
        </td>

        {/* </div>} */}
      </tr>

      {/* Modal de visualizar dados */}
      <Modal show={showVer} onHide={handleCloseVer}>
        <Modal.Header closeButton>
          <Modal.Title>Dados de {nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={nome}
                variant="outlined"
                label="Nome Completo"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={telefone1}
                variant="outlined"
                label="Telefone para Contato 1"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={telefone2}
                variant="outlined"
                label="Telefone para Contato 2"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={email}
                variant="outlined"
                label="E-mail"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={data_nascimento}
                variant="outlined"
                label="Data de Nascimento"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={sexo}
                variant="outlined"
                label="Sexo"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={instituicao}
                variant="outlined"
                label="Instituição onde Estuda"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={matricula}
                variant="outlined"
                label="Número de Matrícula"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={semestre}
                variant="outlined"
                label="Semestre Atual"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={cpf}
                variant="outlined"
                label="CPF"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={rg}
                variant="outlined"
                label="RG"
              />
            </section>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseVer}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de editar dados */}
      <Modal show={showEditar} onHide={handleCloseEditar}>
        <Modal.Header closeButton>
          <Modal.Title>Editando {nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form onSubmit={handleEdicao}>
              <section>
                <TextField
                  className="textarea"
                  id="outlined"
                  defaultValue={nome}
                  onChange={(f) => setNome(f.target.value)}
                  variant="outlined"
                  label="Nome Completo"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  id="outlined"
                  defaultValue={telefone1}
                  onChange={(f) => setTelefone1(f.target.value)}
                  variant="outlined"
                  label="Telefone para Contato 1"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  id="outlined"
                  defaultValue={telefone2}
                  onChange={(f) => setTelefone2(f.target.value)}
                  variant="outlined"
                  label="Telefone para Contato 2"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  id="outlined"
                  defaultValue={email}
                  onChange={(f) => setEmail(f.target.value)}
                  variant="outlined"
                  label="E-mail"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  id="outlined"
                  defaultValue={data_nascimento}
                  onChange={(f) => setDataNascimento(f.target.value)}
                  variant="outlined"
                  label="Data de Nascimento"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  id="outlined"
                  defaultValue={sexo}
                  onChange={(f) => setSexo(f.target.value)}
                  variant="outlined"
                  label="Sexo"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  id="outlined"
                  defaultValue={instituicao}
                  onChange={(f) => setInstituicao(f.target.value)}
                  variant="outlined"
                  label="Instituição onde Estuda"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  id="outlined"
                  defaultValue={matricula}
                  onChange={(f) => setMatricula(f.target.value)}
                  variant="outlined"
                  label="Número de Matrícula"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  id="outlined"
                  defaultValue={semestre}
                  onChange={(f) => setSemestre(f.target.value)}
                  variant="outlined"
                  label="Semestre Atual"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  id="outlined"
                  defaultValue={cpf}
                  onChange={(f) => setCpf(f.target.value)}
                  variant="outlined"
                  label="CPF"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  id="outlined"
                  defaultValue={rg}
                  onChange={(f) => setRg(f.target.value)}
                  variant="outlined"
                  label="RG"
                />
              </section>
              <div className="btn-send">
                <Button variant="contained" type="submit">
                  Salvar Mudanças
                </Button>
              </div>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditar}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleCloseEditar}>
            Salvar mudanças
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de deletar */}
      <Modal show={showDeletar} onHide={handleCloseDeletar}>
        <Modal.Header closeButton>
          <Modal.Title>Excluindo {nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja excluir {nome}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeletar}>
            FECHAR
          </Button>
          <Button
            style={{ color: 'red' }}
            variant="primary"
            onClick={handleExclusao}
          >
            EXCLUIR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
