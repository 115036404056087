import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import './styles.css'
import api from '../../services/api'
import PlataformaPadrao from '../../components/plataforma-padrao'
import { Form, Container, Spinner } from 'react-bootstrap'

export default function CadastroEstudantePreenchido() {
  const [loading, setLoading] = useState(true)
  const [aluno, setAluno] = useState({})
  const [global, setGlobal] = useState({})

  useEffect(() => {
    //faz as duas requisições juntas
    Promise.all([api.get('alunos_graduacao/1'), api.get('global/1')]).then(
      ([alunoResponse, globalResponse]) => {
        setAluno(alunoResponse.data)
        setGlobal(globalResponse.data)
        setLoading(false)
      }
    )
  }, [])

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  //só retorna a página em si quando o loading acaba (quando os dados são carregados)
  return (
    <PlataformaPadrao title="CADASTRO ESTUDANTE PREENCHIDO">
      <Container>
        <section>
          <TextField
            className="textarea"
            disabled
            id="outlined-disabled"
            defaultValue={aluno.nome}
            variant="outlined"
            label="Nome Completo"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            disabled
            id="outlined-disabled"
            defaultValue={global.telefone1}
            variant="outlined"
            label="Telefone para Contato 1"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            disabled
            id="outlined-disabled"
            defaultValue={global.telefone2}
            variant="outlined"
            label="Telefone para Contato 2"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            disabled
            id="outlined-disabled"
            defaultValue={global.email}
            variant="outlined"
            label="E-mail"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            disabled
            id="outlined-disabled"
            defaultValue={aluno.data_nascimento}
            variant="outlined"
            label="Data de Nascimento"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            disabled
            id="outlined-disabled"
            defaultValue={aluno.sexo}
            variant="outlined"
            label="Sexo"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            disabled
            id="outlined-disabled"
            defaultValue={aluno.instituicao}
            variant="outlined"
            label="Instituição onde Estuda"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            disabled
            id="outlined-disabled"
            defaultValue={aluno.matricula}
            variant="outlined"
            label="Número de Matrícula"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            disabled
            id="outlined-disabled"
            defaultValue={aluno.semestre}
            variant="outlined"
            label="Semestre Atual"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            disabled
            id="outlined-disabled"
            defaultValue={aluno.cpf}
            variant="outlined"
            label="CPF"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            disabled
            id="outlined-disabled"
            defaultValue={aluno.rg}
            variant="outlined"
            label="RG"
          />
        </section>
      </Container>
    </PlataformaPadrao>
  )
}
