import Modal from 'react-bootstrap/Modal'
import api from '../../services/api'
import { Image } from 'react-bootstrap'
import './styles.css'
import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Container, Form } from 'react-bootstrap'
import { TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../contexts/user.js'
import gfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'

const components = {
  del: 'u',
  link: ({ href, children }) => (
    <a href={href} target='_blank'>
      {children}
    </a>
  ),
  p: ({ children }) => (
    <p style={{ textIndent: '8.09%', textAlign: 'justify' }}>{children}</p>
  ),
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    justifyContent: 'center',
    color: '#000000',
  },
  botao: {
    backgroundColor: '#F54C4C',
  },
  botaosecundario: {
    color: '#F54C4C',
  },
}))

export default function ItemListaMensagem({ mensagem }) {
  const { user } = useContext(UserContext)
  const classes = useStyles()
  const [showResponder, setShowResponder] = useState(false)
  const handleCloseResponder = () => setShowResponder(false)
  const handleShowResponder = () => setShowResponder(true)

  const history = useHistory()
  const [first, setFirst] = useState(true)
  const [mensagem_resposta, setMensagem] = useState([])
  const [id_forum, setIdForum] = useState(mensagem.id_forum)
  const [autor, setAutor] = useState(user.nome)
  const [anexo, setAnexo] = useState('')
  const [conteudo, setConteudo] = useState('')
  const [titulo, setTitulo] = useState('')
  const [nome, setNome] = useState(user.nome)
  const [fileCollection, setFileCollection] = useState([])
  const id_resposta = mensagem.id_mensagem
  const date = mensagem.hora_data
  //const dataFormatada = ((data.getDate() )) + "/" + ((data.getMonth() + 1)) + "/" + ((data.getFullYear())) + "-" + ((data.getHours())) + ":" + ((data.getMinutes())) + ":" + ((data.getSeconds()));

  async function fileUpload() {
    let uploadError = false,
      filenamesArray = [],
      allowedTypes = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'video/3gpp',
        'video/mp4',
        'video/ogg',
        'video/quicktime',
        'video/webm',
        'video/x-ms-wmv',
        'video/x-msvideo',
      ]
    if (!fileCollection.length) {
      return [uploadError, filenamesArray]
    }

    const allowedFiles = fileCollection.filter((f) =>
      allowedTypes.includes(f.type)
    )

    if (allowedFiles.length !== fileCollection.length) {
      alert('Erro ao fazer upload, verifique o formato do arquivo.')
      setFileCollection([])
      uploadError = true
      return [uploadError, filenamesArray]
    }
    const formData = new FormData()
    allowedFiles.forEach((f) => formData.append('file', f))
    await api
      .post('/uploads', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        filenamesArray = res.data
      })

    return [uploadError, filenamesArray]
  }

  async function handleCadastro(e) {
    e.preventDefault()
    const [uploadError, filenamesArray] = await fileUpload()
    if (uploadError) {
      return
    } else {
      const hora_data = new Date()

      const data = {
        id_forum,
        autor,
        anexo: filenamesArray.join(','),
        conteudo,
        titulo,
        hora_data,
        nome,
        id_resposta,
      }

      try {
        const response = await api.post('mensagem_forum', data)
        setShowResponder(false)
        alert('Mensagem enviada com sucesso.')
        history.go(0)
      } catch (err) {
        alert('Erro ao Enviar Mensagem. Tente Novamente.')
      }
    }
  }

  if (first && mensagem.id_resposta) {
    setFirst(false)
    async function getMensagem() {
      const response1 = await api.get(
        `mensagem_especifica_forum/${mensagem.id_resposta}`
      )
      setMensagem(response1.data)
    }
    getMensagem()
  }
  return (
    <>
      <div className='mensagem-linha-da-lista'>
        <div className='mensagem-linha1-lista'>
          <div className='mensagem-linha1-linha1-lista'>
            <div className='mensagem-forum-autor'>{mensagem.autor}</div>
            <div className='mensagem-forum-titulo'>{mensagem.titulo}</div>
            <div className='mensagem-forum-hora_data'>
              {new Date(mensagem.hora_data).toLocaleString('pt-BR')}
            </div>
          </div>
          <div className='mensagem-linha2-linha1-lista'>
            {mensagem.id_resposta !== 0 && mensagem_resposta[0] && (
              <p>{mensagem_resposta[0].conteudo}</p>
            )}
          </div>
        </div>
        <div className='mensagem-linha2-lista'>
          <div className='mensagem-forum-conteudo'>
            <div className='mensagem-forum-conteudo'>
              <ReactMarkdown
                plugins={[gfm]} //adiciona suporte para texto riscado (strikethrough), tabelas, listas de tarefas e URLs diretamente
                components={components}
                children={mensagem.conteudo}
                linkTarget='_blank'
              />
            </div>
          </div>
          {mensagem.anexo !== '' &&
            mensagem.anexo
              .split(',')
              .map((anexo, idx) => (
                <Image
                  key={idx}
                  className='mensagem-forum-anexo'
                  src={`${process.env.REACT_APP_API_URL}/files/uploads/${anexo}`}
                  thumbnail
                />
              ))}
        </div>
        <pre></pre>
        <div className='mensagem-linha3-lista'>
          <Button variant='outlined' onClick={handleShowResponder}>
            Responder esta mensagem
          </Button>
        </div>
      </div>
      {/* Modal para responder uma mensagem */}
      <Modal show={showResponder} onHide={handleCloseResponder}>
        <Modal.Header closeButton>
          <Modal.Title>Respondendo {mensagem.autor}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {mensagem.id_resposta !== 0 && (
              <Alert className='sinal-resposta' severity='info'>
                Essa mensagem é resposta da mensagem de {mensagem.autor}
              </Alert>
            )}
            <Form onSubmit={handleCadastro}>
              {/* Falta submeter o autor que vem da sessão, o id_forum que vem da URL, a hora atual e o id resposta que vem da mensagem que está sendo respondida */}
              <section>
                <TextField
                  className='textarea'
                  value={titulo}
                  onChange={(e) => setTitulo(e.target.value)}
                  id='outlined-basic'
                  label='Título (opcional)'
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  value={conteudo}
                  onChange={(e) => setConteudo(e.target.value)}
                  required
                  id='outlined-basic'
                  label='Conteúdo/Mensagem'
                  variant='outlined'
                  multiline
                  rows={16}
                />
              </section>
              <section>
                <p>Imagens: </p>
                <input
                  accept='image/jpeg,image/jpg,image/png'
                  type='file'
                  // multiple
                  onChange={(e) =>
                    setFileCollection(Array.from(e.target.files))
                  }
                />
              </section>
              <div className='btn-send'>
                <Button variant='contained' type='submit'>
                  {' '}
                  Enviar Mensagem
                </Button>
              </div>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseResponder}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

// import Modal from 'react-bootstrap/Modal';
// import api from '../../services/api';
// import './styles.css';
// import React, { useState } from "react";
// import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
// import { Container, Form } from 'react-bootstrap';
// import { TextField } from '@material-ui/core';
// import { Alert } from '@material-ui/lab';
// import { useHistory } from 'react-router-dom';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         flexDirection: 'column',
//         alignItems: 'center',
//     },
//     input: {
//         width: '100%',
//         justifyContent: 'center',
//         color: '#000000',
//     },
//     botao: {
//         backgroundColor: '#F54C4C',
//     },
//     botaosecundario: {
//         color: '#F54C4C',
//     }
// }));

// export default function ItemListaMensagem({mensagem}) {
//     const classes = useStyles();
//     const [showResponder, setShowResponder] = useState(false);
//     const handleCloseResponder = () => setShowResponder(false);
//     const handleShowResponder = () => setShowResponder(true);

//     const history = useHistory()

//     const [first, setFirst] = useState(true)
//     const [id_forum, setIdForum] = useState(mensagem.id_forum)
//     const [autor, setAutor] = useState('')
//     const [anexo, setAnexo] = useState('')
//     const [conteudo, setConteudo] = useState('')
//     const [titulo, setTitulo] = useState('')
//     const [nome, setNome] = useState('')
//     const [resposta, setResposta] = useState('')
//     const id_resposta = mensagem.id_mensagem;
//     const date = mensagem.hora_data;
//     //const dataFormatada = ((data.getDate() )) + "/" + ((data.getMonth() + 1)) + "/" + ((data.getFullYear())) + "-" + ((data.getHours())) + ":" + ((data.getMinutes())) + ":" + ((data.getSeconds()));

//     async function handleCadastro(e) {
//         e.preventDefault();

//         const hora_data = new Date()

//         const data = {
//             id_forum,
//             autor,
//             anexo,
//             conteudo,
//             titulo,
//             hora_data,
//             nome,
//             id_resposta,
//         }

//         try {
//             const response = await api.post('mensagem_forum', data)
//         }

//         catch (err) {
//             alert('Erro ao Enviar Mensagem. Tente Novamente.')
//         }
//     }
//     if(first && mensagem.id_resposta !== 0) {
//         setFirst(false)
//         async function getResposta(){
//             const response1 = await api.get(`mensagem_especifica_forum/${id_resposta}`)
//             setResposta(response1.data)
//         }
//         getResposta()
//         console.log(resposta)
//     }
//     return (
//         <>
//           <div className="mensagem-linha-da-lista">
//               <div className="mensagem-linha1-lista">
//                 <div className="mensagem-linha1-linha1-lista">
//                   <div className="mensagem-forum-autor">{mensagem.autor}</div>
//                   <div className="mensagem-forum-titulo">{mensagem.titulo}</div>
//                   <div className="mensagem-forum-hora_data">{date.slice(8,10)+'/'+date.slice(5,7)+'/'+date.slice(0,4)+', '+date.slice(11,16)}</div>
//                 </div>
//                 <div className="mensagem-linha2-linha1-lista">
//                   {mensagem.id_resposta !== 0 &&
//                     <p>{resposta}</p>
//                   }
//                 </div>
//               </div>
//               <div className="mensagem-linha2-lista">
//                 <div className="mensagem-forum-conteudo">{mensagem.conteudo}</div>
//                 <div className="mensagem-forum-anexo">{mensagem.anexo}</div>
//               </div>
//               <div className="mensagem-linha3-lista">
//                 <Button variant="primary" onClick={handleShowResponder}>
//                   Responder esta mensagem
//                 </Button>
//               </div>
//           </div>
//           {/* Modal para responder uma mensagem */}
//           <Modal show={showResponder} onHide={handleCloseResponder}>
//             <Modal.Header closeButton>
//               <Modal.Title>Respondendo {mensagem.autor}</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               <Container>
//                 {mensagem.id_resposta !== 0 &&
//                     < Alert
//                         className="sinal-resposta"
//                         severity="info"
//                     >
//                         Essa mensagem é resposta da mensagem de {mensagem.autor}
//                     </Alert>
//                 }
//                 <Form onSubmit={handleCadastro}>
//                     {/* Falta submeter o autor que vem da sessão, o id_forum que vem da URL, a hora atual e o id resposta que vem da mensagem que está sendo respondida */}
//                     <section>
//                         <TextField
//                             className="textarea"
//                             value={titulo}
//                             onChange={e => setTitulo(e.target.value)}
//                             required
//                             id="outlined-basic"
//                             label="Título"
//                             variant="outlined"
//                         />
//                     </section>
//                     <section>
//                         <TextField
//                             className="textarea"
//                             value={conteudo}
//                             onChange={e => setConteudo(e.target.value)}
//                             required
//                             id="outlined-basic"
//                             label="Conteúdo/Mensagem"
//                             variant="outlined"
//                             multiline
//                             rows={16}
//                         />
//                     </section>
//                     <section>
//                         <p>Anexo: </p>
//                         <input type="file" />
//                     </section>
//                     <div className="btn-send">
//                         <Button variant='contained' type='submit'> Enviar Mensagem</Button>
//                     </div>
//                 </Form>
//               </Container>
//             </Modal.Body>
//             <Modal.Footer>
//               <Button variant="secondary" onClick={handleCloseResponder}>
//                 Fechar
//               </Button>
//               <Button variant="primary" onClick={handleCloseResponder}>
//                 Enviar
//               </Button>
//             </Modal.Footer>
//           </Modal>
//         </>
//       );
// }
