import './styles.css'

import { Button, FormControl, MenuItem, Select } from '@material-ui/core'
import { Modal, Spinner } from 'react-bootstrap'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import DateFnsUtils from '@date-io/date-fns'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import PlataformaPadrao from '../../components/plataforma-padrao'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'
import { UserContext } from '../../contexts/user'
import api from '../../services/api'
import ptlocale from 'date-fns/locale/pt-BR'

export default function DiarioEliminacoes() {
  const history = useHistory()
  const data_de_hoje = new Date()
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const { user } = useContext(UserContext)

  const [dataConsulta, setDataConsulta] = useState('')

  //fezes
  const [fezesTipo, setFezesTipo] = useState([])
  const [fezesHora, setFezesHora] = useState([])

  const [fezesTipoForm, setFezesTipoForm] = useState('')
  const [fezesHoraForm, setFezesHoraForm] = useState(data_de_hoje)

  //urina
  const [urinaUrgencia, setUrinaUrgencia] = useState([])
  const [urinaQuantidade, setUrinaQuantidade] = useState([])
  const [urinaHora, setUrinaHora] = useState([])

  const [urinaPerdaForm, setUrinaPerdaForm] = useState('')
  const [urinaUrgenciaForm, setUrinaUrgenciaForm] = useState('')
  const [urinaQuantidadeForm, setUrinaQuantidadeForm] = useState('')
  const [urinaHoraForm, setUrinaHoraForm] = useState(data_de_hoje)

  //bebida
  const [bebidaTipo, setBebidaTipo] = useState([])
  const [bebidaQuantidade, setBebidaQuantidade] = useState([])
  const [bebidaHora, setBebidaHora] = useState([])

  const [bebidaTipoForm, setBebidaTipoForm] = useState('')
  const [bebidaQuantidadeForm, setBebidaQuantidadeForm] = useState('')
  const [bebidaHoraForm, setBebidaHoraForm] = useState(data_de_hoje)

  //modais
  const [showEvac, setShowEvac] = useState(false)
  const [showMicc, setShowMicc] = useState(false)
  const [showBebida, setShowBebida] = useState(false)

  const handleCloseEvac = () => setShowEvac(false)
  const handleShowEvac = () => setShowEvac(true)
  const handleCloseMicc = () => setShowMicc(false)
  const handleShowMicc = () => setShowMicc(true)
  const handleCloseBebida = () => setShowBebida(false)
  const handleShowBebida = () => setShowBebida(true)

  useEffect(() => {
    const getData = async () => {
      await api
        .get(`diario_eliminacao/${id}`)
        .then((response) => {
          const auxFezesTipo = response.data.fezes_tipo
          const auxFezesHora = response.data.fezes_hora
          const auxUrinaUrgencia = response.data.urina_urgencia
          const auxUrinaQuantidade = response.data.urina_quantidade
          const auxUrinaHora = response.data.urina_hora
          const auxBebidaTipo = response.data.bebida_tipo
          const auxBebidaQuantidade = response.data.bebida_quantidade
          const auxBebidaHora = response.data.bebida_hora
          const auxDataConsulta = new Date(response.data.data)
          setFezesTipo(auxFezesTipo ? auxFezesTipo.split(',') : [])
          setFezesHora(auxFezesHora ? auxFezesHora.split(',') : [])
          setUrinaUrgencia(auxUrinaUrgencia ? auxUrinaUrgencia.split(',') : [])
          setUrinaQuantidade(
            auxUrinaQuantidade ? auxUrinaQuantidade.split(',') : []
          )
          setUrinaHora(auxUrinaHora ? auxUrinaHora.split(',') : [])
          setBebidaTipo(auxBebidaTipo ? auxBebidaTipo.split(',') : [])
          setBebidaQuantidade(
            auxBebidaQuantidade ? auxBebidaQuantidade.split(',') : []
          )
          setBebidaHora(auxBebidaHora ? auxBebidaHora.split(',') : [])
          setDataConsulta(
            auxDataConsulta ? auxDataConsulta.toLocaleDateString('pt-BR') : null
          )
          api.get(`paciente/${response.data.id_paciente}`).then(({ data }) => {
            if (
              user.id_global === response.data.id_paciente ||
              user.id_global === data.id_pesquisador
            ) {
              setLoading(false)
            } else {
              history.push(`/sem-permissao`)
            }
          })
        })
        .catch((error) => {
          console.log(error)
          alert('Erro ao recuperar informações, tente novamente.')
        })
    }
    getData()
  }, [])

  const SaveButton = ({ onClick }) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          style={{
            marginTop: 16,
            backgroundColor: '#F66464',
            color: 'white',
          }}
          variant='contained'
          onClick={onClick}
        >
          salvar
        </Button>
      </div>
    )
  }

  const TimeSelector = ({ value, onChange, title }) => {
    return (
      <>
        <h4 style={{ textAlign: 'center' }}>{title}</h4>
        <div style={{ marginTop: '1.2rem', marginBottom: '1.2rem' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptlocale}>
            <TimePicker
              variant='static'
              ampm={false}
              value={value}
              onChange={onChange}
            />
          </MuiPickersUtilsProvider>
        </div>
      </>
    )
  }

  const controlUrinaPerda = (valor) => {
    if (valor == 'false') {
      setUrinaPerdaForm('false')
    } else {
      setUrinaPerdaForm('true')
    }
    setUrinaQuantidadeForm('')
  }

  const saveEvac = async () => {
    // e.preventDefault()

    if (!fezesHoraForm || !fezesTipoForm) {
      return
    }

    fezesHora.push(fezesHoraForm)
    fezesTipo.push(fezesTipoForm)

    const data = {
      data: data_de_hoje,
      fezes_hora: fezesHora,
      fezes_tipo: fezesTipo,
    }

    await api
      .put(`diario_eliminacao/${id}`, data)
      .then(() => {
        alert('Registro feito com sucesso!')
        history.go(0)
      })
      .catch((error) => {
        console.log(error)
        alert('Erro! Tente novamente.')
      })
  }

  const saveMicc = async () => {
    // e.preventDefault()
    if (!urinaQuantidadeForm) {
      return
    }

    if (urinaPerdaForm == 'false') {
      urinaUrgencia.push(urinaUrgenciaForm)
    } else {
      urinaUrgencia.push('perda')
    }
    urinaQuantidade.push(urinaQuantidadeForm)
    urinaHora.push(urinaHoraForm)

    const data = {
      data: data_de_hoje,
      urina_hora: urinaHora,
      urina_quantidade: urinaQuantidade,
      urina_urgencia: urinaUrgencia,
    }

    await api
      .put(`diario_eliminacao/${id}`, data)
      .then(() => {
        alert('Registro feito com sucesso!')
        history.go(0)
      })
      .catch((error) => {
        console.log(error)
        alert('Erro! Tente novamente.')
      })
  }

  const saveBebida = async () => {
    // e.preventDefault()

    if (!bebidaTipoForm || !bebidaQuantidadeForm) {
      return
    }

    bebidaTipo.push(bebidaTipoForm)
    bebidaQuantidade.push(bebidaQuantidadeForm)
    bebidaHora.push(bebidaHoraForm)

    const data = {
      data: data_de_hoje,
      bebida_hora: bebidaHora,
      bebida_tipo: bebidaTipo,
      bebida_quantidade: bebidaQuantidade,
    }

    await api
      .put(`diario_eliminacao/${id}`, data)
      .then(() => {
        alert('Registro feito com sucesso!')
        history.go(0)
      })
      .catch((error) => {
        console.log(error)
        alert('Erro! Tente novamente.')
      })
  }

  const handleTimeChangeEvac = (date) => {
    setFezesHoraForm(date)
  }

  const handleTimeChangeMicc = (date) => {
    setUrinaHoraForm(date)
  }

  const handleTimeChangeBebida = (date) => {
    setBebidaHoraForm(date)
  }

  if (loading) {
    return (
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    )
  }

  return (
    <PlataformaPadrao title={`Diário de Eliminação - Dia (${dataConsulta})`}>
      <div className='subtitulo_instrucoes'>
        <p id='datainicial'>
          Atenção! você precisa preeencher esse diário dois dias seguidos (48
          horas).
          {/* Semana do dia: {new Date(data_inicial).toLocaleString()} */}
        </p>
      </div>
      <div className='campo'>
        <div className='titulo_campo'>
          <h4>Evacuação</h4>
        </div>
        <div className='linha linha_evacuacao'>
          <div className='abrir_modal_evacuacao' onClick={handleShowEvac}>
            <div className='hora_card'>+</div>
            <div className='texto_card'>Adicionar</div>
          </div>
          <div className='ocorrencias_evacuacao'>
            <div className='inner'>
              {fezesHora.map((horariosFezes, idx) => {
                return (
                  <div className='ocorrencia_evacuacao' key={idx}>
                    <div className='hora_card'>
                      {new Date(horariosFezes)
                        .toLocaleTimeString('pt-BR')
                        .slice(0, 5)}
                    </div>
                    <div className='texto_card'>Cocô Tipo {fezesTipo[idx]}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className='campo'>
        <div className='titulo_campo'>
          <h4>Micção</h4>
        </div>
        <div className='linha linha_miccao'>
          <div className='abrir_modal_miccao' onClick={handleShowMicc}>
            <div className='hora_card'>+</div>
            <div className='texto_card'>Adicionar</div>
          </div>
          <div className='ocorrencias_miccao'>
            <div className='inner'>
              {urinaHora.map((horariosUrina, idx) => {
                return (
                  <div className='ocorrencia_miccao' key={idx}>
                    <div className='hora_card'>
                      {new Date(horariosUrina)
                        .toLocaleTimeString('pt-BR')
                        .slice(0, 5)}
                    </div>
                    {urinaUrgencia[idx] === 'perda' ? (
                      <>
                        <div className='texto_card'>Perda</div>
                        <div className='texto_card'>
                          Qtd.: {urinaQuantidade[idx]}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='texto_card'>
                          {urinaQuantidade[idx]} ml
                        </div>
                        <div className='texto_card'>
                          {urinaUrgencia[idx] === 'true'
                            ? 'Urgente'
                            : 'Não urgente'}
                        </div>
                      </>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className='campo'>
        <div className='titulo_campo'>
          <h4>Ingestão de bebidas</h4>
        </div>
        <div className='linha linha_liquido'>
          <div className='abrir_modal_liquido' onClick={handleShowBebida}>
            <div className='hora_card'>+</div>
            <div className='texto_card'>Adicionar</div>
          </div>
          <div className='ocorrencias_liquido'>
            <div className='inner'>
              {bebidaHora.map((horariosBebida, idx) => {
                return (
                  <div className='ocorrencia_liquido' key={idx}>
                    <div className='hora_card'>
                      {new Date(horariosBebida)
                        .toLocaleTimeString('pt-BR')
                        .slice(0, 5)}
                    </div>
                    <div className='texto_card'>{bebidaTipo[idx]}</div>
                    <div className='texto_card'>{bebidaQuantidade[idx]} ml</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      {/* Modal Evacuacao */}
      <Modal show={showEvac} onHide={handleCloseEvac}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Evacuação</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='campos-formulario'>
            <section>
              <FormControl>
                <FormLabel component='legend'>
                  Qual foi o tipo de cocô?
                </FormLabel>
                <Select
                  value={fezesTipoForm}
                  onChange={(e) => setFezesTipoForm(e.target.value)}
                  fullWidth
                >
                  <MenuItem value={1}>Tipo 1 - Bolinhas</MenuItem>
                  <MenuItem value={2}>Tipo 2 - Massa com bolotas</MenuItem>
                  <MenuItem value={3}>Tipo 3 - Banana</MenuItem>
                  <MenuItem value={4}>Tipo 4 - Pedaços moles</MenuItem>
                  <MenuItem value={5}>Tipo 5 - Líquido</MenuItem>
                </Select>
              </FormControl>
            </section>
            <section className='tempo'>
              <TimeSelector
                title='Horário de Eliminação Intestinal (cocô)'
                value={fezesHoraForm}
                onChange={handleTimeChangeEvac}
              />
            </section>
            <SaveButton onClick={saveEvac} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseEvac}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Miccao */}
      <Modal show={showMicc} onHide={handleCloseMicc}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Micção</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='campos-formulario'>
            <section>
              <FormControl component='fieldset'>
                <FormLabel component='legend'>Houve perda urinária?</FormLabel>
                <RadioGroup
                  aria-label='perda'
                  name='perda'
                  value={urinaPerdaForm}
                  onChange={(e) => controlUrinaPerda(e.target.value)}
                >
                  <FormControlLabel
                    value='false'
                    control={<Radio />}
                    label='No banheiro'
                  />
                  <FormControlLabel
                    value='true'
                    control={<Radio />}
                    label='Perda'
                  />
                </RadioGroup>
              </FormControl>
            </section>
            {urinaPerdaForm === 'true' && (
              <section>
                <FormControl>
                  <FormLabel component='legend'>Quantidade</FormLabel>
                  <Select
                    value={urinaQuantidadeForm}
                    onChange={(e) => setUrinaQuantidadeForm(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value={'pouca'}>Pouco</MenuItem>
                    <MenuItem value={'média'}>Médio</MenuItem>
                    <MenuItem value={'muita'}>Muito</MenuItem>
                  </Select>
                </FormControl>
              </section>
            )}
            {urinaPerdaForm === 'false' && (
              <>
                <section>
                  <FormControl component='fieldset'>
                    <FormLabel component='legend'>
                      Urgência (Precisou Sair Correndo para Fazer Xixi?)
                    </FormLabel>
                    <RadioGroup
                      aria-label='urina_urgencia'
                      name='urina_urgencia'
                      value={urinaUrgenciaForm}
                      onChange={(e) => setUrinaUrgenciaForm(e.target.value)}
                    >
                      <FormControlLabel
                        value='false'
                        control={<Radio />}
                        label='Não'
                      />
                      <FormControlLabel
                        value='true'
                        control={<Radio />}
                        label='Sim'
                      />
                    </RadioGroup>
                  </FormControl>
                </section>
                <section>
                  <FormControl component='fieldset'>
                    <FormLabel component='legend'>
                      Qual a quantidade de xixi (ml)?
                    </FormLabel>
                    <TextField
                      value={urinaQuantidadeForm}
                      onChange={(e) => setUrinaQuantidadeForm(e.target.value)}
                      label='Quantidade em ml'
                      variant='outlined'
                      fullWidth
                    />
                  </FormControl>
                </section>
              </>
            )}
            <section className='tempo'>
              <TimeSelector
                title='Horário que Urinou'
                value={urinaHoraForm}
                onChange={handleTimeChangeMicc}
              />
            </section>
            <SaveButton onClick={saveMicc} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseMicc}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Bebida */}
      <Modal show={showBebida} onHide={handleCloseBebida}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Bebida</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='campos-formulario'>
            <section>
              <FormLabel component='legend'>Qual a bebida?</FormLabel>
              <TextField
                value={bebidaTipoForm}
                onChange={(e) => setBebidaTipoForm(e.target.value)}
                label='Água, Suco, Leite,...'
                variant='outlined'
                fullWidth
              />
            </section>
            <section>
              <FormLabel component='legend'>Qual a quantidade?</FormLabel>
              <TextField
                value={bebidaQuantidadeForm}
                onChange={(e) => setBebidaQuantidadeForm(e.target.value)}
                label='Quantidade em ml'
                variant='outlined'
                fullWidth
              />
            </section>
            <section className='tempo'>
              <TimeSelector
                title='Horário de Ingestão de Líquido'
                value={bebidaHoraForm}
                onChange={handleTimeChangeBebida}
              />
            </section>
            <SaveButton onClick={saveBebida} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseBebida}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </PlataformaPadrao>
  )
}
