import React, { useState, useEffect, useContext } from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import PlataformaPadrao from '../../components/plataforma-padrao'
import api from '../../services/api'
import './styles.css'
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Button,
} from '@material-ui/core'

//Import para imagens e assets, Solzinho
import sol_QuaseTodoOTempo from '../../assets/sol-dvss-QuaseTodooTempo.jpeg'
import sol_MetadeDoTempo from '../../assets/sol-dvss-MetadeDoTempo.jpeg'
import sol_MenosQueAMetadeDoTempo from '../../assets/sol-dvss-MenosQueaMetadeDoTempo.jpeg'
import sol_NuncaOuQuaseNunca from '../../assets/sol-dvss-NuncaOuQuaseNunca.jpeg'
import { UserContext } from '../../contexts/user'

export default function FormularioDVSS() {
  const { user } = useContext(UserContext)
  const history = useHistory()
  const [pergunta_1, setPergunta_1] = useState('0')
  const [pergunta_2, setPergunta_2] = useState('0')
  const [pergunta_3, setPergunta_3] = useState('0')
  const [pergunta_4, setPergunta_4] = useState('0')
  const [pergunta_5, setPergunta_5] = useState('0')
  const [pergunta_6, setPergunta_6] = useState('0')
  const [pergunta_7, setPergunta_7] = useState('0')
  const [pergunta_8, setPergunta_8] = useState('0')
  const [pergunta_9, setPergunta_9] = useState('0')
  const [pergunta_10, setPergunta_10] = useState('0')
  const [total, setTotal] = useState(0)
  const [data_inicial, setDataInicial] = useState([])
  let { id } = useParams()
  const [idPaciente, setIdPaciente] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getData() {
      await api.get(`dvss/${id}`).then((response) => {
        setPergunta_1(response.data.pergunta_1.toString())
        setPergunta_2(response.data.pergunta_2.toString())
        setPergunta_3(response.data.pergunta_3.toString())
        setPergunta_4(response.data.pergunta_4.toString())
        setPergunta_5(response.data.pergunta_5.toString())
        setPergunta_6(response.data.pergunta_6.toString())
        setPergunta_7(response.data.pergunta_7.toString())
        setPergunta_8(response.data.pergunta_8.toString())
        setPergunta_9(response.data.pergunta_9.toString())
        setPergunta_10(response.data.pergunta_10.toString())
        setTotal(response.data.total)
        setDataInicial(response.data.data_inicial.toString())
        setIdPaciente(response.data.id_paciente)
        api.get(`paciente/${response.data.id_paciente}`).then(({ data }) => {
          if (
            user.id_global === response.data.id_paciente ||
            user.id_global === data.id_pesquisador
          ) {
            setLoading(false)
          } else {
            history.push(`/sem-permissao`)
          }
        })
      })
    }
    getData()
  }, [])

  useEffect(() => {
    setTotal(
      parseInt(pergunta_1, 10) +
        parseInt(pergunta_2, 10) +
        parseInt(pergunta_3, 10) +
        parseInt(pergunta_4, 10) +
        parseInt(pergunta_5, 10) +
        parseInt(pergunta_6, 10) +
        parseInt(pergunta_7, 10) +
        parseInt(pergunta_8, 10) +
        parseInt(pergunta_9, 10) +
        parseInt(pergunta_10, 10)
    )
  }, [
    pergunta_1,
    pergunta_2,
    pergunta_3,
    pergunta_4,
    pergunta_5,
    pergunta_6,
    pergunta_7,
    pergunta_8,
    pergunta_9,
    pergunta_10,
    data_inicial,
  ])

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  function handleSubmit(e) {
    e.preventDefault()
    const data = {
      pergunta_1,
      pergunta_2,
      pergunta_3,
      pergunta_4,
      pergunta_5,
      pergunta_6,
      pergunta_7,
      pergunta_8,
      pergunta_9,
      pergunta_10,
      total,
    }

    api
      .put(`dvss/${id}`, data)
      .then(() => {
        api.post('dvss_warn', { idPaciente }).catch((error) => {
          alert('Erro ao notificar a(o) enfermeira(o)! Contate a administração.')
          console.log(error)
        })
      })
      .catch((error) => {
        alert('Erro! Tente novamente.')
        console.log(error)
      })

    alert('Operação realizada com sucesso!')
    history.goBack()
  }

  return (
    <PlataformaPadrao title="Escore de Sintoma de Disfunção Miccional">
      <div className="subtitulo_instrucoes">
        <p id="datainicial">
          Com base nos últimos 30 dias, considerar a data de HOJE que você está
          preenchendo o Escore de Sintoma de Disfunção Miccional:
          {/* Semana do dia: {new Date(data_inicial).toLocaleString()} */}
        </p>
      </div>
      <div id="dvssimg">
        <img className="imagem_sol" src={sol_QuaseTodoOTempo} />
        <img className="imagem_sol" src={sol_MetadeDoTempo} />
        <img className="imagem_sol" src={sol_MenosQueAMetadeDoTempo} />
        <img className="imagem_sol" src={sol_NuncaOuQuaseNunca} />
      </div>
      <pre></pre>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          1. Seu(a) filho(a) tem molhado de xixi a roupa durante o dia?
        </FormLabel>
        <RadioGroup
          aria-label="pergunta1"
          name="pergunta1"
          value={pergunta_1}
          onChange={(e) => setPergunta_1(e.target.value)}
        >
          <FormControlLabel
            value="0.01"
            control={<Radio />}
            label="(0) Nunca ou quase nunca"
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="(1) Menos que a metade do tempo"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="(2) Metade do tempo"
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label="(3) Quase todo o tempo"
          />
        </RadioGroup>
        <pre></pre>
        <FormLabel component="legend">
          2. Quando seu(a) filho(a) se molha de xixi, a cueca ou calcinha fica
          ensopada?
        </FormLabel>
        <RadioGroup
          aria-label="pergunta2"
          name="pergunta2"
          value={pergunta_2}
          onChange={(e) => setPergunta_2(e.target.value)}
        >
          <FormControlLabel
            value="0.01"
            control={<Radio />}
            label="(0) Nunca ou quase nunca"
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="(1) Menos que a metade do tempo"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="(2) Metade do tempo"
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label="(3) Quase todo o tempo"
          />
        </RadioGroup>
        <pre></pre>
        <FormLabel component="legend">
          3. Com que frequência seu(a) filho(a) não faz cocô todos os dias?
        </FormLabel>
        <RadioGroup
          aria-label="pergunta3"
          name="pergunta3"
          value={pergunta_3}
          onChange={(e) => setPergunta_3(e.target.value)}
        >
          <FormControlLabel
            value="0.01"
            control={<Radio />}
            label="(0) Nunca ou quase nunca"
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="(1) Menos que a metade do tempo"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="(2) Metade do tempo"
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label="(3) Quase todo o tempo"
          />
        </RadioGroup>
        <pre></pre>
        <FormLabel component="legend">
          4. Seu(a) filho(a) tem que fazer força para fazer cocô?
        </FormLabel>
        <RadioGroup
          aria-label="pergunta4"
          name="pergunta4"
          value={pergunta_4}
          onChange={(e) => setPergunta_4(e.target.value)}
        >
          <FormControlLabel
            value="0.01"
            control={<Radio />}
            label="(0) Nunca ou quase nunca"
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="(1) Menos que a metade do tempo"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="(2) Metade do tempo"
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label="(3) Quase todo o tempo"
          />
        </RadioGroup>
        <pre></pre>
        <FormLabel component="legend">
          5. Com que frequência seu(a) filho(a) só vai ao banheiro fazer xixi
          uma ou duas vezes por dia?
        </FormLabel>
        <RadioGroup
          aria-label="pergunta5"
          name="pergunta5"
          value={pergunta_5}
          onChange={(e) => setPergunta_5(e.target.value)}
        >
          <FormControlLabel
            value="0.01"
            control={<Radio />}
            label="(0) Nunca ou quase nunca"
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="(1) Menos que a metade do tempo"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="(2) Metade do tempo"
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label="(3) Quase todo o tempo"
          />
        </RadioGroup>
        <pre></pre>
        <FormLabel component="legend">
          6. Seu(a) filho(a) segura o xixi cruzando as pernas, agachando ou
          dançando?
        </FormLabel>
        <RadioGroup
          aria-label="pergunta6"
          name="pergunta6"
          value={pergunta_6}
          onChange={(e) => setPergunta_6(e.target.value)}
        >
          <FormControlLabel
            value="0.01"
            control={<Radio />}
            label="(0) Nunca ou quase nunca"
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="(1) Menos que a metade do tempo"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="(2) Metade do tempo"
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label="(3) Quase todo o tempo"
          />
        </RadioGroup>
        <pre></pre>
        <FormLabel component="legend">
          7. Quando seu(a) filho(a) precisa fazer xixi tem que ir rápido ao
          banheiro? (não consegue esperar)
        </FormLabel>
        <RadioGroup
          aria-label="pergunta7"
          name="pergunta7"
          value={pergunta_7}
          onChange={(e) => setPergunta_7(e.target.value)}
        >
          <FormControlLabel
            value="0.01"
            control={<Radio />}
            label="(0) Nunca ou quase nunca"
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="(1) Menos que a metade do tempo"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="(2) Metade do tempo"
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label="(3) Quase todo o tempo"
          />
        </RadioGroup>
        <pre></pre>
        <FormLabel component="legend">
          8. Seu(a) filho(a) tem que fazer força para fazer xixi?
        </FormLabel>
        <RadioGroup
          aria-label="pergunta8"
          name="pergunta8"
          value={pergunta_8}
          onChange={(e) => setPergunta_8(e.target.value)}
        >
          <FormControlLabel
            value="0.01"
            control={<Radio />}
            label="(0) Nunca ou quase nunca"
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="(1) Menos que a metade do tempo"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="(2) Metade do tempo"
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label="(3) Quase todo o tempo"
          />
        </RadioGroup>
        <pre></pre>
        <FormLabel component="legend">
          9. Seu(a) filho(a) disse que sente dor quando faz xixi?
        </FormLabel>
        <RadioGroup
          aria-label="pergunta9"
          name="pergunta9"
          value={pergunta_9}
          onChange={(e) => setPergunta_9(e.target.value)}
        >
          <FormControlLabel
            value="0.01"
            control={<Radio />}
            label="(0) Nunca ou quase nunca"
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="(1) Menos que a metade do tempo"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="(2) Metade do tempo"
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label="(3) Quase todo o tempo"
          />
        </RadioGroup>
        <pre></pre>
        <FormLabel component="legend">
          10.Seu(a) filho(a) passou por <strong>alguma</strong> situação
          estressante como as dos exemplos abaixo nos últimos 30 dias?
        </FormLabel>
        <ul class="listaDVSS" style={{ fontSize: 16 }}>
          <li>Bebê novo em casa</li>
          <li>Mudança de Casa</li>
          <li>Mudança de Escola</li>
          <li>Problemas Escolares</li>
          <li>Abuso (sexual/físico)</li>
          <li>Problemas em casa (divórcio/morte)</li>
          <li>Eventos especiais (aniversário)</li>
          <li>Acidente/Ferimento</li>
          <li>Outros</li>
        </ul>
        <RadioGroup
          aria-label="pergunta10"
          name="pergunta10"
          value={pergunta_10}
          onChange={(e) => setPergunta_10(e.target.value)}
        >
          <FormControlLabel value="0.1" control={<Radio />} label="Não" />
          <FormControlLabel value="3" control={<Radio />} label="Sim" />
        </RadioGroup>
        <Paper
          style={{
            width: '35%',
            textAlign: 'center',
            margin: 'auto',
            padding: 10,
          }}
          elevation={11}
        >
          <h1>Escore: {total}</h1>
        </Paper>
        <div className="btn-send">
          <Button onClick={handleSubmit} color="secondary" variant="contained">
            concluir
          </Button>
        </div>
      </FormControl>
    </PlataformaPadrao>
  )
}
