import './styles.css'

import { Link, useHistory } from 'react-router-dom'
import { React, useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Footer from '../../components/Footer'
import Gisele from '../../assets/Gisele.jpeg'
import Imagem_Para_Tela_Incial from '../../assets/imagem_para_tela_inicial.png'
import Imagem_SolChuva from '../../assets/solChuva.png'
import Imagem_SolDVSSMenosQueaMetadeDoTempo from '../../assets/sol-dvss-MenosQueaMetadeDoTempo.jpeg'
import Imagem_SolSolChuva from '../../assets/sol_sol_chuva.png'
import NavBar from '../../components/Navbar'
import api from '../../services/api'
import big_family from '../../assets/big-family.png'
import crianca from '../../assets/crianca.jpg'
import enfermeiros_inicio from '../../assets/enfermeiros_inicio.png'
import equipe from '../../assets/equipe.jpg'
import estudante_inicio from '../../assets/estudante-inicio.png'
import extensaoimg from '../../assets/equipe2.png'
import home_4 from '../../assets/home_4.png'
import home_6 from '../../assets/pesq2.png'
import home_7 from '../../assets/home_7.jpg'
import home_8 from '../../assets/estudo1.png'
import nossotrabalho from '../../assets/nossotrabalho.png'
import noticia_exemplo1 from '../../assets/noticia_exemplo1.jpg'
import noticia_exemplo2 from '../../assets/noticia_exemplo2.jpg'
import ourgoal from '../../assets/enfem1.jpeg'
import pesquisadores_inicio from '../../assets/pesquisadores-inicio.png'
import trabalho from '../../assets/trabalho.jpg'
import valoress from '../../assets/valores.png'

export default function Home() {
  const history = useHistory()
  const [card_tab1, setCard_tab1] = useState(true)
  const [card_tab2, setCard_tab2] = useState(false)
  const [card_tab3, setCard_tab3] = useState(false)
  const [card_tab4, setCard_tab4] = useState(true)
  const [card_tab5, setCard_tab5] = useState(false)
  const [card_tab6, setCard_tab6] = useState(false)

  const [noticiasPreview, setNoticiasPreview] = useState([])
  const [first, setFirst] = useState(true)

  if (first) {
    setFirst(false)
  }

  useEffect(() => {
    async function getNoticiasPreview() {
      await api.get(`ultimas_noticias`).then((response) => {
        setNoticiasPreview(response.data)
      })
    }
    getNoticiasPreview()
  }, [])

  function handleTab1() {
    setCard_tab1(true)
    setCard_tab2(false)
    setCard_tab3(false)
  }

  function handleTab2() {
    setCard_tab1(false)
    setCard_tab2(true)
    setCard_tab3(false)
  }

  function handleTab3() {
    setCard_tab1(false)
    setCard_tab2(false)
    setCard_tab3(true)
  }

  function handleTab4() {
    setCard_tab4(true)
    setCard_tab5(false)
    setCard_tab6(false)
    console.log('tab4', card_tab4, card_tab5, card_tab6)
  }

  function handleTab5() {
    setCard_tab4(false)
    setCard_tab5(true)
    setCard_tab6(false)
    console.log('tab5', card_tab4, card_tab5, card_tab6)
  }

  function handleTab6() {
    setCard_tab4(false)
    setCard_tab5(false)
    setCard_tab6(true)
    console.log('tab6', card_tab4, card_tab5, card_tab6)
  }

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div className='inicio'>
        <div className='texto_inicial'>
          <h2>Pesquisa, ensino e extensão</h2>
          <p className='card_texto'>
            Trata-se de um programa de pesquisa, ensino e extensão, vinculado ao
            Departamento de Enfermagem da Universidade de Brasília (ENF/UnB) que
            visa promover excelência e inovação na avaliação, diagnóstico e
            manejo de sintomas urinários e intestinais durante a infância e
            adolescência na era da Saúde Digital.
          </p>
          <p className='card_texto'>
            Nesse sentido, o programa desenvolve atividades conjuntas de
            pesquisa, ensino e extensão, integrando a comunidade externa à
            Universidade.
          </p>
        </div>
        <div className='imagem_inicial'>
          <div className='foto_inicial'>
            <img src={home_4}></img>
          </div>
        </div>
      </div>
      <div className='tabs'>
        <div className='conjunto_botoes'>
          <div className='botao_tab1' onClick={handleTab1}>
            Pesquisa
          </div>
          <div className='botao_tab1' onClick={handleTab2}>
            Ensino
          </div>
          <div className='botao_tab1' onClick={handleTab3}>
            Extensão
          </div>
        </div>
        {card_tab1 && (
          <div className='tab'>
            <div className='card_tab1'>
              <p className='card_texto'>
                As pesquisas são baseadas na busca contínua por geração,
                aplicação e disseminação de evidências para um cuidado de
                excelência a crianças e adolescentes com sintomas urinários e/ou
                intestinais.
              </p>
            </div>
            <div className='circulo_card_tab1'>
              {/* <a href='https://www.freepik.com/free-photos-vectors/people'>People vector created by katemangostar - www.freepik.com</a> */}
              <img src={home_6}></img>
            </div>
          </div>
        )}
        {card_tab2 && (
          <div className='tab_central'>
            <div className='card_tab2'>
              <p className='card_texto'>
                As práticas de ensino estão voltadas para a capacitação
                orientada ao cuidado virtual em Uropediatria, direcionadas a
                profissionais e estudantes da área da saúde, visando uma
                assistência empática, qualificada e fundamentada na promoção e
                fortalecimento de ações de autocuidado apoiado. Envolvem a
                realização de disciplinas, orientações, estágio, mentoria e
                eventos científicos, visando contribuir na formação da próxima
                geração de enfermeiros-cientistas a serem formad@s pela
                Universidade de Brasília.
              </p>
            </div>
            <div className='circulo_card_tab2direita'>
              {/* <a href='https://www.freepik.com/free-photos-vectors/school'>School vector created by pch.vector - www.freepik.com</a> */}
              <img src={home_8}></img>
            </div>
          </div>
        )}
        {card_tab3 && (
          <div className='tab'>
            <div className='card_tab3'>
              <p className='card_texto'>
                Desenvolvida por meio de 2 ações de extensão:
              </p>
              <p className='card_texto'>
                - Portal de informações em saúde urológica pediátrica para
                crianças, adolescentes, famílias e profissionais;
              </p>
              <p className='card_texto'>
                - Plataforma virtual para autocuidado apoiado em Uropediatria.
              </p>
            </div>
            <div className='circulo_card_tab3'>
              {/* <a href='https://www.freepik.com/free-photos-vectors/school'>School vector created by pch.vector - www.freepik.com</a> */}
              <img src={extensaoimg}></img>
            </div>
          </div>
        )}
      </div>
      <div className='tabs2'>
        <div className='conjunto_botoes2'>
          <div className='botao_tab2' onClick={handleTab4}>
            Família
          </div>
          <div className='botao_tab2' onClick={handleTab5}>
            Enfermeiras(os) & Estudantes
          </div>
          <div className='botao_tab2' onClick={handleTab6}>
            Pesquisadores
          </div>
        </div>
        {card_tab4 && (
          <div className='tab'>
            <div className='card_tab1'>
              <p className='card_texto'>
                Plataforma virtual para autocuidado apoiado em Uropediatria.
              </p>
              <p className='card_texto'>
                Nosso apoio para autocuidado é customizado para cada caso e
                disponível em apenas um click!
              </p>
            </div>
            <div className='circulo_card_tab1'>
              {/* <a href='https://br.freepik.com/vetores-gratis/grande-reuniao-de-familia_7732593.htm</a> */}
              <img src={big_family}></img>
            </div>
          </div>
        )}
        {card_tab5 && (
          <div className='tab_central2'>
            <div className='card_tab2'>
              <p className='card_texto'>
                Programa de ensino e capacitação para cuidado virtual em
                Uropediatria.
                <br />
                <br />
                Direcionada a profissionais e estudantes da área da saúde,
                visando uma assistência empática, qualificada e fundamentada na
                promoção e fortalecimento de ações de autocuidado no contexto da
                Uropediatria.
              </p>
            </div>
            <div className='circulo_card_tab2'>
              {/* <a href='https://www.freepik.com/free-photos-vectors/school'>School vector created by pch.vector - www.freepik.com</a> */}
              <img src={enfermeiros_inicio}></img>
            </div>
            <div className='circulo_card_tab12'>
              {/* <a href='https://www.freepik.com/free-photos-vectors/school'>School vector created by pch.vector - www.freepik.com</a> */}
              <img src={estudante_inicio}></img>
            </div>
          </div>
        )}
        {card_tab6 && (
          <div className='tab'>
            <div className='card_tab3'>
              <p className='card_texto'>
                Programa de pesquisa de Prática Avançada de Enfermagem em
                Uropediatria.
              </p>
              <p className='card_texto'>
                Busca contínua na geração, aplicação e disseminação de
                evidências para um cuidado de excelência a crianças e
                adolescentes com sintomas urinários e/ou intestinais.
              </p>
            </div>
            <div className='circulo_card_tab32'>
              {/* <a href='https://www.freepik.com/free-photos-vectors/school'>School vector created by pch.vector - www.freepik.com</a> */}
              <img src={pesquisadores_inicio}></img>
            </div>
          </div>
        )}
      </div>
      <div className='noticias_previa'>
        {noticiasPreview.map((noticiasPreview) => {
          return (
            <div
              className='previa_da_noticia'
              onClick={() => {
                history.push(`/postagem/${noticiasPreview.id_noticia}`)
              }}
            >
              <img
                src={`${process.env.REACT_APP_API_URL}/files/uploads/${noticiasPreview.imagem}`}
              ></img>
              <div className='div_titulo_noticia_previa'>
                <h5>{noticiasPreview.titulo}</h5>
              </div>
            </div>
          )
        })}
        {/* <div className="previa_da_noticia">
          <img src={noticia_exemplo1}></img>
          <div className="div_titulo_noticia_previa">
            <h5>Título referente à última notícia</h5>
          </div>
        </div>
        <div className="previa_da_noticia">
          <img src={noticia_exemplo2}></img>
          <div className="div_titulo_noticia_previa">
            <h5>Título referente à penúltima notícia</h5>
          </div>
        </div> */}
      </div>
      <div className='historia'>
        <div className='card_historia'>
          <div className='card_historia_titulo'>
            <h3 className='titles'>Nossa História</h3>
          </div>
          <div className='card_historia_texto'>
            <p className='card_texto'>
              Esse programa de pesquisa, ensino e extensão foi criado pela
              Profa. Dra. Gisele Martins, que é uma das poucas
              enfermeiras-pesquisadoras da área de Urologia Pediátrica do Brasil
              e também do mundo, com titulação de doutorado (PhD) e
              pós-doutorado na área.
              <br />
              <br />
              A Profa. Gisele nasceu com algumas malformações congênitas
              (inclusive no trato urinário), que foram corrigidas cirurgicamente
              ao longo de sua infância e adolescência. Em virtude dessa
              experiência de vida, a professora decidiu dedicar a sua carreira
              de enfermeira, docente e cientista na área de Enfermagem em
              Urologia Pediátrica. No Brasil, a legislação profissional sobre o
              papel de Enfermeira(o) de Prática Avançada ainda se encontra em
              processo de construção e debate. Portanto, a Profa. Gisele
              desenvolveu sua expertise em Uropediatria tanto clínica quanto
              científica, por meio de treinamentos e capacitações sistemáticas
              no país e no exterior (principalmente, no Canadá e Estados
              Unidos). Assim, nasceu a Prática Avançada de Enfermagem em
              Uropediatria!
              <br />
              <br />
              CV Lattes:{' '}
              <a
                className='link_lattes'
                href='http://lattes.cnpq.br/0938329840216223'
                target='_blank'
              >
                http://lattes.cnpq.br/0938329840216223
              </a>
            </p>
          </div>
          <div className='card_historia_circulo'>
            <img src={Gisele}></img>
          </div>
        </div>
      </div>
      <div className='visao'>
        <div className='card_visao'>
          <div className='card_visao_titulo'>
            <h3 className='titles'>Visão</h3>
          </div>
          <div className='card_visao_texto'>
            <p className='card_texto'>
              Toda família, criança e adolescente precisa compreender seu
              protagonismo ao gerenciar seus sintomas urinários e intestinais
            </p>
          </div>
          <div className='card_visao_circulo'>
            {/*<a href='https://www.freepik.com/free-photos-vectors/people'>People vector created by freepik - www.freepik.com</a>*/}
            <img src={ourgoal}></img>
          </div>
        </div>
      </div>
      <div className='missao'>
        <div className='card_missao'>
          <div className='card_missao_titulo'>
            <h3 className='titles'>Missão</h3>
            {/* <a href='https://www.freepik.com/free-photos-vectors/school'>School vector created by pch.vector - www.freepik.com</a> */}
          </div>
          <div className='card_missao_texto'>
            <p className='card_texto'>
              Divulgar informações de qualidade e dar apoio na jornada de
              autocuidado da criança e do adolescente com sintomas urinários e
              intestinais, e suas famílias
            </p>
          </div>
          <div className='card_missao_circulo'>
            {/* <a href="https://www.freepik.com/free-photos-vectors/medical">Medical vector created by stories - www.freepik.com</a> */}
            <img src={home_7}></img>
          </div>
        </div>
      </div>
      <div className='valores'>
        <div className='card_valores'>
          <div className='card_valores_titulo'>
            <h3 className='titles'>Valores</h3>
          </div>
          <div className='card_valores_texto'>
            <p className='card_texto'>
              1. Excelência
              <br />
              2. Cuidado centrado na criança e família
              <br />
              3. Empatia
              <br />
              4. Advocacy
              <br />
              5. Protagonismo
            </p>
          </div>
          <div className='card_valores_circulo'>
            {/*<a href='https://www.freepik.com/free-photos-vectors/people'>People vector created by freepik - www.freepik.com</a>*/}
            <img src={valoress}></img>
          </div>
        </div>
      </div>
      <div className='equipe'>
        <div className='card_equipe'>
          <div className='card_equipe_titulo'>
            <h3 className='titles'>Nossa equipe</h3>
            {/* <a href='https://www.freepik.com/free-photos-vectors/school'>School vector created by pch.vector - www.freepik.com</a> */}
          </div>
          <div className='card_equipe_texto'>
            <p className='card_texto'>
              1. Profa. Dra. Gisele Martins (fundadora e coordenadora do
              programa)
              <br />
              2. Enfermeiras(os) que estão fazendo o curso de mestrado ou
              doutorado sob orientação da Profa.Gisele
              <br />
              3. Enfermeiras(os) voluntárias(os) que tem interesse em aprimorar
              o conhecimento na área
              <br />
              4. Estudantes extensionistas que participam do projeto de extensão
              (PEAC)
              <br />
              5. Estudantes que estão fazendo pesquisas de iniciação científica
              e/ou trabalhos de conclusão de curso (TCC)
            </p>
          </div>
          <div className='card_equipe_circulo'>
            {/* <a href='https://www.freepik.com/free-photos-vectors/school'>School vector created by pch.vector - www.freepik.com</a> */}
            <img src={equipe}></img>
          </div>
        </div>
      </div>
      <div className='trabalho'>
        <div className='card_trabalho'>
          <div className='card_trabalho_titulo'>
            <h3 className='titles'>Nosso trabalho</h3>
            {/* <a href='https://www.freepik.com/free-photos-vectors/school'>School vector created by pch.vector - www.freepik.com</a> */}
          </div>
          <div className='card_trabalho_texto'>
            <p className='card_texto'>
              1. Produzir tecnologias educativas digitais (postagens, vídeos,
              campanhas) baseadas nas melhores evidências científicas, que dão
              suporte à prática do autocuidado apoiado pela família da criança
              ou adolescente com sintomas urinários e intestinais.
              <br />
              2. Criar soluções e experiências virtuais de autocuidado
              inovadoras, valorizando a parceria colaborativa entre crianças,
              adolescentes, famílias e profissionais.
              <br />
              3. Capacitar os estudantes de graduação e pós-graduação para
              desenvolver estratégias de tradução/mobilização do conhecimento em
              plataformas virtuais e mídias sociais, com vistas a facilitar a
              disseminação das melhores evidências e sua implementação na
              prática tanto por profissionais quanto por usuários dos serviços
              de saúde.
              <br />
              4. Estabelecer uma rede de suporte social e parceria colaborativa
              entre membros de nossa equipe e profissionais, estudantes,
              famílias, crianças e adolescentes com sintomas urinários e/ou
              intestinais.
            </p>
          </div>
          <div className='card_trabalho_circulo'>
            {/*<a href='https://www.freepik.com/free-photos-vectors/people'>People vector created by freepik - www.freepik.com</a>*/}
            <img src={nossotrabalho}></img>
          </div>
        </div>
      </div>
      <div className='beneficio'>
        <div className='card_missao'>
          <div className='card_missao_titulo'>
            <h3 id='titulo_missao2' className='titles'>
              Quais crianças e adolescentes
            </h3>
            <h3 id='titulo_missao2' className='titles'>
              podem se beneficiar com{' '}
            </h3>
            <h3 id='titulo_missao2' className='titles'>
              a nossa ajuda?
            </h3>
          </div>
          <div className='card_missao_texto'>
            <p className='card_texto'>
              1. Com malformações na coluna vertebral, que levam às disfunções
              da bexiga e do intestino (mielomeningocele, por exemplo)
              <br />
              2. Com malformações complexas do trato geniturinário já corrigidas
              ou não cirurgicamente;
              <br />
              3. Com atrasos neurocognitivos e/ou do desenvolvimento;
              <br />
              4. Com quadro de mobilidade reduzida;
              <br />
              5. Com perdas de urina e/ou fezes, mesmo sem ter problema de saúde
              ou causa aparente;
              <br />
              6. Com dificuldades no processo de retirada das fraldas;
              <br />
              7. Em uso de cateterismo intermitente ou outros dispositivos
              (urostomias, por exemplo);
              <br />
              8. Em fase de transição de atendimento pediátrico para adulto.
            </p>
          </div>
          <div className='card_missao_circulo'>
            {/* <a href="https://www.freepik.com/free-photos-vectors/medical">Medical vector created by stories - www.freepik.com</a> */}
            <img src={home_7}></img>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </>
  )
}
