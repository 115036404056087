import { FiTrash2, FiEye } from 'react-icons/fi'
import { FaRegEdit } from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal'
import api from '../../services/api'
import './styles.css'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Container, Spinner } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import FormularioPreenchidoFamilia from '../FormularioPreenchidoFamilia'
import FormularioPreenchidoFamiliaEditavel from '../FormularioPreenchidoFamiliaEditavel'
import { useHistory } from 'react-router-dom'

export default function ItemListaPacientesAlta({
  excluir,
  editar,
  olhar,
  paciente,
}) {
  const history = useHistory()

  const [showVer, setShowVer] = useState(false)

  const handleCloseVer = () => setShowVer(false)
  const handleShowVer = () => setShowVer(true)

  let pagina_cada_paciente = `/cada-paciente/${paciente.id_global}`

  return (
    <>


      <tr>
        {/* <div className="itens-da-linha">*/}
        <td >{paciente.nome}</td>
        <td >{paciente.telefone1}</td>
        <td >{paciente.id_global}</td>
        {/* </div>*/}
        {/*<div className="botoes-lista">*/}
        <td> <Button variant="primary" onClick={handleShowVer}>
          DADOS
        </Button></td>
        <td> <Button variant="primary" href={pagina_cada_paciente}>
          PASTA
        </Button></td>

        {/* </div>} */}
      </tr>

      {/* Modal de visualizar dados */}
      <Modal show={showVer} onHide={handleCloseVer}>
        <Modal.Header closeButton>
          <Modal.Title>Dados de {paciente.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <section>
              <TextField
                className="textarea"
                label="Nome Completo do Paciente"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.nome}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Nome Completo do Responsável"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.nome_responsavel}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Telefone para contato 1"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.telefone1}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Telefone para contato 2"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.telefone2}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Cidade/Estado"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.cidade_estado}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="E-mail do Responsável"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.email_responsavel}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Data de Nascimento do Paciente"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.data_nascimento}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Data de Nascimento do Responsável"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.data_nascimento_responsavel}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Sexo"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.sexo}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Altura do Paciente (em centímetros)"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.altura}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Peso do Paciente (em quilogramas)"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.peso}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Escola do Paciente"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.escola}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="CPF do Paciente"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.cpf}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="RG do Paciente"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.rg}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Grau de Parentesco entre Responsável e Paciente"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.parentesco}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="CPF do Responsável"
                disabled
                id="outlined-disabled"
                defaultValue={paciente.cpf_responsavel}
                variant="outlined"
              />
            </section>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseVer}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
