import React, { useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { FiMenu, FiX } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo.png'
import './styles.css'

export default function NavBar() {
  const [toggleVariable, setToggleVariable] = useState(true)

  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      className='navbar-main fixed-top'
      variant='dark'
    >
      <Navbar.Brand>
        <div className='navbar-row'>
          <Link to='/'>
            <img
              alt=''
              src={Logo}
              width='60'
              height='60'
              className='d-inline-block align-top'
            />
          </Link>
          <Link to='/' className='navbar-slogan'>
            Prática Avançada de Enfermagem
            <br />
            em Uropediatria
          </Link>
        </div>
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls='responsive-navbar-nav'
        onClick={() => {
          setToggleVariable(!toggleVariable)
        }}
      >
        {toggleVariable ? (
          <FiMenu size={30} color='#ffffff' />
        ) : (
          <FiX size={30} color='#ffffff' />
        )}
      </Navbar.Toggle>
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='ml-auto'>
          <Link to='/' className='navbar-slogan-mobile'>
            Prática Avançada de Enfermagem em Uropediatria
          </Link>
          {/* <Link to="/" className="navbar-links" id="link-borda">Nossa História</Link> */}
          <div
            className='navbar-links'
            style={{
              backgroundColor: '#e04a4a',
              padding: '3px 0',
              borderRadius: '5px',
              marginLeft: '5px',
            }}
          >
            <Link to='/blog' className='navbar-links portal-informacoes'>
              Portal de informações
            </Link>
          </div>
          <div
            className='navbar-links'
            style={{ padding: '3px 0', borderRadius: '5px', marginLeft: '5px' }}
          >
            <Link to='/login' className='navbar-links login-cadastro'>
              {' '}
              <i class='fas fa-sign-in-alt'></i> Login/Cadastro
            </Link>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
