import React from 'react'
import './styles.css'
import BlogImg from '../../assets/Blog.png'
import { Image, Container } from 'react-bootstrap'

export default function HeaderBlog() {
  return (
    <>
      <div className="background-header">
        <div className="middle-text">
          <h1 className="titulo-header">
            Portal de informações em saúde urológica pediátrica para crianças,
            adolescentes, famílias e profissionais
          </h1>
          <Image src={BlogImg} className="img-blog-header" />
          <h2 className="subtitulo-header">
            Local para acesso às informações científicas da área de
            Uropediatria, com objetivo de facilitar o acesso e a experiência
            virtual de busca de informações em saúde de qualidade.
          </h2>
        </div>
      </div>
    </>
  )
}
