import React, { useState } from 'react'
import { Container, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import './styles.css'
import { Button } from '@material-ui/core'
import api from '../../services/api'
import { TextField } from '@material-ui/core'
import PlataformaPadrao from '../../components/plataforma-padrao'
import InputMask from 'react-input-mask'
import { useParams } from 'react-router'

export default function FormularioSAE() {
  const history = useHistory()
  let { id_sae } = useParams()

  const [id_paciente, setIdpaciente] = useState(1)
  const [id_pesquisador, setIdpesquisador] = useState(1)
  const [acompanhante, setAcompanhante] = useState('')
  const [queixa, setQueixa] = useState('')
  const [historico, setHistorico] = useState('')
  const [avaliacao_urinario, setAvaliacaoUrinario] = useState('')
  const [avaliacao_intestinal, setAvaliacaoIntestinal] = useState('')
  const [exame_fisico, setExameFisico] = useState('')
  const [diagnostico, setDiagnostico] = useState('')
  const [intervencoes, setIntervencoes] = useState('')
  const [resultados_esperados, setResultadosEsperados] = useState('')
  const [data_proxima_consulta, setDataProximaConsulta] = useState('')
  const [data_consulta, setDataConsulta] = useState(new Date().toUTCString())
  const [num_acompanhamento, setNumAcompanhamento] = useState('')

  async function handleCadastro(e) {
    e.preventDefault()

    const data1 = {
      id_sae,
      id_paciente,
      id_pesquisador,
      acompanhante,
      queixa,
      historico,
      avaliacao_urinario,
      avaliacao_intestinal,
      exame_fisico,
      diagnostico,
      intervencoes,
      resultados_esperados,
      data_proxima_consulta,
      data_consulta:0,
      num_acompanhamento:0,
    }

    console.log(data1) //teste

    try {
      const response = await api.post('consulta', data1)
      console.log(response)
      alert('OK')
      history.push(`/pasta-consulta/${id_sae}`)
    } catch (err) {
      alert('Erro ao Cadastrar Formulário. Tente Novamente.')
    }
  }

  return (
    <PlataformaPadrao title="Formulário de Preenchimento - SAE">
      <Container>
        <Form onSubmit={handleCadastro}>
          <section>
            <TextField
              className="textarea"
              value={acompanhante}
              onChange={(e) => setAcompanhante(e.target.value)}
              required
              id="outlined-basic"
              label="Acompanhado por: (mãe, pai, avó, avô, ...)"
              variant="outlined"
            />
          </section>
          <section>
            <TextField
              className="textarea"
              value={queixa}
              onChange={(e) => setQueixa(e.target.value)}
              required
              id="outlined-basic"
              label="Queixa principal"
              variant="outlined"
            />
          </section>
          <section>
            <TextField
              className="textarea"
              value={historico}
              onChange={(e) => setHistorico(e.target.value)}
              required
              id="outlined-basic"
              label="Histórico de Enfermagem em Uropediatria"
              variant="outlined"
              multiline
              rows={16}
            />
          </section>
          <section>
            <TextField
              className="textarea"
              value={avaliacao_urinario}
              onChange={(e) => setAvaliacaoUrinario(e.target.value)}
              required
              id="outlined-basic"
              label="Avaliação do Sistema Urinário"
              variant="outlined"
              multiline
              rows={16}
            />
          </section>
          <section>
            <TextField
              className="textarea"
              value={avaliacao_intestinal}
              onChange={(e) => setAvaliacaoIntestinal(e.target.value)}
              required
              id="outlined-basic"
              label="Avaliação do Sistema Intestinal"
              variant="outlined"
              multiline
              rows={16}
            />
          </section>
          <section>
            <TextField
              className="textarea"
              value={exame_fisico}
              onChange={(e) => setExameFisico(e.target.value)}
              required
              id="outlined-basic"
              label="Exame Físico"
              variant="outlined"
              multiline
              rows={16}
            />
          </section>
          <section>
            <TextField
              className="textarea"
              value={diagnostico}
              onChange={(e) => setDiagnostico(e.target.value)}
              required
              id="outlined-basic"
              label="Diagnóstico de Enfermagem em Uropediatria"
              variant="outlined"
              multiline
              rows={16}
            />
          </section>
          <section>
            <TextField
              className="textarea"
              value={intervencoes}
              onChange={(e) => setIntervencoes(e.target.value)}
              required
              id="outlined-basic"
              label="Intervenções de Enfermagem em Uropediatria"
              variant="outlined"
              multiline
              rows={16}
            />
          </section>
          <section>
            <TextField
              className="textarea"
              value={resultados_esperados}
              onChange={(e) => setResultadosEsperados(e.target.value)}
              required
              id="outlined-basic"
              label="Resultados Esperados"
              variant="outlined"
              multiline
              rows={16}
            />
          </section>
          {/* <section>
            <InputMask
              mask="99/99/9999"
              value={data_proxima_consulta}
              onChange={(e) => setDataProximaConsulta(e.target.value)}
            >
              {() => (
                <TextField
                  className="textarea"
                  required
                  label="Data da Próxima Consulta"
                  id="outlined-basic"
                  variant="outlined"
                />
              )}
            </InputMask>
          </section>
          <section>
            <TextField
              type="number"
              className="textarea"
              value={num_acompanhamento}
              onChange={(e) => setNumAcompanhamento(e.target.value)}
              required
              id="outlined-basic"
              label="Número de Acompanhamento"
              variant="outlined"
              helperText="Preencha com 0 se for a Primeira Consulta de Todas, com 1 se for a Primeira Consulta de Acompanhamento (Primeira Consulta Depois da Primeira Consulta) e assim por diante..."
            />
          </section> */}
          <div className="btn-send">
            <Button variant="contained" type="submit">
              {' '}
              Enviar Formulário
            </Button>
          </div>
        </Form>
      </Container>
    </PlataformaPadrao>
  )
}