import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import './styles.css'
import api from '../../services/api'
import { Spinner } from 'react-bootstrap'
import ItemListaPesquisador from '../../components/ItemListaPesquisador'
import PlataformaPadrao from '../../components/plataforma-padrao'
import Table from 'react-bootstrap/Table'

export default function ListaPesquisador() {
  const [pesquisador, setPesquisador] = useState([])
  const [globalPesquisador, setGlobalPesquisador] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    await api
      .get('getNoAdmins')
      .then(({ data }) => {
        setPesquisador(data.pesquisadores)
        setGlobalPesquisador(data.pesquisadoresGlobal)
        setLoading(false)
      })
      .catch(() => {
        alert('Erro ao recuperar informações, tente novamente.')
      })
  }, [])

  return loading ? (
    <Spinner />
  ) : (
    <PlataformaPadrao
      title="Enfermeiras(os)"
    >
      <p id="aceitarcn">Lista de enfermeiras(os) cadastrados</p>
      <div className="lista-geral-table-wrapper">
        <Table >
          <thead>
            <th>
              <div>NOME</div>
            </th>
            <th>
              <div>E-MAIL</div>
            </th>
            <th>
              <div>TELEFONE</div>
            </th>
            <th>
            </th>
            <th>
            </th>
            <th>
            </th>
            <th>
            </th>
          </thead>
          <tbody>
            {!loading &&
              pesquisador.map((_, idx) => {
                return (
                  <ItemListaPesquisador
                    key={idx}
                    excluir={false}
                    editar={false}
                    olhar={true}
                    pesquisador={pesquisador[idx]}
                    globalPesquisador={globalPesquisador[idx]}
                  />
                )
              })}
          </tbody>
        </Table>
      </div>

    </PlataformaPadrao>
  )
}
