import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import AddBoxIcon from '@material-ui/icons/AddBox'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import AssignmentIcon from '@material-ui/icons/Assignment'
import Forum from '@material-ui/icons/Chat'
import DisponibilidadeIcon from '@material-ui/icons/DateRange'
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList'
import HomeIcon from '@material-ui/icons/Home'
import ListRoundedIcon from '@material-ui/icons/ListRounded'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import Acompanharpacientes from '@material-ui/icons/People'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import clsx from 'clsx'
import { React, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../contexts/user'
import api from '../../services/api'
import './styles.css'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F54C4C',
    width: '100%',
    justifyContent: 'flex-start',
    paddingLeft: '5px',
    height: '100px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  arrowDivider: {
    marginTop: 0,
  },
  listButtonWrapper: {
    backgroundColor: '#F66464',
    height: '100%',
  },
  marginButton: {
    marginRight: '5px',
  },
}))

export default function MiniDrawer() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const { user } = useContext(UserContext)
  const tipo = user.tipo
  const [id_consulta, setId_consulta] = useState('')

  useEffect(() => {
    const getData = async () => {
      await api
        .get(`/proxima_consulta/${user.id_global}`)
        .then(({ data }) => {
          setId_consulta(data.id_consulta)
        })
        .catch(console.log)
    }
    getData()
  }, [])

  const handleDrawerClose = () => {
    setOpen(!open)
  }

  if (tipo === 'estudante') {
    return (
      <>
        <CssBaseline />
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton
              className={classes.marginButton}
              onClick={handleDrawerClose}
            >
              <ListRoundedIcon />
            </IconButton>
          </div>
          <List className={classes.listButtonWrapper}>
            {['Novo Post', 'Postagens'].map((text, index) => (
              <ListItem
                button
                key={text}
                onClick={() => {
                  if (text === 'Novo Post') history.push('/cadastro-noticia')
                  else history.push('/lista-postagem')
                }}
              >
                <ListItemIcon>
                  {index % 2 === 0 ? <AddBoxIcon /> : <MenuBookIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </>
    )
  }

  if (tipo === 'pesquisador') {
    return (
      <>
        <CssBaseline />
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton
              className={classes.marginButton}
              onClick={handleDrawerClose}
            >
              <ListRoundedIcon />
            </IconButton>
          </div>
          <List className={classes.listButtonWrapper}>
            <ListItem
              button
              onClick={() => {
                history.push('/principal-pesquisador')
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={'Início'} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push(`/lista-pacientes-especificos/${user.id_global}`)
              }}
            >
              <ListItemIcon>
                <Acompanharpacientes />
              </ListItemIcon>
              <ListItemText primary={'Meus Pacientes'} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push(`/edicao-disponibilidade/${user.id_global}`)
              }}
            >
              <ListItemIcon>
                <AddCircleOutlineIcon />
              </ListItemIcon>
              <ListItemText primary={'Disponibilidade'} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push(`/lista-novo-paciente/${user.id_global}`)
              }}
            >
              <ListItemIcon>
                <DisponibilidadeIcon />
              </ListItemIcon>
              <ListItemText primary={'Minhas Consultas'} />
            </ListItem>
          </List>
        </Drawer>
      </>
    )
  }

  if (tipo === 'admin') {
    return (
      <>
        <CssBaseline />
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton
              className={classes.marginButton}
              onClick={handleDrawerClose}
            >
              <ListRoundedIcon />
            </IconButton>
          </div>
          <List className={classes.listButtonWrapper}>
            <ListItem
              button
              onClick={() => {
                history.push('/principal-adm')
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={'Início'} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push('/lista-autenticar')
              }}
            >
              <ListItemIcon>
                <FeaturedPlayListIcon />
              </ListItemIcon>
              <ListItemText primary={'Novos Cadastros'} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push('/lista-pesquisador')
              }}
            >
              <ListItemIcon>
                <SupervisedUserCircleIcon />
              </ListItemIcon>
              <ListItemText primary={'Enfermeiros'} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push('/lista-estudante')
              }}
            >
              <ListItemIcon>
                <LocalLibraryIcon />
              </ListItemIcon>
              <ListItemText primary={'Estudantes'} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push('/lista-postagem')
              }}
            >
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary={'Portal'} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push(`/lista-pacientes-especificos/${user.id_global}`)
              }}
            >
              <ListItemIcon>
                <Acompanharpacientes />
              </ListItemIcon>
              <ListItemText primary={'Meus Pacientes'} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push(`/edicao-disponibilidade/${user.id_global}`)
              }}
            >
              <ListItemIcon>
                <AddCircleOutlineIcon />
              </ListItemIcon>
              <ListItemText primary={'Disponibilidade'} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push(`/lista-novo-paciente/${user.id_global}`)
              }}
            >
              <ListItemIcon>
                <DisponibilidadeIcon />
              </ListItemIcon>
              <ListItemText primary={'Minhas Consultas'} />
            </ListItem>
          </List>
        </Drawer>
      </>
    )
  }

  if (tipo === 'paciente') {
    return (
      <>
        <CssBaseline />
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton
              className={classes.marginButton}
              onClick={handleDrawerClose}
            >
              <ListRoundedIcon />
            </IconButton>
          </div>
          <List className={classes.listButtonWrapper}>
            <ListItem
              button
              onClick={() => {
                history.push('/principal-familia')
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={'Início'} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push(`/instrumentos-preencher/${id_consulta}`)
              }}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary={'Instrumentos'} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push(`/forum/${user.id_global}`)
              }}
            >
              <ListItemIcon>
                <Forum />
              </ListItemIcon>
              <ListItemText primary={'Fórum'} />
            </ListItem>
          </List>
        </Drawer>
      </>
    )
  }
}
