import { createContext, useState, useEffect } from 'react'

export const UserContext = createContext(null)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() =>
    JSON.parse(localStorage.getItem('@uropred:user'))
  )

  function login(userData) {
    setUser(userData)
    localStorage.setItem(
      '@uropred:user',
      JSON.stringify({
        id_global: userData.id_global,
        nome: userData.nome,
        autenticado: userData.autenticado_pelo_admin,
        tipo: userData.tipo,
        email: userData.email,
      })
    )
  }

  function logout() {
    setUser(null)
    localStorage.removeItem('@uropred:user')
  }

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  )
}
