import './styles.css'

import { Form, Spinner } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import React, { useState } from 'react'

import CadastroPadrao from '../../components/CadastroPadrao'
import InputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'
import api from '../../services/api'
import { makeStyles } from '@material-ui/core/styles'
import politica_privacidade from '../../assets/politica_privacidade_enfuroped.pdf'
import termos_de_uso from '../../assets/termos_de_uso_enfuroped.pdf'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    justifyContent: 'center',
    color: '#000000',
  },
}))

export default function CadastroEstudante() {
  const classes = useStyles()
  const history = useHistory()

  //Cadastro global
  const [nome, setNome] = useState('')
  const [data_nascimento, setDataNascimento] = useState('')
  const [email, setEmail] = useState('')
  const [cpf, setCpf] = useState('')
  const [senha, setSenha] = useState('')
  const [senhaConfirmada, setSenhaConfirmada] = useState('')
  const [telefone1, setTelefone1] = useState('')
  const [telefone2, setTelefone2] = useState('')
  const [sexo, setSexo] = useState('Feminino') //começa com Feminino pois é a primeira opção do select no front
  const [tipo, setTipo] = useState('estudante')
  const [autenticado_pelo_admin, setAutenticado_pelo_admin] = useState(false)
  const [loading, setLoading] = useState(false)

  //Cadastro Estudante
  const [rg, setRg] = useState('')
  const [instituicao, setInstituicao] = useState('')
  const [matricula, setMatricula] = useState('')
  const [semestre, setSemestre] = useState('')
  const [acesso_cadastros, setAcessocadastros] = useState(false)
  const [acesso_blog, setAcessoblog] = useState(false)

  function isEmailAndCpfAlreadyBeingUsed(data) {
    for (const iter of data) {
      if (iter.email == email) {
        return true
      }
      if (iter.cpf == cpf) {
        return true
      }
    }

    return false
  }

  async function handleValidation() {
    const global = await api
      .get('global')
      .then(({ data }) => data)
      .catch(console.log)

    let formIsValid = true

    if (telefone1.includes('_') || telefone1.length === 0) {
      formIsValid = false
    }

    if (data_nascimento.includes('_') || data_nascimento.length === 0) {
      formIsValid = false
    }

    if (cpf.includes('_') || cpf.length === 0) {
      formIsValid = false
    }

    if (rg.includes('_') || rg.length === 0) {
      formIsValid = false
    }

    if (isEmailAndCpfAlreadyBeingUsed(global)) {
      alert('Esse email ou cpf já estão cadastrados')
      formIsValid = false
    }

    return formIsValid
  }

  async function handleCadastro(e) {
    e.preventDefault()

    const formIsValid = await handleValidation()

    if (!formIsValid) {
      alert('Preencha todos os campos necessários antes de continuar.')
      return
    }

    if (senha !== senhaConfirmada) {
      alert('As Senhas Digitadas Não Batem. Por Favor, Tente Novamente.')
      return
    }

    setLoading(true)

    const response_global = await api
      .post('global', {
        nome,
        data_nascimento,
        email,
        cpf,
        senha,
        sexo,
        telefone1,
        telefone2,
        tipo,
        autenticado_pelo_admin,
      })
      .then(({ data }) => data)
      .catch(console.log)

    api
      .post('alunos_graduacao', {
        id_global: response_global.id,
        nome,
        data_nascimento,
        cpf,
        rg,
        instituicao,
        matricula,
        semestre,
        sexo,
        acesso_cadastros,
        acesso_blog,
      })
      .catch(console.log)

    api
      .post('/validar-email/', { email })
      .then(handleSendMail)
      .catch(console.log)

    alert(
      'Cadastrado com sucesso, aguarde autenticação... Você será avisado por e-mail.'
    )

    history.replace('/')
  }

  function handleSendMail() {
    api
      .get('global/1')
      .then((response) => {
        const admin_email = response.data.email
        const admin_nome = response.data.nome

        const dados_email = {
          nome_destinatario: admin_nome,
          endereco_email: admin_email,
          assunto_do_email: 'Um novo estudante se cadastrou',
          texto_do_email: `O estudante "${nome}" acabou de se cadastrar e precisa ser autenticado.`,
        }

        api.post('send_mail', dados_email).catch(console.log)
      })
      .catch(console.log)
  }

  return (
    <CadastroPadrao
      title='CADASTRO ESTUDANTE'
      onSubmit={handleCadastro}
      className={classes.root}
    >
      <div className='entrada'>
        <TextField
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          className={classes.input}
          required
          label='Nome Completo'
          variant='outlined'
        />
      </div>
      <div className='entrada'>
        <InputMask
          mask='(99)99999-9999'
          value={telefone1}
          onChange={(e) => setTelefone1(e.target.value)}
        >
          {() => (
            <TextField
              className={classes.input}
              required
              label='Telefone para contato 1'
              variant='outlined'
            />
          )}
        </InputMask>
      </div>
      <div className='entrada'>
        <InputMask
          mask='(99)99999-9999'
          value={telefone2}
          onChange={(e) => setTelefone2(e.target.value)}
        >
          {() => (
            <TextField
              className={classes.input}
              label='Telefone para contato 2 (Opcional)'
              variant='outlined'
            />
          )}
        </InputMask>
      </div>
      <div className='entrada'>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={classes.input}
          label='E-mail'
          variant='outlined'
          type='email'
          required
        />
      </div>
      <div className='entrada'>
        <InputMask
          mask='99/99/9999'
          value={data_nascimento}
          onChange={(e) => setDataNascimento(e.target.value)}
        >
          {() => (
            <TextField
              className={classes.input}
              required
              label='Data de Nascimento'
              variant='outlined'
            />
          )}
        </InputMask>
      </div>
      <div className='entrada'>
        <Form.Group>
          <Form.Label className='form-select-label'>Sexo</Form.Label>
          <Form.Control as='select' onChange={(e) => setSexo(e.target.value)}>
            <option value='Feminino'>Feminino</option>
            <option value='Masculino'>Masculino</option>
            <option value='Outro'>Outro</option>
          </Form.Control>
        </Form.Group>
      </div>
      <div className='entrada'>
        <TextField
          value={instituicao}
          onChange={(e) => setInstituicao(e.target.value)}
          className={classes.input}
          required
          label='Instituição onde Estuda'
          variant='outlined'
        />
      </div>
      <div className='entrada'>
        <TextField
          value={matricula}
          onChange={(e) => setMatricula(e.target.value)}
          className={classes.input}
          required
          label='Número de Matrícula'
          variant='outlined'
        />
      </div>
      <div className='entrada'>
        <TextField
          type='number'
          value={semestre}
          onChange={(e) => setSemestre(e.target.value)}
          className={classes.input}
          required
          label='Semestre Atual'
          variant='outlined'
        />
      </div>
      <div className='entrada'>
        <InputMask
          mask='999.999.999-99'
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
        >
          {() => (
            <TextField
              className={classes.input}
              required
              label='CPF'
              variant='outlined'
            />
          )}
        </InputMask>
      </div>
      <div className='entrada'>
        <InputMask
          mask='9.999.999'
          value={rg}
          onChange={(e) => setRg(e.target.value)}
        >
          {() => (
            <TextField
              className={classes.input}
              required
              label='RG'
              variant='outlined'
            />
          )}
        </InputMask>
      </div>
      <div className='entrada'>
        <TextField
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
          className={classes.input}
          id='outlined-password-input'
          label='Senha para Login na Plataforma'
          type='password'
          variant='outlined'
          required
        />
      </div>
      <div className='entrada'>
        <TextField
          value={senhaConfirmada}
          onChange={(e) => setSenhaConfirmada(e.target.value)}
          className={classes.input}
          id='outlined-password-input'
          label='Confirme a Senha'
          type='password'
          variant='outlined'
          required
        />
      </div>
      <div className='entrada termo_politica'>
        <p>
          → Ao se cadastrar, você confirma que leu e aceita os{' '}
          <Link target='_blank' to={termos_de_uso}>
            Termos de Uso
          </Link>{' '}
          e a{' '}
          <Link target='_blank' to={politica_privacidade}>
            Política de Privacidade
          </Link>
          .
        </p>
      </div>
      {loading && <Spinner animation='border' />}
    </CadastroPadrao>
  )
}
