import React from 'react'
import { Button, Card, Carousel, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Img_3 from '../../assets/carousel_3.png'
import Img_2 from '../../assets/carousel_2.png'
import Img_1 from '../../assets/carousel_4.png'
import Img_4 from '../../assets/carousel_1.png'
import CardEquipe from '../../components/CardEquipe'
import CardNoticia from '../../components/CardNoticia'
import Footer from '../../components/Footer'
import NavBar from '../../components/Navbar'
import './styles.css'

//////////////////////
const imagem_equipe_teste = 'https://picsum.photos/id/5/700/700'
const nome_teste = 'Diego Martins'
const link_curriculo_teste = ''
const imagem_noticia_teste = 'https://picsum.photos/id/237/200/300'
const titulo_teste = 'Título da notícia'
const resumo_noticia_teste =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam'
const link_noticia_teste = ''
//////////////////////

export default function Home() {
  return (
    <>
      <div>
        <NavBar />
        <Carousel className="carrossel">
          <Carousel.Item>
            <img className="photos" src={Img_1} alt="First slide" id="familia"/>
            <Carousel.Caption>
              <div className="texto">
                <p>Cuidado customizado e conectado</p>
                <p>
                  Nosso cuidado virtual de enfermagem especializado está baseado
                  no fortalecimento de ações de promoção para o autocuidado
                  apoiado de crianças e adolescentes com sintomas urinários e
                  intestinais
                </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="photos" src={Img_2} alt="Second slide" id="pesquisadores" />
            <Carousel.Caption>
              <div className="texto">
                <p>
                  Educação e capacitação à distancia para cuidado de crianças e
                  adolescentes com sintomas urinários e intestinais
                </p>
                <p>
                  Direcionada a profissionais e estudantes da área da saúde,
                  utilizando estratégias de educação à distância, para prover
                  uma assistência qualificada a pacientes com sintomas urinários
                  e intestinais e suas famílias{' '}
                </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="photos" src={Img_3} alt="Third slide" />
            <Carousel.Caption>
              <div className="texto">
                <p>
                  Produção, aplicação e disseminação de evidências no cuidado em
                  Uropediatria​
                </p>
                <p>
                  Busca contínua na geração de novos conhecimentos para apoiar
                  uma assistência de excelência a crianças e adolescentes com
                  sintomas urinários e intestinais​
                </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
{/*
      <div className="botao-login">
        <div className="margem"></div>
        <div>
          <Link to="/login">
            <Button variant="outline-dark" active>
              Clique aqui para entrar na plataforma
            </Button>{' '}
          </Link>
        </div>
        <div className="margem"></div>
      </div>
*/}
      <div className="apresentacao">
      <div className="margem"></div>
        <div className="caixa">
          <nav class="nav_tabs">
            <ul>
                            <li>
                              <input type="radio" id="tab1" class="rd_tab" name="tabs"/>
                              <label for="tab1" class="tab_label">Programa</label>
                              <div class="tab-content">
                                <h2>Sobre o Programa</h2>
                                <article>
                                  <img className="photos" src={Img_1} alt="First slide"/>
                                  <br/>
                                  <p>Trata-se de um programa de pesquisa, ensino e extensão, vinculado ao 
                                Departamento de Enfermagem da Universidade de Brasília (ENF/UnB) que 
                                visa promover excelência e inovação na avaliação, diagnóstico e manejo
                                 de sintomas urinários e intestinais durante a infância e adolescência na 
                                 era da Saúde Digital.</p>
                                  <p>
                                Nesse sentido, o programa desenvolve atividades conjuntas de pesquisa, 
                                ensino e extensão, inclusive integrando a comunidade externa à Universidade.   
                                </p>
                                </article>
                              </div>
                            </li>
                            <li>
                              <input type="radio" name="tabs" class="rd_tab" id="tab2" />
                              <label for="tab2" class="tab_label">Pesquisa</label>
                              <div class="tab-content">
                                <h2>Pesquisa</h2>
                                <article>
                                <img className="photos" src={Img_2} alt="Second slide" id="pesquisadores" />
                                <br/>
                                As pesquisas são baseadas na busca contínua por geração, aplicação e 
                                disseminação de evidências para um cuidado de excelência a crianças e 
                                adolescentes com sintomas urinários e/ou intestinais.
                                  </article>
                              </div>
                            </li>
                            <li>
                              <input type="radio" name="tabs" class="rd_tab" id="tab3" />
                              <label for="tab3" class="tab_label">Ensino</label>
                              <div class="tab-content">
                                <h2>Ensino</h2>
                                <article>
                                <img className="photos" src={Img_4} alt="Second slide" id="pesquisadores" />
                                <br/>
                                As práticas de ensino estão voltadas para a capacitação orientada ao 
                                cuidado virtual em Uropediatria, direcionadas a profissionais e estudantes
                                 da área da saúde, visando uma assistência empática, qualificada e 
                                 fundamentada na promoção e fortalecimento de ações de autocuidado 
                                 apoiado. Envolvem a realização de disciplinas, orientações, estágio,
                                  mentoria e eventos científicos, visando contribuir na formação da 
                                  próxima geração de enfermeir@s-cientistas a serem formad@s pela 
                                  Universidade de Brasília. 
                               </article>
                              </div>
                            </li>
                            <li>
                              <input type="radio" name="tabs" class="rd_tab" id="tab4" />
                              <label for="tab4" class="tab_label">Extensão</label>
                              <div class="tab-content">
                                <h2>Extensão</h2>
                                <article id="justify4">
                                <img className="photos" src={Img_3} alt="Second slide" id="pesquisadores" />
                                <br/>
                                <b>Desenvolvida por meio de 2 ações de extensão:</b>
                                <ul>
                                    <li>- Portal de informações em saúde urológica pediátrica para 
                                    crianças, adolescentes, famílias e profissionais;</li>
                                    <li>- Clínica-escola virtual para autocuidado apoiado em Uropediatria.</li>
                                </ul>
                              </article>
                              </div>
                            </li>
                          </ul>
                        </nav>
        </div>
        <div className="margem"></div>
      </div>

      <div className="follow-up" id="verde">
        <Card>
          <Card.Title>Nossa História</Card.Title>
          <Card.Body>
            <p>Esse programa de pesquisa, ensino e extensão foi criado pela Profa. Dra. Gisele Martins, que é uma das
            únicas enfermeiras-pesquisadoras da área de Urologia Pediátrica do Brasil e também uma das poucas do
            mundo, com titulação de Doutorado (PhD) na área.</p>

            <p>A Profa. Gisele nasceu com algumas malformações congênitas (inclusive no trato urinário), que foram
            corrigidas cirurgicamente ao longo de sua infância e adolescência. Em virtude dessa experiência de vida,
            a professora decidiu dedicar a sua carreira de enfermeira, docente e cientista na área de Enfermagem
            em Urologia Pediátrica. No Brasil, ainda não se tem reconhecido o papel de Enfermeira(o) de Prática
            Avançada. Portanto, a Profa. Gisele desenvolveu sua expertise em Uropediatria tanto clínica quanto
            científica, por meio de treinamentos e capacitações sistemáticas no país e no exterior (principalmente,
            no Canadá e Estados Unidos). Assim, nasceu a Prática Avançada de Enfermagem em Uropediatria!</p>
          </Card.Body>
        </Card>
      </div>

      <div className="follow-up" id="azul">
        <Card>
          <Card.Title>Visão</Card.Title>
          <Card.Body>
            Disseminar e desenvolver ciência para promover experiências virtuais customizadas de
            aprendizagem e de cuidado na área de Uropediatria através de parceria colaborativa, autêntica e
            baseada nas potencialidades das crianças, adolescentes, famílias, estudantes e profissionais
          </Card.Body>
        </Card>
      </div>

      <div className="follow-up" id= "outroazul">
        <Card>
          <Card.Title>Missão</Card.Title>
          <Card.Body>
          <p>1. Facilitar o acesso e a experiência virtual de busca de informações em saúde de qualidade na área
          de Uropediatria para crianças, adolescentes, famílias e profissionais;</p>

          <p>2. Promover experiências virtuais de ensino-aprendizagem significativas e inovadoras, visando um
          cuidado empático e baseado nas forças do educando;</p>

          <p>3. Desenvolver experiências virtuais de cuidado customizadas para as necessidades de
          crianças/adolescentes com sintomas urinários e/ou intestinais, bem como seus pais e familiares
          valorizando a parceria colaborativa entre crianças, adolescentes, famílias e profissionais.</p>
          </Card.Body>
        </Card>
      </div>

      {/* <div className="follow-up">
        <Card>
          <div className="noticia-container">
            <Container>
              <Row>
                <Col md={12} className="d-flex justify-content-center">
                  <h1>Notícias</h1>
                </Col>
              </Row>
              <Row className="cards-container">
                <Col sm={12} md={6} className="d-flex justify-content-center">
                  <CardNoticia
                    imagem={imagem_noticia_teste}
                    titulo={titulo_teste}
                    resumo={resumo_noticia_teste}
                    link={link_noticia_teste}
                  />
                </Col>
                <Col sm={12} md={6} className="d-flex justify-content-center">
                  <CardNoticia
                    imagem={imagem_noticia_teste}
                    titulo={titulo_teste}
                    resumo={resumo_noticia_teste}
                    link={link_noticia_teste}
                  />
                </Col>
              </Row>
              <Row className="cards-container">
                <Col sm={12} md={6} className="d-flex justify-content-center">
                  <CardNoticia
                    imagem={imagem_noticia_teste}
                    titulo={titulo_teste}
                    resumo={resumo_noticia_teste}
                    link={link_noticia_teste}
                  />
                </Col>
                <Col sm={12} md={6} className="d-flex justify-content-center">
                  <CardNoticia
                    imagem={imagem_noticia_teste}
                    titulo={titulo_teste}
                    resumo={resumo_noticia_teste}
                    link={link_noticia_teste}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Card>
      </div>

      <div className="follow-up">
        <Card>
          <div className="equipe-container">
            <Container>
              <Row>
                <Col md={12} className="d-flex justify-content-center">
                  <h1>Equipe</h1>
                </Col>
              </Row>
              <Row className="cards-container">
                <Col
                  sm={12}
                  md={6}
                  lg={3}
                  className="d-flex justify-content-center"
                >
                  <CardEquipe
                    imagem={imagem_equipe_teste}
                    nome={nome_teste}
                    link={link_curriculo_teste}
                  />
                </Col>
                <Col
                  sm={12}
                  md={6}
                  lg={3}
                  className="d-flex justify-content-center"
                >
                  <CardEquipe
                    imagem={imagem_equipe_teste}
                    nome={nome_teste}
                    link={link_curriculo_teste}
                  />
                </Col>
                <Col
                  sm={12}
                  md={6}
                  lg={3}
                  className="d-flex justify-content-center"
                >
                  <CardEquipe
                    imagem={imagem_equipe_teste}
                    nome={nome_teste}
                    link={link_curriculo_teste}
                  />
                </Col>
                <Col
                  sm={12}
                  md={6}
                  lg={3}
                  className="d-flex justify-content-center"
                >
                  <CardEquipe
                    imagem={imagem_equipe_teste}
                    nome={nome_teste}
                    link={link_curriculo_teste}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Card>
      </div> */}
      <Footer />
    </>
  )
}
