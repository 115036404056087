import './styles.css'

import { Container, Spinner } from 'react-bootstrap'
import { MDBCol, MDBRow } from 'mdbreact'
import React, { useEffect, useState } from 'react'

import FooterBlog from '../../components/FooterBlog'
import HeaderBlog from '../../components/HeaderBlog'
import NavBarBlog from '../../components/NavbarBlog'
import Noticiaprev from '../../components/Noticiaprev'
import TagsBlog from '../../components/TagsBlog'
import api from '../../services/api'
import { useParams } from 'react-router'

export default function Blog() {
  const [loading, setLoading] = useState(true)
  const [noticias, setNoticias] = useState([])
  let { tag } = useParams()

  useEffect(async () => {
    await api.get('noticias').then((response) => {
      setNoticias(filter(response.data))
      setLoading(false)
    })
  }, [tag])

  function filter(input) {
    if (!tag) {
      return input
    }
    let arrayFinal = []

    for (let i of input) {
      let tagsLidas = i.tags.split(',')
      tagsLidas = tagsLidas.map((tagAtual) => tagAtual.trim().toLowerCase())
      for (let j of tagsLidas) {
        if (j.indexOf(tag) != -1) {
          arrayFinal.push(i)
          break
        }
      }
    }
    return arrayFinal
  }

  function reverseArr(input) {
    var ret = new Array()
    for (var i = input.length - 1; i >= 0; i--) {
      ret.push(input[i])
    }
    return ret
  }

  if (loading) {
    return (
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    )
  }

  //só retorna a página em si quando o loading acaba (quando os dados são carregados)
  return (
    <>
      <NavBarBlog />
      <HeaderBlog />

      {/* <body> */}
      <Container>
        <MDBRow>
          <MDBCol sm='10' className='coluna-noticias'>
            {' '}
            {/* mostra as notícias em ordem da mais recente pra mais antiga, por isso inverte o array*/}
            {reverseArr(noticias).map((noticia) => (
              <MDBRow key={noticia.id_noticia}>
                <Noticiaprev
                  id={noticia.id_noticia}
                  diadata={noticia.data}
                  noticiaTitulo={noticia.titulo}
                  noticiaImagem={noticia.imagem}
                  noticiaResumo={noticia.resumo}
                  noticiaAutor={noticia.autores}
                  noticiaTags0={noticia.tags}
                />
              </MDBRow>
            ))}
          </MDBCol>
          <TagsBlog />
        </MDBRow>
      </Container>
      {/* <body /> */}

      <FooterBlog />
    </>
  )
}
