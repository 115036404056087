import React from 'react'
import { Image, Row, Col } from 'react-bootstrap'
import './styles.css'

export default function CardInstrumentoAPreencher({ onClick, image, title }) {
  return (
    <div
      onClick={() => {
        onClick()
      }}
      className="card-instrumentos-preencher"
    >
      <Row className="linha-img-titulo">
        <Col lg className="img">
          <Image className="w-100" src={image} />
        </Col>
        <Col lg className="titulo">
          <h1>{title}</h1>
        </Col>
      </Row>
    </div>
  )
}
