import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter,
} from 'react-router-dom'
import { React, useContext, useLayoutEffect } from 'react'
import { UserContext, UserProvider } from './contexts/user'

import Blog from './pages/Blog'
import CadastroEstudante from './pages/CadastroEstudante'
import CadastroEstudantePreenchido from './pages/CadastroEstudantePreenchido'
import CadastroFamilia from './pages/CadastroFamilia'
import CadastroFamiliaPreenchido from './pages/CadastroFamiliaPreenchido'
import CadastroNoticia from './pages/CadastroNoticia'
import CadastroPesquisador from './pages/CadastroPesquisador'
import CadastroPesquisadorPreenchido from './pages/CadastroPesquisadorPreenchido'
//import DiarioEliminacoes from './pages/DiarioEliminacoes'
import DiarioEliminacoes from './pages/DiarioEliminacoes'
import DisponibilidadeEdit from './pages/DisponibilidadeEdit'
import DisponibilidadePesquisador from './pages/DisponibilidadeSemestral'
import Dvss from './pages/DVSS'
import Equipe from './pages/equipe'
import FormularioSAE from './pages/FormularioSAE'
import FormularioSAEPreenchido from './pages/FormularioSAEPreenchido'
import Forum from './pages/Forum'
import Home from './pages/Home'
import HomeLegacy from './pages/HomeLegacy' //não precisa colocar o /index porque pelo nome do arquivo ser index, ele fica subtendido
import InstrumentosAPreencher from './pages/InstrumentosAPreencher'
import ListaAdministrador from './pages/ListaAdministrador'
import ListaAutenticar from './pages/ListaAutenticar'
import ListaEstudante from './pages/ListaEstudante'
import ListaFamilia from './pages/ListaFamilia'
import ListaGeral from './pages/Lista_geral'
import ListaNovoPaciente from './pages/ListaNovoPaciente'
import ListaPacientesAlta from './pages/ListaPacientesAlta'
import ListaPacientesEspecificos from './pages/ListaPacientesEspecificos'
import ListaPesquisador from './pages/ListaPesquisador'
import ListaPostagem from './pages/ListaPostagem'
import Login from './pages/login'
import MarcarPrimeiraConsulta from './pages/MarcarPrimeiraConsulta'
import MarcarProximaConsulta from './pages/MarcarProximaConsulta'
import MensagemForum from './pages/MensagemForum'
import NoticiasSite from './pages/noticias'
import PaginaCadaPaciente from './pages/PaginaCadaPaciente'
import PaginaPrincipalFamilia from './pages/PaginaPrincipalFamilia'
import PaginaPrincipalPesquisador from './pages/PaginaPrincipalPesquisador'
import PastaConsulta from './pages/PastaConsulta'
import Postagem from './pages/Postagem'
import PrincipalAdm from './pages/PrincipalAdm'
import RedefinicaoSenha from './pages/RedefinicaoSenha'
import RomaIV from './pages/RomaIV'
import SemPermissao from './pages/SemPermissao'
import SolChuva from './pages/SolChuva'
import WrapperPage from './pages/WrapperPage'

const PesquisadorPrivateRoute = ({ children, ...rest }) => {
  const { user } = useContext(UserContext)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user?.tipo === 'pesquisador' || user?.tipo === 'admin' ? (
          children
        ) : (
          <Redirect
            to={{ pathname: '/sem-permissao', state: { from: location } }}
          />
        )
      }
    />
  )
}

const PacientePrivateRoute = ({ children, ...rest }) => {
  const { user } = useContext(UserContext)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user?.tipo === 'paciente' || user?.tipo === 'admin' ? (
          children
        ) : (
          <Redirect
            to={{ pathname: '/sem-permissao', state: { from: location } }}
          />
        )
      }
    />
  )
}

const EstudantePrivateRoute = ({ children, ...rest }) => {
  const { user } = useContext(UserContext)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user?.tipo === 'estudante' || user?.tipo === 'admin' ? (
          children
        ) : (
          <Redirect
            to={{ pathname: '/sem-permissao', state: { from: location } }}
          />
        )
      }
    />
  )
}

// rota privada do admin que ta aceitando pesquisadores temporariamente, o certo eh nao aceitar
const AdminPrivateRoute = ({ children, ...rest }) => {
  const { user } = useContext(UserContext)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user?.tipo === 'admin' ? (
          children
        ) : (
          <Redirect
            to={{ pathname: '/sem-permissao', state: { from: location } }}
          />
        )
      }
    />
  )
}

const PacientePesquisadorPrivateRoute = ({ children, ...rest }) => {
  const { user } = useContext(UserContext)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user?.tipo === 'paciente' ||
        user?.tipo === 'pesquisador' ||
        user?.tipo === 'admin' ? (
          children
        ) : (
          <Redirect
            to={{ pathname: '/sem-permissao', state: { from: location } }}
          />
        )
      }
    />
  )
}

const _ScrollToTop = ({ children }) => {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children
}

const ScrollToTop = withRouter(_ScrollToTop)

export default function Routes() {
  return (
    <BrowserRouter>
      <UserProvider>
        <Switch>
          <ScrollToTop>
            {/*o exact é usado para a pagina inicial da plataforma; ele é necessário para assim a pagina pagename só aparecer quando o path for exatamente igual a "/"; dessa forma os outros paths tbm funcionarão normalmente*/}
            <Route path='/' exact component={Home} />
            <Route path='/home-legacy' component={HomeLegacy} />
            <Route path='/sem-permissao' component={SemPermissao} />
            <Route path='/login' component={Login} />
            <Route path='/cadastro-estudante' component={CadastroEstudante} />
            <Route path='/cadastro-familia' component={CadastroFamilia} />
            <Route
              path='/cadastro-pesquisador'
              component={CadastroPesquisador}
            />
            <Route path='/equipe'>
              <Equipe />
            </Route>
            <Route path='/noticias'>
              <NoticiasSite />
            </Route>
            {/* é necessário criar essas rotas sendo que a equipe e a prévia das notícias estão dentro da home?*/}
            <Route path='/blog' exact>
              <Blog />
            </Route>
            <Route path='/blog/:tag'>
              <Blog />
            </Route>
            <Route path='/postagem/:id'>
              <Postagem />
            </Route>
            <Route path='/token/:token'>
              <WrapperPage />
            </Route>
            <Route path='/redefinir-senha'>
              <RedefinicaoSenha />
            </Route>
            <PacientePrivateRoute path='/sol-chuva/:id'>
              <SolChuva />
            </PacientePrivateRoute>
            <PacientePrivateRoute path='/dvss/:id'>
              <Dvss />
            </PacientePrivateRoute>
            <PacientePrivateRoute path='/diario-eliminacoes/:id'>
              <DiarioEliminacoes />
            </PacientePrivateRoute>
            <PacientePrivateRoute path='/romaiv/:id'>
              <RomaIV />
            </PacientePrivateRoute>
            <PacientePesquisadorPrivateRoute path='/sol-chuva-preenchido/:id'>
              <SolChuva />
            </PacientePesquisadorPrivateRoute>
            <PacientePesquisadorPrivateRoute path='/dvss-preenchido/:id'>
              <Dvss />
            </PacientePesquisadorPrivateRoute>
            <PacientePesquisadorPrivateRoute path='/diario-eliminacoes-preenchido/:id'>
              <DiarioEliminacoes />
            </PacientePesquisadorPrivateRoute>
            <PacientePesquisadorPrivateRoute path='/diario-eliminacoes-v2-preenchido/:id'>
              <DiarioEliminacoes />
            </PacientePesquisadorPrivateRoute>
            <PacientePesquisadorPrivateRoute path='/romaiv-preenchido/:id'>
              <RomaIV />
            </PacientePesquisadorPrivateRoute>
            <EstudantePrivateRoute path='/cadastro-noticia'>
              <CadastroNoticia />
            </EstudantePrivateRoute>
            <PesquisadorPrivateRoute path='/sae/:id_sae'>
              <FormularioSAE />
            </PesquisadorPrivateRoute>
            <PesquisadorPrivateRoute path='/sae-preenchido/:id'>
              <FormularioSAEPreenchido />
            </PesquisadorPrivateRoute>
            <PesquisadorPrivateRoute path='/edicao-disponibilidade/:id'>
              <DisponibilidadeEdit />
            </PesquisadorPrivateRoute>
            <Route path='/cadastro-estudante-preenchido'>
              <CadastroEstudantePreenchido />
            </Route>
            <Route path='/cadastro-familia-preenchido'>
              <CadastroFamiliaPreenchido />
            </Route>
            <Route path='/cadastro-pesquisador-preenchido'>
              <CadastroPesquisadorPreenchido />
            </Route>
            <PacientePesquisadorPrivateRoute path='/mensagem-forum'>
              <MensagemForum />
            </PacientePesquisadorPrivateRoute>
            <PacientePrivateRoute path='/instrumentos-preencher/:id_consulta'>
              <InstrumentosAPreencher />
            </PacientePrivateRoute>
            <AdminPrivateRoute path='/principal-adm'>
              <PrincipalAdm />
            </AdminPrivateRoute>
            <Route path='/lista-geral'>
              <ListaGeral />
            </Route>
            <AdminPrivateRoute path='/lista-estudante'>
              <ListaEstudante />
            </AdminPrivateRoute>
            <AdminPrivateRoute path='/lista-familia'>
              <ListaFamilia />
            </AdminPrivateRoute>
            <AdminPrivateRoute path='/lista-pesquisador'>
              <ListaPesquisador />
            </AdminPrivateRoute>
            <AdminPrivateRoute path='/lista-administrador'>
              <ListaAdministrador />
            </AdminPrivateRoute>
            <AdminPrivateRoute path='/lista-autenticar'>
              <ListaAutenticar />
            </AdminPrivateRoute>
            <AdminPrivateRoute path='/lista-pacientes-alta'>
              <ListaPacientesAlta />
            </AdminPrivateRoute>
            <EstudantePrivateRoute path='/lista-postagem'>
              <ListaPostagem />
            </EstudantePrivateRoute>
            <PesquisadorPrivateRoute path='/lista-novo-paciente/:id'>
              <ListaNovoPaciente />
            </PesquisadorPrivateRoute>
            <PesquisadorPrivateRoute path='/lista-pacientes-especificos/:id'>
              <ListaPacientesEspecificos />
            </PesquisadorPrivateRoute>
            <PacientePesquisadorPrivateRoute path='/forum/:id'>
              <Forum />
            </PacientePesquisadorPrivateRoute>
            <PacientePrivateRoute path='/principal-familia'>
              <PaginaPrincipalFamilia />
            </PacientePrivateRoute>
            <PesquisadorPrivateRoute path='/principal-pesquisador'>
              <PaginaPrincipalPesquisador />
            </PesquisadorPrivateRoute>
            <PesquisadorPrivateRoute path='/cada-paciente/:id'>
              <PaginaCadaPaciente />
            </PesquisadorPrivateRoute>
            <PesquisadorPrivateRoute path='/disponibilidade-semestral/:id_pesquisador'>
              <DisponibilidadePesquisador />
            </PesquisadorPrivateRoute>
            <PacientePrivateRoute path='/marcar-primeira-consulta'>
              <MarcarPrimeiraConsulta />
            </PacientePrivateRoute>
            <PesquisadorPrivateRoute path='/marcar-proxima-consulta/:id_paciente'>
              <MarcarProximaConsulta />
            </PesquisadorPrivateRoute>
            <PesquisadorPrivateRoute path='/pasta-consulta/:id_consulta'>
              <PastaConsulta />
            </PesquisadorPrivateRoute>
          </ScrollToTop>
        </Switch>
      </UserProvider>
    </BrowserRouter>
  )
}
