import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Modal, Button } from 'react-bootstrap'
import './styles.css'
import PlataformaPadrao from '../../components/plataforma-padrao'
import CardInstrumentoAPreencher from '../../components/CardInstrumentosAPreencher'
import Bexiga from '../../assets/bexiga.png'
import Coco from '../../assets/coco.png'
//import Banheiro from '../../assets/banheiro.png'
import diario from '../../assets/diario.png'
import SolChuva from '../../assets/solChuva.png'
import { useParams } from 'react-router'
import { UserContext } from '../../contexts/user.js'
import api from '../../services/api'
import { set, sub, startOfWeek, lastDayOfWeek } from 'date-fns'
import { Link, useHistory } from 'react-router-dom'

export default function InstrumentosAPreencher() {
  //variáveis só a fins de teste, na aplicação real a gnt teria que ver como o paciente vai saber se deve ou n preencher um instrumento de fato
  const { user } = useContext(UserContext)
  let id = user.id_global
  const history = useHistory()
  let { id_consulta } = useParams()
  const [consulta, setConsulta] = useState({})
  const [data_consulta_menos_um_mes, setData_consulta_menos_um_mes] = useState(
    new Date()
  )
  const [
    instrumentos_dessa_consulta,
    setInstrumentos_dessa_consulta,
  ] = useState({})
  const [showModalSolChuva, setShowModalSolChuva] = useState(false)
  const [showModalDvss, setShowModalDvss] = useState(false)
  const [showModalRomaIV, setShowModalRomaIV] = useState(false)
  const [showModalDiarioEliminacao, setShowModalDiarioEliminacao] = useState(
    false
  )
  const [dvssConsultaEspecifica, setDvssConsultaEspecifica] = useState([])
  const [solChuvaConsultaEspecifica, setSolChuvaConsultaEspecifica] = useState(
    []
  )
  const [romaIVConsultaEspecifica, setRomaIVConsultaEspecifica] = useState([])
  const [
    diarioEliminacaoConsultaEspecifica,
    setDiarioEliminacaoConsultaEspecifica,
  ] = useState([])

  useEffect(() => {
    async function getConsulta() {
      await api.get(`consulta_especifica/${id_consulta}`).then((response) => {
        setConsulta(response.data)
        setInstrumentos_dessa_consulta(response.data.instrumentos.split(','))
        setData_consulta_menos_um_mes(
          startOfWeek(sub(new Date(response.data.hora_inicio), { months: 1 }))
        )
      })
    }
    async function getInstrumentos() {
      Promise.all([
        await api.get(`dvss_da_consulta_especifica/${id_consulta}`),
        await api.get(`sol_chuva_da_consulta_especifica/${id_consulta}`),
        await api.get(`roma_iv_da_consulta_especifica/${id_consulta}`),
        await api.get(
          `diario_eliminacao_da_consulta_especifica/${id_consulta}`
        ),
      ]).then(
        ([
          dvssDiaEspecificoResponse,
          solChuvaDiaEspecificoResponse,
          romaIVDiaEspecificoResponse,
          diarioEliminacaoDiaEspecificoResponse,
        ]) => {
          setDvssConsultaEspecifica(dvssDiaEspecificoResponse.data)
          setSolChuvaConsultaEspecifica(solChuvaDiaEspecificoResponse.data)
          setRomaIVConsultaEspecifica(romaIVDiaEspecificoResponse.data)
          setDiarioEliminacaoConsultaEspecifica(
            diarioEliminacaoDiaEspecificoResponse.data
          )
        }
      )
    }
    getInstrumentos()
    getConsulta()
  }, [])

  async function createSolChuvaThisWeek() {
    const agora = set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })
    let inicio_semana = startOfWeek(agora) //domingo
    let final_semana = lastDayOfWeek(agora) //sábado

    //verificar se existe Sol e Chuva da Semana atual
    let variaveis_parametro_sol_chuva_do_dia_especifico = [
      consulta.id_paciente,
      inicio_semana.toISOString(),
    ]
    let parametro_sol_chuva_do_dia_especifico = variaveis_parametro_sol_chuva_do_dia_especifico.join(
      ','
    )
    console.log(parametro_sol_chuva_do_dia_especifico)
    let response0 = await api.get(
      `sol_chuva_do_dia_especifico/${parametro_sol_chuva_do_dia_especifico}`
    )
    if (response0.data.id_sol_chuva != undefined) {
      history.push('/sol-chuva-preenchido/' + response0.data.id_sol_chuva)
    } else {
      try {
        //VERIFICAR SE O WHILE ESPERA O AWAIT TERMINAR OU SE PRECISA COLOCAR UM PROMISE OU ALGO DO TIPO
        let response4 = await api.post('sol_chuva', {
          id_paciente: consulta.id_paciente,
          id_consulta: consulta.id_consulta,
          data_inicial: inicio_semana.toISOString(),
          data_final: final_semana.toISOString(),
          segunda: 0,
          terca: 0,
          quarta: 0,
          quinta: 0,
          sexta: 0,
          sabado: 0,
          domingo: 0,
        })
        history.push('/sol-chuva-preenchido/' + response4.data.id)
      } catch (err) {
        alert('Erro ao Cadastrar Formulário. Tente Novamente.')
      }
    }
  }

  async function createRomaIVThisWeek() {
    const agora = set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })
    let inicio_semana = startOfWeek(agora) //domingo
    let final_semana = lastDayOfWeek(agora) //sábado

    //verificar se existe Sol e Chuva da Semana atual
    let variaveis_parametro_roma_iv_do_dia_especifico = [
      consulta.id_paciente,
      inicio_semana.toISOString(),
    ]
    let parametro_roma_iv_do_dia_especifico = variaveis_parametro_roma_iv_do_dia_especifico.join(
      ','
    )
    console.log(parametro_roma_iv_do_dia_especifico)
    let response0 = await api.get(
      `roma_iv_do_dia_especifico/${parametro_roma_iv_do_dia_especifico}`
    )
    if (response0.data.id_roma != undefined) {
      history.push('/romaiv-preenchido/' + response0.data.id_roma)
    } else {
      try {
        //VERIFICAR SE O WHILE ESPERA O AWAIT TERMINAR OU SE PRECISA COLOCAR UM PROMISE OU ALGO DO TIPO
        let response4 = await api
          .post('roma_iv', {
            id_paciente: consulta.id_paciente,
            id_consulta,
            data_inicial: inicio_semana.toISOString(),
            data_final: final_semana.toISOString(),
            pergunta_1: 0,
            pergunta_2: 0,
            pergunta_3: 0,
            pergunta_4: 0,
            pergunta_5: 0,
            pergunta_6: 0,
          })
        history.push('/romaiv-preenchido/' + response4.data.id)
      } catch (err) {
        alert('Erro ao Cadastrar Formulário. Tente Novamente.')
      }
    }
  }

  return (
    <PlataformaPadrao title="Instrumentos a Preencher">
      <Container>
        <Row>
          {instrumentos_dessa_consulta[2] === '1' && (
            <Col className="cards">
              <CardInstrumentoAPreencher
                title="Escore de Sintoma de Disfunção Miccional"
                onClick={() => {
                  history.push('/dvss-preenchido/' + id_consulta)
                }}
                image={Bexiga}
              />
            </Col>
          )}

          {instrumentos_dessa_consulta[1] === '1' && (
            <Col className="cards">
              <CardInstrumentoAPreencher
                title="Critérios de Roma IV"
                onClick={() => setShowModalRomaIV(true)}
                image={Coco}
              />
            </Col>
          )}
        </Row>
        <Row className="row">
          {instrumentos_dessa_consulta[3] === '1' && (
            <Col className="cards">
              <CardInstrumentoAPreencher
                title="Diário de Eliminações"
                onClick={() => setShowModalDiarioEliminacao(true)}
                // image={Banheiro}
                image={diario}
              />
            </Col>
          )}
          {instrumentos_dessa_consulta[0] === '1' && (
            <Col className="cards">
              <CardInstrumentoAPreencher
                title="Calendário Sol e Chuva"
                onClick={() => setShowModalSolChuva(true)}
                image={SolChuva}
              />
            </Col>
          )}
        </Row>
        <Modal
          show={showModalSolChuva}
          onHide={() => setShowModalSolChuva(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Instrumentos Sol e Chuva dessa consulta</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              Por favor, preencha os formulários<br></br>de todas semanas
              indicadas.
            </p>
            <p id="espe2">Preencha o formulário da semana atual: </p>
            <div onClick={createSolChuvaThisWeek}>
              <Button variant="danger">
                <span>Esta Semana</span>
              </Button>
              <br></br>
              <br></br>
            </div>
            <p id="espe2">Selecione a semana:</p>

            {solChuvaConsultaEspecifica.map(
              (instrumentos_dessa_consulta, i) => {
                console.log(data_consulta_menos_um_mes)
                if (
                  new Date(instrumentos_dessa_consulta.data_inicial) >=
                  data_consulta_menos_um_mes
                )
                  return (
                    <Link
                      id="espe1"
                      key={i}
                      className="cada-formulario"
                      to={
                        '/sol-chuva-preenchido/' +
                        instrumentos_dessa_consulta.id_sol_chuva
                      }
                    >
                      <Button variant="contained">
                        <span>
                          semana {i + 1} -{' '}
                          {new Date(
                            instrumentos_dessa_consulta.data_inicial
                          ).getDate()}
                          /
                          {new Date(
                            instrumentos_dessa_consulta.data_inicial
                          ).getMonth() + 1}{' '}
                          até{' '}
                          {new Date(
                            instrumentos_dessa_consulta.data_final
                          ).getDate()}
                          /
                          {new Date(
                            instrumentos_dessa_consulta.data_final
                          ).getMonth() + 1}
                        </span>
                      </Button>
                      <br></br>
                    </Link>
                  )
              }
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => setShowModalSolChuva(false)}
            >
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModalRomaIV} onHide={() => setShowModalRomaIV(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Instrumentos Roma IV dessa consulta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Por favor, preencha os formulários<br></br>de todas semanas
              indicadas.
            </p>
            <p id="espe2">Preencha o formulário da semana atual: </p>
            <div onClick={createRomaIVThisWeek}>
              <Button variant="danger">
                <span>Esta Semana</span>
              </Button>
              <br></br>
              <br></br>
            </div>
            <p id="espe2">Selecione a semana:</p>

            {romaIVConsultaEspecifica.map((instrumentos_dessa_consulta, i) => {
              if (
                new Date(instrumentos_dessa_consulta.data_inicial) >=
                data_consulta_menos_um_mes
              )
                return (
                  <Link
                    id="espe1"
                    key={i}
                    className="cada-formulario"
                    to={'/romaiv-preenchido/' + instrumentos_dessa_consulta.id_roma}
                  >
                    <Button variant="contained">
                      <span>
                          semana {i + 1} -{' '}
                          {new Date(
                            instrumentos_dessa_consulta.data_inicial
                          ).getDate()}
                          /
                          {new Date(
                            instrumentos_dessa_consulta.data_inicial
                          ).getMonth() + 1}{' '}
                          até{' '}
                          {new Date(
                            instrumentos_dessa_consulta.data_final
                          ).getDate()}
                          /
                          {new Date(
                            instrumentos_dessa_consulta.data_final
                          ).getMonth() + 1}
                        </span>
                    </Button>
                    <br></br>
                  </Link>
                  )
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowModalRomaIV(false)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModalDvss} onHide={() => setShowModalDvss(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Escore de Sintoma de Disfunção Miccional</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              Por favor, preencha os formulários<br></br>de todas semanas
              indicadas.
            </p>
            <p id="espe2">Selecione a semana:</p>

            {dvssConsultaEspecifica.map((instrumentos_dessa_consulta, i) => (
              <Link
                id="espe1"
                key={i}
                className="cada-formulario"
                to={'/dvss-preenchido/' + instrumentos_dessa_consulta.id_dvss}
              >
                <Button variant="contained">
                  <span>semana {i + 1}</span>
                </Button>
              </Link>
            ))}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowModalDvss(false)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showModalDiarioEliminacao}
          onHide={() => setShowModalDiarioEliminacao(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Instrumentos Diário de Eliminações dessa consulta
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              Por favor, preencha os formulários <br></br> de todos os dias
              indicados.
            </p>
            <p id="espe2">Selecione o dia:</p>

            {diarioEliminacaoConsultaEspecifica.map(
              (instrumentos_dessa_consulta, i) => (
                <Link
                  id="espe1"
                  key={i}
                  className="cada-formulario"
                  to={
                    '/diario-eliminacoes-preenchido/' +
                    instrumentos_dessa_consulta.id_diario
                  }
                >
                  <Button variant="contained">
                    <span>dia {i + 1}</span>
                  </Button>
                </Link>
              )
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => setShowModalDiarioEliminacao(false)}
            >
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </PlataformaPadrao>
  )
}
