import './styles.css'

import { Add, Update } from '@material-ui/icons'
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { Col, Modal, Row } from 'react-bootstrap'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import DateFnsUtils from '@date-io/date-fns'
import PlataformaPadrao from '../../components/plataforma-padrao'
import { ThemeProvider } from '@material-ui/styles'
import api from '../../services/api'
import axios from 'axios'
import { createMuiTheme } from '@material-ui/core'
import ptlocale from 'date-fns/locale/pt-BR'

export default function DisponibilidadeEdit() {
  const { id } = useParams()
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [checked, setChecked] = useState([])
  const [disponibilidade, setDisponibilidade] = useState([])
  const [all, setAll] = useState(false)
  const [buttonText, setButtonText] = useState('selecionar tudo')

  useEffect(() => {
    if (all) {
      setButtonText('limpar seleção')
    } else {
      setButtonText('selecionar tudo')
    }
  }, [all])

  function selectDeselectAll() {
    if (all) {
      setAll(false)
      setChecked([])
    } else {
      setAll(true)
      setChecked(disponibilidade.map((horario) => horario))
    }
  }

  useEffect(async () => {
    api
      .get(`disponibilidade-pesquisador/${id}`)
      .then((response) => setDisponibilidade(response.data))
  }, [])

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  async function handleAddHorario() {
    const dado = { id_pesquisador: id, hora_inicio: selectedDate }
    try {
      api
        .post('disponibilidade', dado)
        .then(alert('Disponibilidade criada com sucesso!'))
      history.go(0)
    } catch {
      alert('Algo deu errado. Tente novamente!')
    }
  }
  async function handleExclusaoDisp() {
    try {
      await axios.all(
        checked.map(async (horario) => {
          await api.delete(`/disponibilidade/${horario.id_disponibilidade}`)
        })
      )
      alert('Disponibilidades selecionadas deletadas com sucesso!')
      window.location.reload()
    } catch {
      alert('Algo deu errado. Tente novamente!')
    }
  }

  const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersBasePicker: {
        pickerView: {
          maxWidth: '500px',
        },
      },
    },
  })

  return (
    <PlataformaPadrao>
      <Row>
        <Col>
          <Button
            onClick={() => setShowModal(true)}
            variant='contained'
            color='primary'
          >
            <Add />
            Adicionar horário específico
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => history.push(`/disponibilidade-semestral/${id}`)}
            variant='contained'
            color='primary'
          >
            <Update />
            Atualizar agenda semestral
          </Button>
        </Col>
      </Row>
      <h1 style={{ marginTop: 20 }}>Lista de Disponibilidades Atuais</h1>
      {disponibilidade.length && (
        <>
          <div className='lista-disponibilidades'>
            <List>
              {disponibilidade.map((horarios) => {
                let id = new Date(horarios.hora_inicio).toLocaleString('pt-BR')
                return (
                  <ListItem
                    key={id}
                    role={undefined}
                    dense
                    button
                    onClick={() => {
                      const currentIndex = checked.indexOf(horarios)
                      const newChecked = [...checked]

                      if (currentIndex === -1) {
                        newChecked.push(horarios)
                      } else {
                        newChecked.splice(currentIndex, 1)
                      }

                      setChecked(newChecked)
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge='start'
                        checked={checked.indexOf(horarios) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': id }}
                      />
                    </ListItemIcon>
                    <ListItemText id={id} primary={id} />
                  </ListItem>
                )
              })}
            </List>
          </div>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <Button
                variant='contained'
                color='primary'
                onClick={selectDeselectAll}
              >
                {buttonText}
              </Button>
            </Col>
            <Col>
              <Button
                variant='contained'
                color='primary'
                onClick={handleExclusaoDisp}
              >
                Apagar selecionados
              </Button>
            </Col>
          </Row>
        </>
      )}
      {/* No caso abaixo tá imprimindo um '0' na tela (em cima do h3) e eu nao faco ideia de onde esta vindo */}
      {!disponibilidade.length && (
        <h3>Ainda não existem disponibilidades cadastradas</h3>
      )}
      {/* Modal de disponibilidades*/}
      <Modal id='modaldisp' show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar horário na agenda</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='calendariodisp'>
            <h4>Selecione o Dia e o Horário de sua Nova Disponibilidade</h4>
            <div>
              <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptlocale}>
                  <DateTimePicker
                    ampm={false}
                    disablePast
                    value={selectedDate}
                    format={'dd/MM/yyyy HH:mm'}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 5,
            }}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={handleAddHorario}
            >
              Adicionar
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </PlataformaPadrao>
  )
}
