import React, { useState, useEffect } from 'react'
import './styles.css'
import { Link } from 'react-router-dom'
import { MDBCol } from 'mdbreact'
import api from '../../services/api'

export default function TagsBlog() {
  const [tags, setTags] = useState([])

  useEffect(async () => {
    await api.get('tags').then((response) => {
      let tagsArray = response.data.map((id) => id['tag'])
      setTags(tagsArray)
    })
  }, [])

  return (
    <>
      <MDBCol sm="2">
        <div className="coluna-tags">
          <h5>Marcadores</h5>
          {tags.map((tag) => {
            return (
              <ul>
                <Link className="item-tag" to={'/blog/' + tag}>
                  {tag}
                </Link>
              </ul>
            )
          })}
        </div>
      </MDBCol>
    </>
  )
}
