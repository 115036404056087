import React from 'react'
import Routes from './routes'

//os imports abaixo serão aplicados em qualquer pagina que for criada

import 'bootstrap/dist/css/bootstrap.min.css' //css do bootstrap

import './global.css' //css proprio global

function App() {
  return <Routes />
}

export default App
