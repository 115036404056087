import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import './styles.css'
import Img_1 from '../../assets/bubbles.jpg'
import NavBarBlog from '../../components/NavbarBlog'
import FooterBlog from '../../components/FooterBlog'
import HeaderBlog from '../../components/HeaderBlog'
import Noticiapostagem from '../../components/Noticiapostagem'
import api from '../../services/api'
import { useParams } from 'react-router'

/*const diadata = "Segunda-feira, 16 de novembro de 2020"
const noticiaTitulo = "O coronavírus é transmitido por animais domésticos?"
const noticiaImagem = Img_1
const noticiaResumo = "Até o momento, não há relato científico de animais domésticos transmitindo a infecção por SARS-CoV-2 para humanos. Contudo, relatos científicos mostraram animais infectados com SARS-CoV-2.k"
const noticiaAutor = "Fulano de Tal"
const [noticiaTags0,noticiaTags1] = ["Animais","Coronavírus"]*/

export default function Postagem() {
  let { id } = useParams()
  const [postagem, setPostagem] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    api.get(`noticias/${id}`).then((response) => {
      setPostagem(response.data)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  //só retorna a página em si quando o loading acaba (quando os dados são carregados)
  return (
    <>
      <NavBarBlog />
      <HeaderBlog />
      <Noticiapostagem
        diadata={postagem.data}
        noticiaTitulo={postagem.titulo}
        noticiaImagem={postagem.imagem}
        noticiaVideo={postagem.video}
        noticiaTexto={postagem.conteudo}
        noticiaAutor={postagem.autores}
        noticiaRevisor={postagem.revisores}
        noticiaTags0={postagem.tags}
      />
      <FooterBlog />
    </>
  )
}
