import React, { useState, useEffect } from 'react'
import './styles.css'
import api from '../../services/api'
import { Spinner } from 'react-bootstrap'
import ItemListaEstudante from '../../components/ItemListaEstudante'
import PlataformaPadrao from '../../components/plataforma-padrao'
import axios from 'axios'
import Table from 'react-bootstrap/Table'

export default function ListaEstudante() {
  const [estudante, setEstudante] = useState([])
  const [globalEstudante, setGlobalEstudante] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    let promisse_estudante = []
    let promisse_global = []

    await api
      .get('alunos_graduacao')
      .then(({ data }) => {
        promisse_estudante = data
      })
      .catch(alert)

    promisse_global = await axios.all(
      promisse_estudante.map((estudanteAtual) =>
        api
          .get(`global/${estudanteAtual.id_global}`)
          .then((response) => response.data)
      )
    )

    setEstudante(promisse_estudante)
    setGlobalEstudante(promisse_global)

    setLoading(false)
  }, [])

  return loading ? (
    <Spinner />
  ) : (
    <>
      <PlataformaPadrao title="Estudantes">
        <p id="aceitarcn">Lista de estudantes cadastrados</p>
        <div className="lista-geral-table-wrapper">
          <Table>
            <thead>
              <th>
                <div>NOME</div>
              </th>
              <th>
                <div>E-MAIL</div>
              </th>
              <th>
                <div>TELEFONE</div>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              {!loading &&
                estudante &&
                estudante.map((c, i) => {
                  return (
                    <ItemListaEstudante
                      excluir={false}
                      editar={false}
                      olhar={true}
                      estudante={c}
                      globalEstudante={globalEstudante[i]}
                    />
                  )
                })}
            </tbody>
          </Table>
        </div>
      </PlataformaPadrao>
    </>
  )
}
