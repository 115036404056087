import './styles.css'

import React, { useEffect, useState } from 'react'

import ItemListaPacientesEspecificos from '../../components/ItemListaPacientesEspecificos'
import PlataformaPadrao from '../../components/plataforma-padrao'
import Table from 'react-bootstrap/Table'
import api from '../../services/api'
import { useParams } from 'react-router'

export default function ListaPacientesEspecificos() {
  //const [loading, setLoading] = useState(true);
  let { id } = useParams()
  // let id = 6
  const [idpacientesespecificos, setIdpacientesespecificos] = useState([])

  function refreshList() {
    api
      .get(`pacientes-sem-alta/${id}`)
      .then(({ data }) => setIdpacientesespecificos(data))
      .catch(console.log)
  }

  useEffect(refreshList, [])

  return (
    <>
      <PlataformaPadrao title='Pacientes' legend='Lista dos meus pacientes'>
        <div className='lista-geral-table-wrapper'>
          <Table>
            <thead>
              <th>
                <div>NOME</div>
              </th>
              <th>
                <div>E-MAIL</div>
              </th>
              <th>
                <div>TELEFONE</div>
              </th>
              <th>
                <div>PROX. CONSULTA</div>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              {idpacientesespecificos.map((idpacientesespecificos) => (
                <>
                  <ItemListaPacientesEspecificos
                    excluir={false}
                    editar={false}
                    olhar={true}
                    idpacientesespecificos={idpacientesespecificos}
                  />
                </>
              ))}
            </tbody>
          </Table>
        </div>
        {/*{idpacientesespecificos.map((idpacientesespecificos) => (
          <>
            <ItemListaPacientesEspecificos
              excluir={false}
              editar={false}
              olhar={true}
              idpacientesespecificos={idpacientesespecificos}
            />
          </>
        ))}
        */}
      </PlataformaPadrao>
    </>
  )
}
