import './styles.css'

import { Link, useHistory } from 'react-router-dom'
import React, { useContext, useRef, useState } from 'react'

import { Alert } from '@material-ui/lab'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Button from '@material-ui/core/Button'
import InputMask from 'react-input-mask'
import { Spinner } from 'react-bootstrap'
import TextField from '@material-ui/core/TextField'
import { UserContext } from '../../contexts/user'
import api from '../../services/api'
import enfermeiroIcon from '../../assets/enfermeiroIcon.svg'
import estudanteIcon from '../../assets/estudanteIcon.svg'
import familiaIcon from '../../assets/familiaIcon.svg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    justifyContent: 'center',
    color: '#000000',
  },
  botao: {
    backgroundColor: '#F54C4C',
  },
  botaosecundario: {
    color: '#F54C4C',
  },
  backLogin: {
    marginTop: '1.2rem',
    cursor: 'pointer',
  },
}))

export default function Login() {
  const ref = useRef()
  const classes = useStyles()
  const history = useHistory()

  const [cpf, setCpf] = useState('')
  const [senha, setSenha] = useState('')
  const [erro, setErro] = useState(null)

  const [isRecuperandoSenha, setIsRecuperandoSenha] = useState(false)
  const [email, setEmail] = useState('')
  const [loading_animation, setLoading_animation] = useState(false)

  const { login } = useContext(UserContext)

  async function handleLogin(event) {
    //pra fazer login precisa estar autenticado certo? teria q fazer um if (!response.data.autenticado) {break} sla algo assim, só pra n deixar fazer o login em casos não autenticados pelo admin
    event.preventDefault()
    const response = await api.post('/login', { cpf, senha }).catch((err) => {
      setErro(
        err.response?.data?.message ??
          'Parece que Algo deu Errado, Tente Novamente mais Tarde.'
      )
      console.log({ err })
    })

    if (response.data.autenticado_pelo_admin) {
      await login(response.data)
      if (response.data.tipo === 'estudante') {
        history.replace('/lista-postagem')
      } else if (response.data.tipo === 'pesquisador') {
        history.replace('/principal-pesquisador')
      } else if (response.data.tipo === 'paciente') {
        const consulta = await api.get(
          `proxima_consulta/${response.data.id_global}`
        )
        if (consulta.data) {
          history.replace('/principal-familia')
        } else {
          history.replace('/marcar-primeira-consulta')
        }
      } else if (response.data.tipo === 'admin') {
        history.replace('/principal-adm')
      }
    } else {
      alert(
        'Você ainda não foi autenticado, certifique-se que o seu email foi confirmado ou contate o administrador'
      )
    }
  }

  function handleRecuperarSenha(event) {
    event.preventDefault()
    setLoading_animation(true)
    api
      .post('/recuperar-senha', { email })
      .then(async () => {
        alert('Se seu email estiver correto, verifique a caixa de mensagens.')
        history.push('/')
      })
      .catch((err) => {
        setErro(
          err.response?.data?.message ??
            'Parece que Algo deu Errado, Tente Novamente mais Tarde.'
        )
        setLoading_animation(false)
        console.log({ err })
      })
  }

  function handleScroll(e) {
    window.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: 'instant',
    })
  }

  function renderForm() {
    if (isRecuperandoSenha) {
      return (
        <>
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            className={classes.input}
            id='outlined-required'
            label='Digite seu E-mail'
            type='email'
            variant='outlined'
            required
          />
          <div className='botoes-entrada'>
            <Button
              type='submit'
              className={classes.botao}
              variant='contained'
              color='secondary'
            >
              Enviar
            </Button>
          </div>
          {loading_animation && <Spinner animation='border' />}
        </>
      )
    }
    return (
      <>
        <div className='entrada'>
          <InputMask
            mask='999.999.999-99'
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
          >
            {() => (
              <TextField
                className={classes.input}
                required
                id='outlined-required'
                label='CPF'
                variant='outlined'
              />
            )}
          </InputMask>
        </div>
        <div className='entrada'>
          <TextField
            onChange={(e) => setSenha(e.target.value)}
            className={classes.input}
            id='outlined-password-input'
            label='Senha'
            type='password'
            variant='outlined'
            required
          />
        </div>
        <div className='botoes-entrada'>
          <Button
            type='submit'
            className={classes.botao}
            variant='contained'
            color='secondary'
          >
            Entrar
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      <div className='login-container'>
        <div className='lateral-esquerda-login-voltar'>
          <Link className='voltar-site-link' to='/'>
            <h4 className='voltar-site'>
              <ArrowBackIcon style={{ fontSize: 30, marginRight: 5 }} />
              Voltar
            </h4>
          </Link>
        </div>
        <div className='lateral-esquerda-login'>
          <h1 className='titulo-lateral'>BEM-VINDO À PLATAFORMA ENF-UROPED</h1>
          <h4 className='descricao-opcional-lateral'>
            Se já for cadastrado, basta preencher os campos ao lado
            <ArrowForwardIcon style={{ fontSize: 35, marginLeft: 20 }} />
          </h4>
          <h4 className='descricao-lateral'>
            Caso ainda precise se cadastrar,{' '}
            <strong style={{ cursor: 'pointer ' }} onClick={handleScroll}>
              clique aqui
            </strong>
          </h4>
          <h4 className='descricao-lateral'>
            <ArrowDropDownIcon /> ou arraste para baixo <ArrowDropDownIcon />
          </h4>
        </div>
        <div className='lateral-direita-login'>
          <h1 className='titulo-login'>LOGIN</h1>
          {erro && (
            <Alert style={{ marginBottom: '1.2rem' }} severity='error'>
              {' '}
              {erro}{' '}
            </Alert>
          )}
          <form
            className={classes.root}
            onSubmit={isRecuperandoSenha ? handleRecuperarSenha : handleLogin}
          >
            {renderForm()}
          </form>
          {!isRecuperandoSenha && (
            <div className='botoes-entrada'>
              <Button
                onClick={(_) => setIsRecuperandoSenha(true)}
                to='recuperar-senha'
                className={classes.botaosecundario}
                color='secondary'
              >
                Esqueceu a senha?
              </Button>
            </div>
          )}
          {isRecuperandoSenha && (
            <div
              className={classes.backLogin}
              onClick={(_) => setIsRecuperandoSenha(false)}
            >
              <span>
                <ArrowBackIcon style={{ fontSize: 30, marginRight: 5 }} />
                Voltar
              </span>
            </div>
          )}
        </div>
      </div>
      <div className='cadastro-container' ref={ref}>
        <div className='texto-cadastro'>
          <h2>CLIQUE PARA CADASTRAR:</h2>
        </div>
        <div className='opcoes-cadastro'>
          <Link to='/cadastro-familia' className='botoes-cadastro'>
            <img className='family-icon' src={familiaIcon} />
            <div className='opcoes-links'>
              <h5 className='opcoes-texto'>PACIENTE E RESPONSÁVEL</h5>
            </div>
          </Link>
          <Link to='cadastro-pesquisador' className='botoes-cadastro'>
            <img className='nurse-icon' src={enfermeiroIcon} />
            <div className='opcoes-links'>
              <h5 className='opcoes-texto'>ENFERMEIRA(O)</h5>
            </div>
          </Link>
          <Link to='cadastro-estudante' className='botoes-cadastro'>
            <img className='student-icon' src={estudanteIcon} />
            <div className='opcoes-s'>
              <h5 className='opcoes-texto'>ESTUDANTE</h5>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}
