export function email_primeira_consulta(tokens) {
    return ({
        html: `<div
    style="max-width:630px; font-family: Helvetica, Arial; padding: 15px 25px; margin: 0; background-color: #F5DB7D; align-items: center; justify-content: flex-start; min-width:500px; width: 100%;">
        <div
            style="width: 100%; max-width: 600px; background-color: #F5DB7D; align-items: center; margin: 15px 0;">
            <h1 style="font-size: 30px; margin: 5px 0 0 0; text-align: center;">Enf-UROPED</h1>
            <h3 style="font-size: 24px; font-weight: 500; margin: 0 0 5px 0; text-align: center;">Consulta - Preencher Formulários</h3>
        </div>
        <div
            style="width: 100%; max-width: 600px; font-size: 20px; align-items: center;">
            <p style="font-size: 16px; margin: 5px 0; font-weight: 400; text-align: justify;">
                Por favor, acesse os <i>links</i> abaixo e preencha todos os formulários até a data da consulta! Todas
                as datas
                obrigatórias estão indicadas abaixo.
            </p>
            <p style="font-size: 14px; margin: 5px 0; max-width: 600px; font-weight: 600; text-align: center;">
                O preenchimento dos mesmos são OBRIGATÓRIOS e necessários para realização da consulta, portanto não
                apague este e-mail para ter facilidade no preenchimento!
            </p>
        </div>
        <div
            style="width: 100%; max-width: 600px; font-size: 18px; align-items: flex-start; margin: 5px 0; background-color: #dfc8745e; padding: 10px;">
            <h4 style="font-size: 20px; font-weight: 600; margin: 5px 15px; text-align: justify;">Sol e chuva</h4>
            ${tokens[0].map((sol_chuva, i) => {
            return (`<div
                style="display: flex; flex-direction: row; align-items: flex-start; justify-content: center; margin-bottom: 3px;">
                    <div
                        style="background-color: #F54C4C; align-items: center; justify-content: center; border-radius: 5px; cursor: pointer;">
                        <a href="${process.env.REACT_APP_CLIENT_URL + '/token/' + sol_chuva}" style="padding:5px; color: white; text-align: center; text-decoration: none;">SEMANA ${i + 1}</a>
                    </div>
                </div>`)
        }).join('')
            }
        </div>
        <div
            style="width: 100%; max-width: 600px; font-size: 18px; align-items: flex-start; margin: 5px 0; background-color: #dfc8745e; padding: 10px;">
            <h4 style="font-size: 20px; font-weight: 600; margin: 5px 15px; text-align: justify;">Roma IV</h4>
            ${tokens[1].map((romaiv, i) => (`
                <div
                style="display: flex; flex-direction: row; align-items: flex-start; justify-content: center; margin-bottom: 3px;">
                    <div
                        style="background-color: #F54C4C; align-items: center; justify-content: center; border-radius: 5px; cursor: pointer;">
                        <a href="${process.env.REACT_APP_CLIENT_URL + '/token/' + romaiv}" style="padding:5px; color: white; text-align: center; text-decoration: none;">SEMANA ${i + 1}</a>
                    </div>
                </div>`
            )).join('')
            }
        </div>
        <div
            style="width: 100%; max-width: 600px; font-size: 18px; align-items: flex-start; margin: 5px 0; background-color: #dfc8745e; padding: 10px;">
            <h4 style="font-size: 20px; font-weight: 600; margin: 5px 15px; text-align: justify;">Diário de Eliminações</h4>
            ${tokens[3].map((diario, i) => (`
                <div
                style="display: flex; flex-direction: row; align-items: flex-start; justify-content: center; margin-bottom: 3px;">
                    <div
                        style="background-color: #F54C4C; align-items: center; justify-content: center; border-radius: 5px; cursor: pointer;">
                        <a href="${process.env.REACT_APP_CLIENT_URL + '/token/' + diario}" style="padding:5px; color: white; text-align: center; text-decoration: none;">DIA ${i + 1}</a>
                    </div>
                </div>`
            )).join('')
            }
        </div>
        <div
            style="width: 100%; max-width: 600px; font-size: 18px; align-items: flex-start; margin: 5px 0; background-color: #dfc8745e; padding: 10px;">
            <h4 style="font-size: 20px; font-weight: 600; margin: 5px 15px; text-align: justify;">Escore de Sintoma de Disfunção Miccional</h4>
            ${tokens[2].map((dvss, i) => (`
                <div
                    style="display: flex; flex-direction: row; align-items: flex-start; justify-content: center; margin-bottom: 3px;">
                    <div
                        style="background-color: #F54C4C; align-items: center; justify-content: center; border-radius: 5px; cursor: pointer;">
                        <a href="${process.env.REACT_APP_CLIENT_URL + '/token/' + dvss}" style="padding:5px; color: white; text-align: center; text-decoration: none;">UM MÊS ANTES DA CONSULTA</a>
                    </div>
                </div>`
            )).join('')
            }
        </div>
        <div
            style="width: 100%; max-width: 600px; align-items: flex-start; margin: 5px 0; padding: 10px;">
            <p style="font-size: 16px; font-weight: 600; margin: 5px 0; text-align: center;">Qualquer dúvida ficaremos felizes em ajudar através do e-mail:</p>
            <p style="font-size: 16px; font-weight: 600; margin: 5px 0; text-align: center;">(e-mail para contato)</p>
        </div>
    </div>`
    })

}