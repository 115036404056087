import React from 'react'
import { TiWarning } from 'react-icons/ti'

export default function SemPermissao() {
  return (
    <>
      <h1 style={{ color: 'red', margin: 16 }}>
        <TiWarning size={68} color="#fdef12" /> Você não tem permissão para
        acessar essa página.
      </h1>
    </>
  )
}
