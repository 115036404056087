import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import './styles.css'
import api from '../../services/api'
import PlataformaPadrao from '../../components/plataforma-padrao'
import { Spinner } from 'react-bootstrap'
import ItemListaPesquisador from '../../components/ItemListaPesquisador'
import Table from 'react-bootstrap/Table'

export default function ListaAdministrador() {
  const [administrador, setAdmin] = useState([])
  const [globalAdministrador, setGlobal] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    await api
      .get('getAdmins')
      .then(({ data }) => {
        setGlobal(data.pesquisadoresGlobal)
        setAdmin(data.pesquisadores)
        setLoading(false)
      })
      .catch(() => alert('Erro ao recuperar informações, tente novamente.'))
  }, [])

  return loading ? (
    <Spinner />
  ) : (
    <PlataformaPadrao
      title="Administradores"

    >
      <p id="aceitarcn">Lista de administradores cadastrados</p>
      <div className="lista-geral-table-wrapper">
        <Table >
          <thead>
            <th>
              <div>NOME</div>
            </th>
            <th>
              <div>E-MAIL</div>
            </th>
            <th>
              <div>TELEFONE</div>
            </th>
            <th>
            </th>
            <th>
            </th>
            <th>
            </th>
            <th>
            </th>
          </thead>
          <tbody>
            {administrador.map((_, idx) => (
              <ItemListaPesquisador
                key={idx}
                excluir={false}
                editar={false}
                olhar={true}
                pesquisador={administrador[idx]}
                globalPesquisador={globalAdministrador[idx]}
              />
            ))}
          </tbody>
        </Table>
      </div>
      {/*{administrador.map((_, idx) => (
        <ItemListaPesquisador
          key={idx}
          excluir={false}
          editar={false}
          olhar={true}
          pesquisador={administrador[idx]}
          globalPesquisador={globalAdministrador[idx]}
        />
      ))}
      */}
    </PlataformaPadrao>
  )
}
