import './styles.css'

import { Container, Spinner } from 'react-bootstrap'
import { FiEye, FiTrash2 } from 'react-icons/fi'
import React, { useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import { FaRegEdit } from 'react-icons/fa'
import { Form } from 'react-bootstrap'
import FormularioPreenchidoFamilia from '../FormularioPreenchidoFamilia'
import FormularioPreenchidoFamiliaEditavel from '../FormularioPreenchidoFamiliaEditavel'
import Modal from 'react-bootstrap/Modal'
import TextField from '@material-ui/core/TextField'
import api from '../../services/api'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    justifyContent: 'center',
    color: '#000000',
  },
  botao: {
    backgroundColor: '#F54C4C',
  },
  botaosecundario: {
    color: '#F54C4C',
  },
}))

export default function ItemListaFamilia({
  excluir,
  editar,
  olhar,
  idpacientesespecificos,
}) {
  const classes = useStyles()
  const [paciente, setPaciente] = useState([])
  const [globalPaciente, setGlobalPaciente] = useState([])
  const [horarios_consultas, setHorariosConsultas] = useState([])
  const [second, setSecond] = useState(true)
  const history = useHistory()

  const [showVer, setShowVer] = useState(false)

  const handleCloseVer = () => setShowVer(false)
  const handleShowVer = () => setShowVer(true)

  //Cadastro global
  const [nome, setNome] = useState(globalPaciente.nome)
  const [data_nascimento, setDataNascimento] = useState(
    globalPaciente.data_nascimento
  )
  const [email, setEmail] = useState(globalPaciente.email)
  const [cpf, setCpf] = useState(globalPaciente.cpf)
  const [senha, setSenha] = useState(globalPaciente.senha)
  const [telefone1, setTelefone1] = useState(globalPaciente.telefone1)
  const [telefone2, setTelefone2] = useState(globalPaciente.telefone2)
  const [sexo, setSexo] = useState(globalPaciente.sexo) //começa com Feminino pois é a primeira opção do select no front
  const [tipo, setTipo] = useState(globalPaciente.tipo)
  const [autenticado, setAutenticado] = useState(
    globalPaciente.autenticado_pelo_admin
  )
  const [proxConsulta, setProxConsulta] = useState({ hora_inicio: new Date() })

  let pagina_cada_paciente = `/cada-paciente/${globalPaciente.id_global}`
  //Cadastro Paciente

  async function getPacienteG() {
    const response1 = await api.get(`paciente/${idpacientesespecificos}`)
    setPaciente(response1.data)

    const response2 = await api.get(`global/${idpacientesespecificos}`)
    setGlobalPaciente(response2.data)

    const response3 = await api.get(
      `horarios_consultas/${idpacientesespecificos}`
    )
    setHorariosConsultas(response3.data)

    const response4 = await api.get(
      `proxima_consulta/${idpacientesespecificos}`
    )
    setProxConsulta(response4.data)
  }

  useEffect(getPacienteG, [])

  return (
    <>
      <tr>
        {/* <div className="itens-da-linha">*/}
        <td>{globalPaciente.nome}</td>
        <td>{globalPaciente.email}</td>
        <td>{globalPaciente.telefone1}</td>
        <td>{new Date(proxConsulta.hora_inicio).toLocaleString('pt-BR')}</td>
        {/* </div>*/}
        {/*<div className="botoes-lista">*/}
        <td>
          {' '}
          <Button variant='primary' onClick={handleShowVer}>
            DADOS
          </Button>
        </td>
        <td>
          {' '}
          <Button variant='primary' href={pagina_cada_paciente}>
            PASTA
          </Button>
        </td>

        {/* </div>} */}
      </tr>
      {/* Modal de visualizar dados */}
      <Modal id='modaldados' show={showVer} onHide={handleCloseVer}>
        <Modal.Header closeButton>
          <Modal.Title>Dados de {globalPaciente.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <section>
              <TextField
                className='textarea'
                label='Nome Completo do Paciente'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.nome}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Nome Completo do Responsável'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.nome_responsavel}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Telefone para contato 1'
                disabled
                id='outlined-disabled'
                defaultValue={globalPaciente.telefone1}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Telefone para contato 2'
                disabled
                id='outlined-disabled'
                defaultValue={globalPaciente.telefone2}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Cidade/Estado'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.cidade_estado}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='E-mail do Responsável'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.email_responsavel}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Data de Nascimento do Paciente'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.data_nascimento}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Data de Nascimento do Responsável'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.data_nascimento_responsavel}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Sexo'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.sexo}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Altura do Paciente (em centímetros)'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.altura}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Peso do Paciente (em quilogramas)'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.peso}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Escola do Paciente'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.escola}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='CPF do Paciente'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.cpf}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='RG do Paciente'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.rg}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Grau de Parentesco entre Responsável e Paciente'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.parentesco}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='CPF do Responsável'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.cpf_responsavel}
                variant='outlined'
              />
            </section>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseVer}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
