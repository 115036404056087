import React, { useContext } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import './styles.css'
import Logo from '../../assets/logo.png'
import { Link } from 'react-router-dom'
import { FiUser } from 'react-icons/fi'
import { UserContext } from '../../contexts/user'
import { useHistory } from 'react-router-dom'

export default function NavBarplataforma() {
  const { user, logout } = useContext(UserContext)

  const history = useHistory()

  function handleLogout(event) {
    event.preventDefault()
    logout()
    history.replace('/')
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="navbar-main"
        variant="dark"
      >
        <Navbar.Brand>
          <Link className="navbar-row" to={'/'}>
            <img
              alt=""
              src={Logo}
              width="60"
              height="60"
              className="d-inline-block align-top"
            />
            <div className="navbar-slogan">
              Prática Avançada de Enfermagem
              <br />
              em Uropediatria
            </div>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <FiUser size={30} color="#ffffff" />
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <div className="icone-user">
              <FiUser size={30} color="#ffffff" />
            </div>
            <div className="navbar-links" id="bem-vindo-plataforma">
              Olá, {user.nome}!
            </div>
            <div onClick={handleLogout} className="navbar-links">
              Sair
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}
