import { Link, useHistory } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import './styles.css'
import React, { useEffect, useState } from 'react'

export default function Noticiaprev({
  id,
  diadata,
  noticiaTitulo,
  noticiaImagem,
  noticiaResumo,
  noticiaAutor,
  noticiaTags0,
}) {
  const [tagsArray, setTagsArray] = useState([])
  const history = useHistory()
  let endereco = `/postagem/${id}`
  useEffect(() => {
    let tagsLidas = noticiaTags0.split(",")
    tagsLidas = tagsLidas.map((tagAtual) =>
      tagAtual.trim().toLowerCase()
    )
    setTagsArray(tagsLidas)

  }, [])
  return (
    <>
      <section className="noticia-prev">
        <p className="dia-data">
          {diadata.slice(8, 10) +
            '/' +
            diadata.slice(5, 7) +
            '/' +
            diadata.slice(0, 4)}
        </p>
        <h1 className="noticia-titulo" onClick={()=>{history.push(endereco)}}>{noticiaTitulo}</h1>
        <div className="noticia-imagem">
          <Image
            className="w-50"
            src={`${process.env.REACT_APP_API_URL}/files/uploads/${noticiaImagem}`}
            thumbnail
          />
        </div>
        <p className="noticia-resumo">{noticiaResumo}</p>
        <p>
          <Link to={endereco} className="mais-info">
            Mais informações &gt;&gt;
          </Link>{' '}
        </p>
        <div id="noticia-autores">
          <p>
            <b>Autor(es):</b>
            {noticiaAutor}
          </p>
          <p>
            <b>Marcadores:</b>
            {
              tagsArray.map((tag) => {
                return (
                  <Link className="linktag" to={'/blog/' + tag}>{'#'+tag}</Link>
                )
              })
            }

          </p>
        </div>
      </section>
    </>
  )
}
