import './styles.css'

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { add, lastDayOfWeek, set, startOfWeek, sub } from 'date-fns'
import { useHistory, useParams } from 'react-router-dom'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Axios from 'axios'
import PlataformaPadrao from '../../components/plataforma-padrao'
import { Spinner } from 'react-bootstrap'
import TextField from '@material-ui/core/TextField'
import { UserContext } from '../../contexts/user'
import api from '../../services/api'
import { email_primeira_consulta } from '../../emails'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  formLabel: {
    fontSize: 24,
  },
}))

export default function MarcarProximaConsulta() {
  const { id_paciente } = useParams()
  const classes = useStyles()
  const history = useHistory()
  const { user } = useContext(UserContext)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [disponibilidade, setDisponibilidade] = useState([])
  const [instrumentosArray, setInstrumentosArray] = useState([0, 0, 0, 0])
  const [instrumentos, setInstrumentos] = useState({
    inst1: 0,
    inst2: 0,
    inst3: 0,
    inst4: 0,
  })
  const [pacienteNome, setPacienteNome] = useState('')
  const [paciente, setPaciente] = useState('')
  const [pesquisador, setPesquisador] = useState('')
  const [loading_animation, setLoading_animation] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getDados = async () => {
      await api.get(`global/${id_paciente}`).then((response) => {
        setPacienteNome(response.data.nome)
        setPaciente(response.data)
      })

      await api.get(`global/${user.id_global}`).then((response) => {
        setPesquisador(response.data)
      })

      await api
        .get(`disponibilidade-pesquisador/${user.id_global}`)
        .then((response) => {
          //! filtra as disponibilidades para mostrar somente as disponibilidades 48h depois da hora atual

          let disp = response.data.filter((horario) => {
            let prox48h = new Date()
            prox48h.setDate(prox48h.getDate() + 2)

            return horario.hora_inicio > prox48h.toISOString()
          })

          setDisponibilidade(disp)
        })

      setLoading(false)
    }
    getDados()
  }, [])

  const handleInstChange = (e) => {
    e.preventDefault()
    setInstrumentos({ ...instrumentos, [e.target.name]: +e.target.checked })
  }

  useEffect(() => {
    setInstrumentosArray([
      instrumentos.inst1,
      instrumentos.inst2,
      instrumentos.inst3,
      instrumentos.inst4,
    ])
  }, [instrumentos])

  const handleInstrumentosProximaConsulta = async (
    id_consulta,
    data_consulta,
    instrumentos_necessarios
  ) => {
    const tokensConsultas = {
      sol_chuva: { tokens: [], idFormulario: [] },
      romaiv: { tokens: [], idFormulario: [] },
      dvss: [],
      diario: [],
    }

    const data_consulta_menos_um_mes = sub(data_consulta, { months: 1 })

    //! Sol e Chuva
    if (instrumentos_necessarios[0] === '1') {
      await api
        .post('sol_chuva_proxima_consulta', {
          id_paciente,
          id_consulta,
          data_consulta: data_consulta.toISOString(),
        })
        .then(({ data }) => {
          tokensConsultas.sol_chuva.tokens = data.tokens
          tokensConsultas.sol_chuva.idFormulario = data.idFormulario
        })
    }

    //! Roma IV
    if (instrumentos_necessarios[1] === '1') {
      await api
        .post('roma_iv_proxima_consulta', {
          id_paciente,
          id_consulta,
          data_consulta: data_consulta.toISOString(),
        })
        .then(({ data }) => {
          tokensConsultas.romaiv.tokens = data.tokens
          tokensConsultas.romaiv.idFormulario = data.idFormulario
        })
    }
    //! DVSS
    if (instrumentos_necessarios[2] === '1') {
      await api
        .post('dvss', {
          id_paciente,
          id_consulta,
          data_inicial: data_consulta_menos_um_mes.toISOString(),
          data_final: data_consulta.toISOString(),
          pergunta_1: 0,
          pergunta_2: 0,
          pergunta_3: 0,
          pergunta_4: 0,
          pergunta_5: 0,
          pergunta_6: 0,
          pergunta_7: 0,
          pergunta_8: 0,
          pergunta_9: 0,
          pergunta_10: 0,
          total: 0,
        })
        .then(({ data }) => {
          tokensConsultas.dvss.push(data.id)
        })
        .catch((err) => {
          alert('Erro ao Cadastrar Formulário. Tente Novamente.')
        })
    }
    //! DIARIO DE ELIMINAÇÕES
    if (instrumentos_necessarios[3] === '1') {
      let domingo_anterior_data_consulta = startOfWeek(data_consulta)
      let sabado_anterior_data_consulta = sub(domingo_anterior_data_consulta, {
        days: 1,
      })

      await Axios.all(
        [sabado_anterior_data_consulta, domingo_anterior_data_consulta].map(
          (value) =>
            api
              .post('diario_eliminacao', {
                id_paciente,
                id_consulta,
                data: value.toISOString(),
                ingestao_liquido_hora: '',
                ingestao_liquido_qualbebeida: '',
                ingestao_liquido_quantidademl: '',
                urina_hora: '',
                urina_quantidademl: '',
                urina_urgencia: '',
                urina_quantasvezesmolhou: '',
                urina_volumequemolhou: '',
                eliminacao_intestinal_hora: '',
                eliminacao_intestinal_tipo: '',
              })
              .then(({ data }) => {
                tokensConsultas.diario.push(data.id)
              })
        )
      ).catch((err) => {
        alert('Erro ao Cadastrar Formulário. Tente Novamente.')
      })
    }
    return tokensConsultas
  }

  const handleProximaConsulta = async (e) => {
    e.preventDefault()

    setLoading_animation(true)

    const data = {
      id_paciente,
      id_pesquisador: user.id_global,
      hora_inicio: new Date(selectedDate.hora_inicio),
      instrumentos: instrumentosArray.join(','),
    }

    try {
      await api.delete('/disponibilidade/' + selectedDate.id_disponibilidade)

      const consulta_marcada = await api
        .post('/horarios_consultas', data)
        .catch((_) => alert('Erro ao marcar próxima consulta.'))

      // antes de concluir tem que estabelecer a conexão entre paciente e pesquisador atualizando o campo id_pesquisador da tabela 'paciente' e o campo id_paciente da tabela 'pesquisador'

      await handleInstrumentosProximaConsulta(
        consulta_marcada.data.id_consulta,
        new Date(consulta_marcada.data.hora_inicio),
        consulta_marcada.data.instrumentos.split(',')
      ).then((tokensConsultas) => {
        Axios.all(
          Object.entries(tokensConsultas).map(async (val) => {
            if (val[0] == 'diario' || val[0] == 'dvss') {
              return val[1]
            } else {
              const result = val[1].tokens

              const forms = await api
                .post('createFormsProx', {
                  id_global: id_paciente,
                  data_consulta: new Date(
                    consulta_marcada.data.hora_inicio
                  ).toISOString(),
                  tipo: val[0],
                  ids_formularios: val[1].idFormulario,
                })
                .then(({ data }) => data)

              forms.forEach((val) => result.push(val))

              return result
            }
          })
        ).then(handleEnviosEmail)
      })

      alert('Próxima Consulta marcada com sucesso.')
      history.goBack()
    } catch (err) {
      alert('Erro ao Cadastrar Consulta. Tente Novamente.')
    }
  }

  const sendTokens = (tokens) => {
    console.log(tokens)

    const mailData = {
      nome_destinatario: paciente.nome,
      endereco_email: paciente.email,
      assunto_do_email: 'Preencha seus formulários toda semana',
      html: email_primeira_consulta(tokens).html,
    }

    api.post('send_mail', mailData).catch(console.log)
  }

  const handleEnviosEmail = (tokens) => {
    try {
      //! Envia email para o paciente sobre a marcação da consulta e os tokens
      sendTokens(tokens)

      //! Envia email ao administrador sobre a marcação da primeira consulta
      api.get('global/1').then(({ data }) => {
        api.post('/send_mail', {
          nome_destinatario: data.nome,
          endereco_email: data.email,
          assunto_do_email: 'Nova próxima consulta',
          texto_do_email: `O paciente "${paciente.nome}" acabou de marcar sua próxima consulta.`,
        })
      })
    } catch (error) {
      console.log(error)
      alert(
        'Não foi possível notificar os enfermeiras(os), por favor contate o suporte.'
      )
    }
  }

  const { inst1, inst2, inst3, inst4 } = instrumentos

  return loading ? (
    <Spinner />
  ) : (
    <PlataformaPadrao>
      <p id='tituloprox'>Marque a próxima consulta para {pacienteNome}</p>
      <p id='textoprox'>
        Selecione o dia e o<br></br>horário da proxima consulta dentre os dias
        disponíveis
      </p>
      <div className='container'>
        <Autocomplete
          id='escolha-disponibilidade'
          options={disponibilidade}
          getOptionLabel={(horario) =>
            new Date(horario.hora_inicio).toLocaleString('pt-BR')
          }
          style={{ width: 220 }}
          onChange={(_, newInputValue) => setSelectedDate(newInputValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Selecione o horário'
              variant='outlined'
              fullWidth
            />
          )}
        />
      </div>
      <FormControl>
        <FormLabel className={classes.formLabel}>
          <p id='textinst'>
            Quais Instrumentos são Necessários para a Próxima Consulta?
          </p>
        </FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={inst3}
                onChange={handleInstChange}
                name='inst3'
              />
            }
            label='DVSS'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={inst2}
                onChange={handleInstChange}
                name='inst2'
              />
            }
            label='Critérios de Roma IV'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={inst1}
                onChange={handleInstChange}
                name='inst1'
              />
            }
            label='Calendário Sol e Chuva'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={inst4}
                onChange={handleInstChange}
                name='inst4'
              />
            }
            label='Diário de Eliminações'
          />
        </FormGroup>
      </FormControl>
      <div className='btn-send'>
        <Button variant='contained' onClick={handleProximaConsulta}>
          Marcar Próxima Consulta
        </Button>
        {loading_animation && <Spinner animation='border' />}
      </div>
    </PlataformaPadrao>
  )
}
