import React from 'react'
import {
  Button,
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  NavDropdown,
} from 'react-bootstrap'
import CardEquipe from '../../components/CardEquipe'
import './styles.css'

export default function EquipePaginaPrincipal() {
  /*return(
        <div className="noticia-container">
            <Container>
                <Row>
                    <Col sm={12} md={6} className="d-flex justify-content-center">
                        <CardMutavel/>
                    </Col>
                    <Col sm={12} md={6} className="d-flex justify-content-center">
                        <CardMutavel/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6} className="d-flex justify-content-center">
                        <CardMutavel/>
                    </Col>
                    <Col sm={12} md={6} className="d-flex justify-content-center">
                        <CardMutavel/>
                    </Col>
                </Row>
            </Container>
        </div>
        
    );*/
  return (
    <>
      <CardEquipe />
    </>
  )
}
