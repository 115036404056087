import './styles.css'

import { Button, CircularProgress, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { Container } from 'react-bootstrap'
import MarkdownEditor from '../../components/MarkdownEditor'
import PlataformaPadrao from '../../components/plataforma-padrao'
import api from '../../services/api'
import { useHistory } from 'react-router-dom'

export default function CadastroNoticia() {
  const history = useHistory()
  const [autores, setAutores] = useState('')
  const [revisores, setRevisores] = useState('')
  const [resumo, setResumo] = useState('')
  const [titulo, setTitulo] = useState('')
  const [conteudo, setConteudo] = useState('')
  const [fileCollection, setFileCollection] = useState([])
  // const [videoCollection, setVideoCollection] = useState([])
  const [video, setVideo] = useState('')
  const [tags, setTags] = useState('')
  var data = new Date().toLocaleString('pt-BR')
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [porcentagem, setPorcentagem] = useState(0)

  async function fileUpload() {
    let uploadError = false,
      filenamesArray = [],
      allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']
    if (!fileCollection.length) {
      alert('Selecione uma imagem antes de tentar fazer o upload.')
      return [uploadError, filenamesArray]
    }

    setLoadingUpload(true)

    const allowedFiles = fileCollection.filter((f) =>
      allowedTypes.includes(f.type)
    )

    if (allowedFiles.length !== fileCollection.length) {
      alert('Erro ao fazer upload, verifique o formato do arquivo.')
      setFileCollection([])
      uploadError = true
      return [uploadError, filenamesArray]
    }
    const formData = new FormData()
    allowedFiles.forEach((f) => formData.append('file', f))
    await api
      .post('/uploads', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        filenamesArray = res.data
      })

    return [uploadError, filenamesArray]
  }

  // async function videoUpload() {
  //   console.log('video upload ihuul')
  //   let videoUploadError = false,
  //     videoNamesArray = [],
  //     allowedTypes = [
  //       'video/3gpp',
  //       'video/mp4',
  //       'video/ogg',
  //       'video/quicktime',
  //       'video/webm',
  //       'video/x-ms-wmv',
  //       'video/x-msvideo',
  //     ]
  //   if (!videoCollection.length) {
  //     // alert('Selecione arquivos antes de tentar fazer o upload.')
  //     return [videoUploadError, videoNamesArray]
  //   }

  //   const allowedFiles = videoCollection.filter((f) =>
  //     allowedTypes.includes(f.type)
  //   )

  //   if (allowedFiles.length !== videoCollection.length) {
  //     alert('Erro ao fazer upload, verifique o formato do arquivo.')
  //     setVideoCollection([])
  //     videoUploadError = true
  //     return [videoUploadError, videoNamesArray]
  //   }
  //   const formData = new FormData()
  //   allowedFiles.forEach((f) => formData.append('file', f))
  //   console.log('aqui3')
  //   await api
  //     .post('/uploads', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //       onUploadProgress: (event) =>
  //         setPorcentagem(Math.round((100 * event.loaded) / event.total)),
  //     })
  //     .then((res) => {
  //       videoNamesArray = res.data
  //     })

  //   return [videoUploadError, videoNamesArray]
  // }
  async function uploadTag() {
    let tagsArray = tags.split(',')
    let existingTags = (await api.get('tags')).data

    //! RETONAR UMA ARRAY COM AS TAGS FORMATADAS NÃO CADASTRADAS AINDA !

    tagsArray = tagsArray.map((tagAtual) => {
      if (tagAtual != ' ' && tagAtual != '') {
        tagAtual = tagAtual.trim().toLowerCase()
        let flag = false
        for (let i = 0; i < existingTags.length; i++) {
          if (tagAtual == existingTags[i].tag) {
            flag = true
            break
          }
        }
        if (!flag) {
          return tagAtual
        }
      }
    })

    //! POST TAG !
    tagsArray.map(async (tag) => {
      await api.post('tags', { tag })
    })
  }

  async function handleCadastroNoticia(e) {
    e.preventDefault()
    const [uploadError, filenamesArray] = await fileUpload()
    // const [uploadVideoError, videoNamesArray] = await videoUpload()
    if (
      uploadError ||
      !filenamesArray.length
      // || uploadVideoError ||
      // !videoNamesArray.length
    ) {
      return
    } else {
      data = new Date()

      const dados = {
        autores: autores
          .split(',')
          .map((a) => a.trim())
          .join(', '),
        revisores: revisores
          .split(',')
          .map((a) => a.trim())
          .join(', '),
        resumo,
        data,
        titulo,
        conteudo,
        imagem: filenamesArray.join(','),
        video,
        tags: tags
          .split(',')
          .map((a) => a.trim())
          .join(',')
          .toLowerCase(),
      }

      try {
        const response = await api
          .post('noticias', dados)
          .then(setLoadingUpload(false))
        history.push('/blog')
        uploadTag()
        alert('Notícia cadastrada com sucesso!')
      } catch (err) {
        alert('Erro ao cadastrar notícia, tente novamente.')
      }
    }
  }

  return (
    <PlataformaPadrao>
      <p id='tituloprox'>Formulário para Cadastro de Notícia</p>
      <div id='noticiacadastro'>
        <Container className='conteudo-pagina container'>
          <form onSubmit={handleCadastroNoticia}>
            <section>
              <TextField
                name='autores'
                onChange={(e) => setAutores(e.target.value)}
                required
                className='textarea'
                id='outlined-basic'
                label='Autores'
                variant='outlined'
                helperText='Autores separados por vírgula'
              />
            </section>
            <section>
              <TextField
                name='revisores'
                onChange={(e) => setRevisores(e.target.value)}
                required
                className='textarea'
                id='outlined-basic'
                label='Revisores'
                variant='outlined'
                helperText='Revisores separados por vírgula'
              />
            </section>
            <section>
              <TextField
                name='resumo'
                onChange={(e) => setResumo(e.target.value)}
                required
                className='textarea'
                id='outlined-multiline-static'
                label='Resumo'
                multiline
                rows={4}
                variant='outlined'
                helperText='Um parágrafo que resume a notícia'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                name='titulo'
                onChange={(e) => setTitulo(e.target.value)}
                required
                id='outlined-basic'
                label='Título'
                variant='outlined'
              />
            </section>
            <section>
              <p style={{ fontSize: 20, fontWeight: 'bold' }}>
                Corpo da notícia:
              </p>
              <MarkdownEditor value={conteudo} setValue={setConteudo} />
            </section>
            <section>
              <TextField
                className='textarea'
                name='tags'
                onChange={(e) => setTags(e.target.value)}
                required
                id='outlined-basic'
                label='Tags'
                variant='outlined'
                helperText='Tags separadas por vírgula'
              />
            </section>
            <div id='inputfont'>
              <section>
                <p>Imagem*: </p>
                <input
                  accept='image/jpeg,image/jpg,image/png'
                  type='file'
                  onChange={(e) =>
                    setFileCollection(Array.from(e.target.files))
                  }
                />
                <p id='imagemObrigatorio'>
                  O arquivo de imagem é obrigatório.{' '}
                </p>
              </section>
              {/* <section>
                <p>Vídeo: </p>
                <input
                  accept="video/*"
                  type="file"
                  onChange={(e) => setVideoCollection(Array.from(e.target.files))}
                />
              </section> */}
              <section>
                <TextField
                  className='textarea'
                  name='video'
                  onChange={(e) => setVideo(e.target.value)}
                  id='outlined-basic'
                  label='Link Vídeo Youtube'
                  variant='outlined'
                />
              </section>
            </div>
            {loadingUpload && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
                <p>
                  Aguarde enquanto o upload está sendo feito. ({porcentagem}%)
                </p>
              </div>
            )}
            <div className='btn-send'>
              <Button
                onClick={handleCadastroNoticia}
                variant='contained'
                type='submit'
              >
                {' '}
                Cadastrar Notícia
              </Button>
            </div>
          </form>
        </Container>
      </div>
    </PlataformaPadrao>
  )
}
