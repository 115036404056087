import React, { useState, useEffect } from 'react'
import { Container, Form, Spinner } from 'react-bootstrap'
import './styles.css'
import api from '../../services/api'
import PlataformaPadrao from '../../components/plataforma-padrao'
import TextField from '@material-ui/core/TextField'
import { useParams } from 'react-router'

export default function FormularioSAEPreenchido() {
  let { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [sae, setSae] = useState({})
  const [paciente, setPaciente] = useState([])
  const [pesquisador, setPesquisador] = useState([])
  const [first, setFirst] = useState(true)
  const legenda = `Consulta de ${paciente.nome}, preenchido por ${pesquisador.nome}`

  useEffect(() => {
    api.get(`consulta/${id}`).then((response) => {
      setSae(response.data)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }
  if (first) {
    setFirst(false)
    async function getPacientePesquisador() {
      const response1 = await api.get(`global/${sae.id_paciente}`)
      setPaciente(response1.data)
      const response2 = await api.get(`global/${sae.id_pesquisador}`)
      setPesquisador(response2.data)
    }
    getPacientePesquisador()
  }

  //só retorna a página em si quando o loading acaba (quando os dados são carregados)
  return (
    <PlataformaPadrao title="Formulário Preenchido - SAE" legend={legenda}>
      <Container>
        <section>
          <TextField
            className="textarea"
            label="Acompanhado por:"
            disabled
            id="outlined-disabled"
            defaultValue={sae.acompanhante}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Queixa principal"
            disabled
            id="outlined-disabled"
            defaultValue={sae.queixa}
            variant="outlined"
          />
        </section>
        <section className="campo-texto">
          <h5>Histórico de Enfermagem em Uropediatria</h5>
          {sae.historico}
        </section>
        <section className="campo-texto">
          <h5>Avaliação do Sistema Urinário</h5>
          {sae.avaliacao_urinario}
        </section>
        <section className="campo-texto">
          <h5>Avaliação do Sistema Intestinal</h5>
          {sae.avaliacao_intestinal}
        </section>
        <section className="campo-texto">
          <h5>Exame Físico</h5>
          {sae.exame_fisico}
        </section>
        <section className="campo-texto">
          <h5>Diagnóstico de Enfermagem em Uropediatria</h5>
          {sae.diagnostico}
        </section>
        <section className="campo-texto">
          <h5>Intervenções de Enfermagem em Uropediatria</h5>
          {sae.intervencoes}
        </section>
        <section className="campo-texto">
          <h5>Resultados Esperados</h5>
          {sae.resultados_esperados}
        </section>
        {/* <section>
          <TextField
            className="textarea"
            label="Data da Próxima Consulta"
            disabled
            id="outlined-disabled"
            defaultValue={sae.data_proxima_consulta}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Número de Acompanhamento"
            disabled
            id="outlined-disabled"
            defaultValue={sae.num_acompanhamento}
            variant="outlined"
          />
        </section> */}
      </Container>
    </PlataformaPadrao>
  )
}
