import './styles.css'

import React, { useEffect, useState } from 'react'

import Axios from 'axios'
import ItemListaNovoPaciente from '../../components/ItemListaNovoPaciente'
import PlataformaPadrao from '../../components/plataforma-padrao'
import Table from 'react-bootstrap/Table'
import api from '../../services/api'
import { useParams } from 'react-router'

export default function ListaNovoPaciente() {
  let { id } = useParams()

  const [pesquisador, setPesquisador] = useState([])
  const [dados_pacientes, setDadosPacientes] = useState([])
  const [horariosConsultas, setHorariosConsultas] = useState([])

  async function refreshList() {
    //! Primeiro pegue as informações do pesquisador
    const pesquisadorAtual = await api
      .get(`pesquisador/${id}`)
      .then(({ data }) => data)
      .catch(console.log)

    //! Salve somente os pacientes não aceitos
    let pacientes_do_pesquisador = pesquisadorAtual.id_paciente
      .toString()
      .split(',')

    pacientes_do_pesquisador =
      pacientes_do_pesquisador.length > pesquisadorAtual.quantidade_pacientes
        ? pacientes_do_pesquisador.slice(
            pesquisadorAtual.quantidade_pacientes,
            pacientes_do_pesquisador.length
          )
        : []

    //! Recupere e salve as informações de cada paciente não aceito
    const dadosPacientes = await Axios.all(
      pacientes_do_pesquisador.map((id_paciente) =>
        api
          .get(`global/${id_paciente}`)
          .then(({ data }) => data)
          .catch(console.log)
      )
    )

    //! Recupere as informações de consulta de cada paciente não aceito
    const auxHorariosConsultas = await Axios.all(
      pacientes_do_pesquisador.map((id_paciente) =>
        api
          .get(`proxima_consulta/${id_paciente}`)
          .then(({ data }) => data)
          .catch(console.log)
      )
    )

    //! Salve os estados
    setPesquisador(pesquisadorAtual)
    setHorariosConsultas(auxHorariosConsultas)
    setDadosPacientes(dadosPacientes)
  }

  useEffect(refreshList, [])

  return (
    <>
      <PlataformaPadrao title='Novos Pacientes'>
        <p id='aceitarcn'>Aceitar ou Recusar consultas</p>

        <div className='lista-geral-table-wrapper'>
          <Table>
            <thead>
              <th>
                <div>NOME</div>
              </th>
              <th>
                <div>E-MAIL</div>
              </th>
              <th>
                <div>ID</div>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              {dados_pacientes.map((_, idx) => (
                <ItemListaNovoPaciente
                  key={idx}
                  paciente={dados_pacientes[idx]}
                  consulta={horariosConsultas[idx]}
                  pesquisador={pesquisador}
                />
              ))}
            </tbody>
          </Table>
        </div>
      </PlataformaPadrao>
    </>
  )
}
