import './styles.css'

import { Form, Spinner } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import ItemListaFamilia from '../../components/ItemListaFamilia'
import PlataformaPadrao from '../../components/plataforma-padrao'
import Table from 'react-bootstrap/Table'
import TextField from '@material-ui/core/TextField'
import api from '../../services/api'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'

export default function ListaPaciente() {
  const [paciente, setPaciente] = useState([])
  const [globalPaciente, setGlobalPaciente] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getPacientes() {
      const { data } = await api.get('paciente')
      if (data) {
        setPaciente(
          data.sort((a, b) => Number(a.id_global) - Number(b.id_global))
        )
      }
    }

    getPacientes()
  }, [])

  useEffect(() => {
    async function getGlobalPaciente() {
      const responses = await axios.all(
        paciente.map((paciente) => api.get(`global/${paciente.id_global}`))
      )
      if (responses) {
        setGlobalPaciente(responses.map((r) => r.data))
      }
      setLoading(false)
    }

    if (!paciente.length) return
    getGlobalPaciente()
  }, [paciente])

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <PlataformaPadrao title='Pacientes'>
        <p id='aceitarcn'>Lista de pacientes cadastrados</p>
        <div className='lista-geral-table-wrapper'>
          <Table>
            <thead>
              <th>
                <div>NOME</div>
              </th>
              <th>
                <div>E-MAIL</div>
              </th>
              <th>
                <div>TELEFONE</div>
              </th>
              <th>
                <div>PROX. CONSULTA</div>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              {!loading &&
                paciente.map((c, i) => {
                  return (
                    <ItemListaFamilia
                      excluir={false}
                      editar={false}
                      olhar={true}
                      paciente={paciente[i]}
                      globalPaciente={globalPaciente[i]}
                    />
                  )
                })}
            </tbody>
          </Table>
        </div>
      </PlataformaPadrao>
    </>
  )
}
