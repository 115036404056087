import React, { useRef } from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import './styles.css'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  botao: {
    backgroundColor: '#F54C4C',
  },
}))

export default function CadastroPadrao(props) {
  const classes = useStyles()
  const formRef = useRef()

  return (
    <div className="cadastro-padrao-container">
      <div className="lateral-esquerda-login-voltar">
        <Link className="voltar-site-link" to="/">
          <h4 className="voltar-site">
            <ArrowBackIcon style={{ fontSize: 30, marginRight: 5 }} />
            Voltar ao site
          </h4>
        </Link>
      </div>
      <div className="lateral-esquerda-login">
        <h1 className="titulo-lateral">
          PARA ADENTRAR À PLATAFORMA ENF-UROPED, PRECISAMOS DE ALGUNS DE SEUS DADOS
          PESSOAIS. VAMOS LÁ?
        </h1>
        <h4 className="descricao-opcional-lateral">
          Basta preencher os campos ao lado
          <ArrowForwardIcon style={{ fontSize: 35, marginLeft: 20 }} />
        </h4>
      </div>
      <div className="lateral-direita-login">
        <h1 className="titulo-login">{props.title}</h1>
        <form
          ref={formRef}
          className={props.className}
          onSubmit={props.onSubmit}
        >
          {props.children}
          <div className="botoes-entrada">
            <Button
              onClick={(e) => {
                if (formRef.current.reportValidity()) {
                  props.onSubmit(e)
                }
              }}
              className={classes.botao}
              variant="contained"
              color="secondary"
              type="submit"
            >
              cadastrar
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
