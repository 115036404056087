import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import './styles.css'
import api from '../../services/api'
import PlataformaPadrao from '../../components/plataforma-padrao'
import { Form, Container, Spinner } from 'react-bootstrap'

export default function CadastroFamiliaPreenchido() {
  const [loading, setLoading] = useState(true)
  const [paciente, setPaciente] = useState({})
  const [global, setGlobal] = useState({})

  useEffect(() => {
    //faz as duas requisições juntas
    Promise.all([api.get('paciente/1'), api.get('global/1')]).then(
      ([pacienteResponse, globalResponse]) => {
        setPaciente(pacienteResponse.data)
        setGlobal(globalResponse.data)
        setLoading(false)
      }
    )
  }, [])

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  //só retorna a página em si quando o loading acaba (quando os dados são carregados)
  return (
    <PlataformaPadrao title="CADASTRO PACIENTE PREENCHIDO">
      <Container>
        <section>
          <TextField
            className="textarea"
            label="Nome Completo do Paciente"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.nome}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Nome Completo do Responsável"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.nome_responsavel}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Telefone para contato 1"
            disabled
            id="outlined-disabled"
            defaultValue={global.telefone1}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Telefone para contato 2"
            disabled
            id="outlined-disabled"
            defaultValue={global.telefone2}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Cidade/Estado"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.cidade_estado}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="E-mail do Responsável"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.email_responsavel}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Data de Nascimento do Paciente"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.data_nascimento}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Data de Nascimento do Responsável"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.data_nascimento_responsavel}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Sexo"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.sexo}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Altura do Paciente (em centímetros)"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.altura}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Peso do Paciente (em quilogramas)"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.peso}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Escola do Paciente"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.escola}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="CPF do Paciente"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.cpf}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="RG do Paciente"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.rg}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Grau de Parentesco entre Responsável e Paciente"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.parentesco}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="CPF do Responsável"
            disabled
            id="outlined-disabled"
            defaultValue={paciente.cpf_responsavel}
            variant="outlined"
          />
        </section>
      </Container>
    </PlataformaPadrao>
  )
}
