import './styles.css'

import { Container, Spinner } from 'react-bootstrap'
import { FiEye, FiTrash2 } from 'react-icons/fi'
import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import { FaRegEdit } from 'react-icons/fa'
import { Form } from 'react-bootstrap'
import FormularioPreenchidoPostagemEditavel from '../FormularioPreenchidoPostagemEditavel'
import MarkdownEditor from '../MarkdownEditor'
import Modal from 'react-bootstrap/Modal'
import TextField from '@material-ui/core/TextField'
import api from '../../services/api'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    justifyContent: 'center',
    color: '#000000',
  },
  botao: {
    backgroundColor: '#F54C4C',
  },
  botaosecundario: {
    color: '#F54C4C',
  },
}))

export default function ItemListaFamilia({ postagem }) {
  const classes = useStyles()
  const history = useHistory()
  const [showEditar, setShowEditar] = useState(false)
  const [showDeletar, setShowDeletar] = useState(false)
  const [id_noticia, setIdnoticia] = useState(postagem.id_noticia)
  const [autores, setAutores] = useState(postagem.autores)
  const [revisores, setRevisores] = useState(postagem.revisores)
  const [resumo, setResumo] = useState(postagem.resumo)
  const [data, setData] = useState(postagem.data)
  const [titulo, setTitulo] = useState(postagem.titulo)
  const [conteudo, setConteudo] = useState(postagem.conteudo)
  const [imagem, setImagem] = useState(postagem.imagem)
  const [tags, setTags] = useState(postagem.tags)
  const [video, setVideo] = useState(postagem.video)

  const [fileCollection, setFileCollection] = useState([])
  // const [videoCollection, setVideoCollection] = useState([])
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [porcentagem, setPorcentagem] = useState(0)

  const handleCloseEditar = () => setShowEditar(false)
  const handleShowEditar = () => setShowEditar(true)
  const handleCloseDeletar = () => setShowDeletar(false)
  const handleShowDeletar = () => setShowDeletar(true)

  async function handleEdicao(f) {
    f.preventDefault()

    let [uploadError, filenamesArray] = [false, '']
    // let [uploadVideoError, videoNamesArray] = [false, '']

    fileCollection.length == 0
      ? (filenamesArray = imagem)
      : ([uploadError, filenamesArray] = await fileUpload())

    // videoCollection.length == 0
    //   ? (videoNamesArray = video)
    //   : ([uploadVideoError, videoNamesArray] = await videoUpload())

    // if (uploadError || uploadVideoError) {
    //   return
    // }

    if (uploadError) {
      return
    }

    const dados = {
      autores: autores
        .split(',')
        .map((a) => a.trim())
        .join(', '),
      revisores: revisores
        .split(',')
        .map((a) => a.trim())
        .join(', '),
      resumo,
      titulo,
      conteudo,
      imagem: filenamesArray,
      video,
      tags: tags
        .split(',')
        .map((a) => a.trim())
        .join(',')
        .toLowerCase(),
    }

    try {
      await api
        .put(`/noticias/${id_noticia}`, dados)
        .then(setLoadingUpload(false))
      setShowEditar(false)
      alert('Postagem editada com sucesso')
      history.go(0)
    } catch (err) {
      alert('Erro ao editar. Tente Novamente.')
    }
  }

  async function handleExclusao() {
    try {
      const response1 = await api.delete(`/noticias/${id_noticia}`)
      setShowDeletar(false)
      alert('Postagem excluída com sucesso')
      history.go(0)
    } catch (err) {
      alert('Erro ao deletar. Tente Novamente.')
    }
  }

  async function fileUpload() {
    let uploadError = false,
      filenamesArray = [],
      allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']

    if (fileCollection.length == 0) {
      return [uploadError, filenamesArray]
    }

    const allowedFiles = fileCollection.filter((f) =>
      allowedTypes.includes(f.type)
    )

    if (allowedFiles.length !== fileCollection.length) {
      alert('Erro ao fazer upload, verifique o formato do arquivo.')
      setFileCollection([])
      uploadError = true
      return [uploadError, filenamesArray]
    }
    const formData = new FormData()
    allowedFiles.forEach((f) => formData.append('file', f))
    await api
      .post('/uploads', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        filenamesArray = res.data
      })

    return [uploadError, filenamesArray]
  }

  // async function videoUpload() {
  //   setLoadingUpload(true)
  //   let videoUploadError = false,
  //     videoNamesArray = [],
  //     allowedTypes = [
  //       'video/3gpp',
  //       'video/mp4',
  //       'video/ogg',
  //       'video/quicktime',
  //       'video/webm',
  //       'video/x-ms-wmv',
  //       'video/x-msvideo',
  //     ]
  //   if (videoCollection.length == 0) {
  //     // alert('Selecione arquivos antes de tentar fazer o upload.')
  //     return [videoUploadError, videoNamesArray]
  //   }

  //   const allowedFiles = videoCollection.filter((f) =>
  //     allowedTypes.includes(f.type)
  //   )

  //   if (allowedFiles.length !== videoCollection.length) {
  //     alert('Erro ao fazer upload, verifique o formato do arquivo.')
  //     setVideoCollection([])
  //     videoUploadError = true
  //     return [videoUploadError, videoNamesArray]
  //   }
  //   const formData = new FormData()
  //   allowedFiles.forEach((f) => formData.append('file', f))
  //   console.log('aqui3')
  //   await api
  //     .post('/uploads', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //       onUploadProgress: (event) =>
  //         setPorcentagem(Math.round((100 * event.loaded) / event.total)),
  //     })
  //     .then((res) => {
  //       videoNamesArray = res.data
  //     })

  //   return [videoUploadError, videoNamesArray]
  // }

  return (
    <>
      <tr>
        {/* <div className="itens-da-linha">*/}
        <td>{postagem.titulo}</td>
        <td>{new Date(postagem.data).toLocaleString('pt-BR')}</td>
        <td>{postagem.tags.split(',').join(', ')}</td>
        {/* </div>*/}
        {/*<div className="botoes-lista">*/}
        <td>
          {' '}
          <Button variant='primary' onClick={handleShowEditar}>
            EDITAR
          </Button>
        </td>
        <td>
          <Button variant='primary' onClick={handleShowDeletar}>
            EXCLUIR
          </Button>
        </td>
        {/* </div>} */}
      </tr>

      {/* Modal de editar dados */}
      <Modal show={showEditar} onHide={handleCloseEditar}>
        <Modal.Header closeButton>
          <Modal.Title className='edtpost'>
            Editando {postagem.titulo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form onSubmit={handleEdicao}>
              <section>
                <TextField
                  className='textarea'
                  id='outlined'
                  defaultValue={postagem.autores}
                  onChange={(f) => setAutores(f.target.value)}
                  variant='outlined'
                  label='Autores'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  id='outlined'
                  defaultValue={postagem.titulo}
                  onChange={(f) => setTitulo(f.target.value)}
                  variant='outlined'
                  label='Titulo'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  id='outlined'
                  defaultValue={postagem.resumo}
                  onChange={(f) => setResumo(f.target.value)}
                  variant='outlined'
                  label='Resumo'
                  multiline
                  rowsMax='10'
                />
              </section>
              <section>
                <MarkdownEditor value={conteudo} setValue={setConteudo} />
              </section>
              <section>
                <TextField
                  className='textarea'
                  id='outlined'
                  defaultValue={postagem.tags.split(',').join(', ')}
                  onChange={(f) => setTags(f.target.value)}
                  variant='outlined'
                  label='Tags'
                  multiline
                  rowsMax='3'
                />
              </section>
              <div id='inputfont'>
                <section>
                  <p>Imagem: </p>
                  <input
                    accept='image/jpeg,image/jpg,image/png'
                    type='file'
                    onChange={(e) =>
                      setFileCollection(Array.from(e.target.files))
                    }
                  />
                  {/*} <p id="imagemObrigatorio">O arquivo de imagem é obrigatório. </p>*/}
                </section>
                <section>
                  <TextField
                    className='textarea'
                    value={video}
                    name='video'
                    onChange={(e) => setVideo(e.target.value)}
                    id='outlined-basic'
                    label='Link Vídeo Youtube'
                    variant='outlined'
                  />
                </section>
                {/* <section>
                  <p>Vídeo: </p>
                  <input
                    accept='video/*'
                    type='file'
                    onChange={(e) =>
                      setVideoCollection(Array.from(e.target.files))
                    }
                  />
                </section> */}
              </div>
              {loadingUpload && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress />
                  <p>
                    Aguarde enquanto o upload está sendo feito. ({porcentagem}%)
                  </p>
                </div>
              )}
              <div className='btn-send'>
                <Button variant='contained' type='submit'>
                  Salvar Mudanças
                </Button>
              </div>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseEditar}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de deletar */}
      <Modal show={showDeletar} onHide={handleCloseDeletar}>
        <Modal.Header closeButton>
          <Modal.Title className='delpost'>
            Excluindo {postagem.titulo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='delpost'>
          Tem certeza que deseja excluir {postagem.titulo}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseDeletar}>
            FECHAR
          </Button>
          <Button
            style={{ color: 'red' }}
            variant='primary'
            onClick={handleExclusao}
          >
            EXCLUIR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
