import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import './styles.css'
import { BsFillPeopleFill, BsFillPersonCheckFill, BsPersonCheckFill } from 'react-icons/bs'
import { FaCalendarAlt } from 'react-icons/fa'
import api from '../../services/api'
import { Form, Spinner } from 'react-bootstrap'
import ItemListaPesquisadorAutenticar from '../../components/ItemListaPesquisadorAutenticar'
import ItemListaEstudanteAutenticar from '../../components/ItemListaEstudanteAutenticar'
import ItemListaPacientesEspecificos from '../../components/ItemListaPacientesEspecificos'
import PlataformaPadrao from '../../components/plataforma-padrao'
import { UserContext } from '../../contexts/user.js'

export default function PrincipalPesquisador() {
  const { user } = useContext(UserContext)
  let id = user.id_global
  //const [loading, setLoading] = useState(true);
  const [estudante, setEstudante] = useState([])
  const [globalEstudante, setGlobalEstudante] = useState([])
  const [pesquisador, setPesquisador] = useState([])
  const [globalPesquisador, setGlobalPesquisador] = useState([])
  const [diahoraproximaconsulta, setDiahoraproximaconsulta] = useState(
    '05/05/2021 08:00'
  )
  const [profissionalresponsavel, setProfissionalresponsavel] = useState(
    'Gisele Martins'
  )
  const [first, setFirst] = useState(true)
  const [second, setSecond] = useState(true)
  const [third, setThird] = useState(true)
  let pacientes_especificos = `/lista-pacientes-especificos/${id}`
  let novos_pacientes_especificos = `/lista-novo-paciente/${id}`
  let disponibilidade_especifico = `/edicao-disponibilidade/${id}`

  if (first) {
    setFirst(false)
    async function getPesquisadorEstudanteG() {
      //const response1 = await api.get(`autenticacao/2`)
      const response1 = await api.get(`autenticacao`)
      setGlobalPesquisador(response1.data)
      // const response2 = await api.get(`autenticacao/3`)
      const response2 = await api.get(`autenticacao`)
      setGlobalEstudante(response2.data)
    }
    getPesquisadorEstudanteG()
  }
  return (
    <>
      <PlataformaPadrao
        title="Página principal da(o) enfermeira(o)"
        legend="Página inicial para navegação na plataforma"
      >
        <Link className="card-meus-pacientes" to={pacientes_especificos}>
          <div className="titulo-card-meus-pacientes"><BsFillPeopleFill />  MEUS PACIENTES</div>
          <div className="descricao-card-meus-pacientes">
            » Acompanhamento das famílias
          </div>
        </Link>
        <Link
          className="card-link-calendario-hora"
          to={disponibilidade_especifico}
        >
          <div className="titulo-card-link-calendario-hora">
            <FaCalendarAlt />  CALENDÁRIO E HORA
          </div>
          <div className="descricao-card-link-calendario-hora">
            » Atualizar disponibilidade
          </div>
        </Link>
        <Link className="card-aceitar-recusar" to={novos_pacientes_especificos}>
          <div className="titulo-card-aceitar-recusar">
            Aceitar/Recusar novas consultas
          </div>
        </Link>
      </PlataformaPadrao>
    </>
  )
}
