import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import './styles.css'

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
  },
})

export default function CardNoticia({ imagem, titulo, resumo, link }) {
  const classes = useStyles()

  return (
    <div className="card-container">
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            component="img"
            alt="teste"
            height="140"
            image={imagem}
            title="teste"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {titulo}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {resumo}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary">
            Botão
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}
