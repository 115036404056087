import React, { useState } from 'react'
import { Container, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import './styles.css'
import { Button } from '@material-ui/core'
import api from '../../services/api'
import { TextField } from '@material-ui/core'
import PlataformaPadrao from '../../components/plataforma-padrao'
import { Alert } from '@material-ui/lab'

export default function MensagemForum() {
  const history = useHistory()

  const [id_forum, setIdForum] = useState(1)
  const [autor, setAutor] = useState('autor')
  const [anexo, setAnexo] = useState('')
  const [conteudo, setConteudo] = useState('')
  const [titulo, setTitulo] = useState('')
  const [nome, setNome] = useState('nome')
  const [id_resposta, setIdResposta] = useState(1)
  const [fileCollection, setFileCollection] = useState([])

  async function fileUpload() {
    let uploadError = false,
      filenamesArray = [],
      allowedTypes = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'video/3gpp',
        'video/mp4',
        'video/ogg',
        'video/quicktime',
        'video/webm',
        'video/x-ms-wmv',
        'video/x-msvideo',
      ]
    if (!fileCollection.length) {
      alert('Selecione arquivos antes de tentar fazer o upload.')
      return [uploadError, filenamesArray]
    }

    const allowedFiles = fileCollection.filter((f) =>
      allowedTypes.includes(f.type)
    )

    if (allowedFiles.length !== fileCollection.length) {
      alert('Erro ao fazer upload, verifique o formato do arquivo.')
      setFileCollection([])
      uploadError = true
      return [uploadError, filenamesArray]
    }
    const formData = new FormData()
    allowedFiles.forEach((f) => formData.append('file', f))
    await api
      .post('/uploads', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        filenamesArray = res.data
      })

    return [uploadError, filenamesArray]
  }

  async function handleCadastro(e) {
    e.preventDefault()
    const [uploadError, filename] = await fileUpload()

    if (uploadError || !filename.length) {
      return
    } else {
      const hora_data = new Date()

      const data = {
        autor,
        id_forum,
        nome,
        titulo,
        conteudo,
        hora_data,
        id_resposta,
        anexo: filename,
      }

      try {
        await api.post('mensagem_forum', data)
        alert('Mensagem enviada com sucesso.')
        history.go(0)
      } catch (err) {
        alert('Erro ao Enviar Mensagem. Tente Novamente.')
      }
    }
  }

  return (
    <PlataformaPadrao
      title="Nova mensagem no Fórum"
      legend="Preencha os campos necessários para enviar sua mensagem ao fórum."
    >
      <Container>
        {id_resposta !== 0 && (
          <Alert className="sinal-resposta" severity="info">
            Essa mensagem é resposta da mensagem de id {id_resposta}
          </Alert>
        )}
        <Form onSubmit={handleCadastro}>
          <section>
            <TextField
              className="textarea"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
              required
              id="outlined-basic"
              label="Título"
              variant="outlined"
            />
          </section>
          <section>
            <TextField
              className="textarea"
              value={conteudo}
              onChange={(e) => setConteudo(e.target.value)}
              required
              id="outlined-basic"
              label="Conteúdo/Mensagem"
              variant="outlined"
              multiline
              rows={16}
            />
          </section>
          <section>
            <p>Anexo: </p>
            <input
              accept="image/jpeg,image/jpg,image/png"
              type="file"
              onChange={(e) => setFileCollection(Array.from(e.target.files))}
            />
          </section>
          <div className="btn-send">
            <Button variant="contained" type="submit">
              {' '}
              Enviar Mensagem
            </Button>
          </div>
        </Form>
      </Container>
    </PlataformaPadrao>
  )
}
