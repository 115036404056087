import React from 'react'
import SideBar from '../SideBar'
import Navbarplataforma from '../Navbarplataforma'
import { Container } from 'react-bootstrap'
import './styles.css'

export default function PlataformaPadrao(props) {
  return (
    <div className="body-wrapper">
      <SideBar />
      <div className="content-wrapper">
        <Navbarplataforma />
        <div className="pagina">
          <div className="titulo-pagina">{props.title}</div>
          <div className="estilo-titulo"></div>
          <Container className="conteudo-pagina container">
            <p>{props.legend}</p>
            {props.children}
          </Container>
        </div>
      </div>
    </div>
  )
}
