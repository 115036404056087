import React from 'react'
import './styles.css'
import Logo from '../../assets/logo.png'
import { Link } from 'react-router-dom'
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from 'mdbreact'

export default function FooterBlog() {
  return (
    <>
      <MDBFooter color="blue lighten-1" className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="4" className="coluna_footer">
            <ul>
              <li className="list-unstyled">
                <Link to="/" className="footer-links">
                  Página inicial
                </Link>
              </li>
              <li className="list-unstyled">
                <Link to="/login" className="footer-links">
                  Login/Cadastro
                </Link>
              </li>
            </ul>
          </MDBCol>
          <MDBCol md="4" className="coluna_footer">
            <Link to="#" className="footer-logo coluna_footer">
              <img alt="" src={Logo} width="60" height="60" />
              <Link to="/" className="footer-slogan coluna_footer">
                {/*<br />
                Projeto de Extensão de Ação Contínua:*/}
                <span>Prática Avançada de Enfermagem</span><span>em Uropediatria</span> 
              </Link>
              <Link to="/" className="footer-slogan-reserva">
                <br />
                Prática Avançada de
                Enfermagem em Uropediatria
              </Link>
            </Link>
          </MDBCol>
          <MDBCol md="4" className="redes-sociais coluna_footer">
            <ul>
              <li className="item-rede-social">
                <a
                  href="https://www.youtube.com/channel/UCfR81DfVkZ2MhhEWmMtY0ZQ"
                  target="_blank"
                >
                  <i className="fab fa-youtube-square"></i>
                </a>
              </li>
              <li className="item-rede-social">
                <a href="https://www.facebook.com/PAEUroped/" target="_blank">
                  <i className="fab fa-facebook-square"></i>
                </a>
              </li>
              <li className="item-rede-social">
                <a href="https://twitter.com/enfuroped" target="_blank">
                  <i className="fab fa-twitter-square"></i>
                </a>
              </li>
              <li className="item-rede-social">
                <a href="https://www.instagram.com/enfuroped/" target="_blank">
                  <i className="fab fa-instagram-square"></i>
                </a>
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3 copyright">
        <MDBContainer>
          &copy; {new Date().getFullYear()} Copyright: Todos os direitos
          reservados.
          <br />
          Site por{' '}
          <a target="_blank" href="https://engnetconsultoria.com.br/">
            EngNet Consultoria
          </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  
    </>
  )
}
