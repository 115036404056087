import { FiTrash2, FiEye } from 'react-icons/fi'
import { FaRegEdit } from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal'
import api from '../../services/api'
import './styles.css'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Form } from 'react-bootstrap'
import { Container, Spinner } from 'react-bootstrap'
import FormularioPreenchidoEstudante from '../FormularioPreenchidoEstudante'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    justifyContent: 'center',
    color: '#000000',
  },
  botao: {
    backgroundColor: '#F54C4C',
  },
  botaosecundario: {
    color: '#F54C4C',
  },
}))

export default function ItemListaEstudante({
  excluir,
  editar,
  olhar,
  estudante,
  globalEstudante,
}) {
  const classes = useStyles()
  const history = useHistory()

  const [showVer, setShowVer] = useState(false)
  const [showDeletar, setShowDeletar] = useState(false)

  const handleCloseVer = () => setShowVer(false)
  const handleShowVer = () => setShowVer(true)
  const handleCloseDeletar = () => setShowDeletar(false)
  const handleShowDeletar = () => setShowDeletar(true)

  const [loading_animation, setLoading_animation] = useState(false)

  //Cadastro global
  const [nome, setNome] = useState(globalEstudante.nome)
  const [data_nascimento, setDataNascimento] = useState(
    globalEstudante.data_nascimento
  )
  const [email, setEmail] = useState(globalEstudante.email)
  const [cpf, setCpf] = useState(globalEstudante.cpf)
  const [senha, setSenha] = useState(globalEstudante.senha)
  const [telefone1, setTelefone1] = useState(globalEstudante.telefone1)
  const [telefone2, setTelefone2] = useState(globalEstudante.telefone2)
  const [sexo, setSexo] = useState(globalEstudante.sexo) //começa com Feminino pois é a primeira opção do select no front
  const [tipo, setTipo] = useState(globalEstudante.tipo)
  const [autenticado, setAutenticado] = useState(
    globalEstudante.autenticado_pelo_admin
  )

  //Cadastro Estudante
  const [id_global, setIdglobal] = useState(estudante.id_global)
  const [rg, setRg] = useState(estudante.rg)
  const [instituicao, setInstituicao] = useState(estudante.instituicao)
  const [matricula, setMatricula] = useState(estudante.matricula)
  const [semestre, setSemestre] = useState(estudante.semestre)
  const [acesso_cadastros, setAcessocadastros] = useState(
    estudante.acesso_cadastros
  )
  const [acesso_blog, setAcessoblog] = useState(estudante.acesso_blog)

  async function handleAutenticar() {
    try {
      const response = await api.put(`autenticacao/${estudante.id_global}`)
      const dados= {
        nome_destinatario: nome,
        endereco_email: email,
        assunto_do_email: "Você foi autenticado na Plataforma Enf-UROPED",
        texto_do_email: "Você já pode acessar a plataforma, seu cadastro foi aprovado!"
      }
      setLoading_animation(true)
      const response3 = await api.post(`send_mail`,dados)
      setShowVer(false)
      history.go(0)
    } catch (err) {
      alert('Erro ao deletar. Tente Novamente.')
    }
  }
  async function handleExclusao() {
    try {
      const response1 = await api.delete(`/alunos_graduacao/${id_global}`)
      const response2 = await api.delete(`/global/${id_global}`)
      setShowDeletar(false)
      history.go(0)
    } catch (err) {
      alert('Erro ao deletar. Tente Novamente.')
    }
  }

  const handleReenviar = async () => {
    setLoading_animation(true)
    await api
      .post('/validar-email/', { email: globalEstudante.email })
      .catch(alert)
  }

  return (
    <>
      <tr>
        {/* <div className="itens-da-linha">*/}
        <td>{globalEstudante.nome}</td>
        <td>{globalEstudante.email}</td>
        <td>{globalEstudante.telefone1}</td>
        {/* </div>*/}
        {/*<div className="botoes-lista">*/}
        <td>
          {' '}
          <Button variant="primary" onClick={handleShowVer}>
            AUTENTICAR
          </Button>
        </td>
        <td>
          <Button variant="primary" onClick={handleShowDeletar}>
            EXCLUIR
          </Button>
        </td>
        {/* </div>} */}
      </tr>

      {/* Modal de visualizar dados */}
      <Modal show={showVer} onHide={handleCloseVer}>
        <Modal.Header closeButton>
          <Modal.Title>Dados de {globalEstudante.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={estudante.nome}
                variant="outlined"
                label="Nome Completo"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={globalEstudante.telefone1}
                variant="outlined"
                label="Telefone para Contato 1"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={globalEstudante.telefone2}
                variant="outlined"
                label="Telefone para Contato 2"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={globalEstudante.email}
                variant="outlined"
                label="E-mail"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={estudante.data_nascimento}
                variant="outlined"
                label="Data de Nascimento"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={estudante.sexo}
                variant="outlined"
                label="Sexo"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={estudante.instituicao}
                variant="outlined"
                label="Instituição onde Estuda"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={estudante.matricula}
                variant="outlined"
                label="Número de Matrícula"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={estudante.semestre}
                variant="outlined"
                label="Semestre Atual"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={estudante.cpf}
                variant="outlined"
                label="CPF"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                disabled
                id="outlined-disabled"
                defaultValue={estudante.rg}
                variant="outlined"
                label="RG"
              />
            </section>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseVer}>
            Fechar
          </Button>
          {globalEstudante.email_validado ? (
            <Button
              style={{ color: 'green' }}
              variant="primary"
              onClick={handleAutenticar}
            >
              Autenticar
            </Button>
          ) : (
            <Button
              style={{ color: 'red' }}
              variant="primary"
              onClick={handleReenviar}
            >
              Email não confirmado... Reenviar?
            </Button>
          )}
          {loading_animation && (
              <Spinner animation="border" />
            )
            }
        </Modal.Footer>
      </Modal>

      {/* Modal de deletar */}
      <Modal show={showDeletar} onHide={handleCloseDeletar}>
        <Modal.Header closeButton>
          <Modal.Title>Excluindo {globalEstudante.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja excluir {globalEstudante.nome}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeletar}>
            FECHAR
          </Button>
          <Button
            style={{ color: 'red' }}
            variant="primary"
            onClick={handleExclusao}
          >
            EXCLUIR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
