import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import './styles.css'
import api from '../../services/api'
import PlataformaPadrao from '../../components/plataforma-padrao'
import { Form, Container, Spinner } from 'react-bootstrap'

export default function CadastroPesquisadorPreenchido() {
  const [loading, setLoading] = useState(true)
  const [pesquisador, setPesquisador] = useState({})
  const [global, setGlobal] = useState({})

  useEffect(() => {
    //faz as duas requisições juntas
    Promise.all([api.get('pesquisador/1'), api.get('global/1')]).then(
      ([pesquisadorResponse, globalResponse]) => {
        setPesquisador(pesquisadorResponse.data)
        setGlobal(globalResponse.data)
        setLoading(false)
      }
    )
  }, [])

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  //só retorna a página em si quando o loading acaba (quando os dados são carregados)
  return (
    <PlataformaPadrao title="CADASTRO PESQUISADOR PREENCHIDO">
      <Container>
        <section>
          <TextField
            className="textarea"
            label="Nome Completo"
            disabled
            id="outlined-disabled"
            defaultValue={pesquisador.nome}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Telefone para contato 1"
            disabled
            id="outlined-disabled"
            defaultValue={global.telefone1}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Telefone para contato 2"
            disabled
            id="outlined-disabled"
            defaultValue={global.telefone2}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="E-mail"
            disabled
            id="outlined-disabled"
            defaultValue={global.email}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Data de Nascimento"
            disabled
            id="outlined-disabled"
            defaultValue={pesquisador.data_nascimento}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Sexo"
            disabled
            id="outlined-disabled"
            defaultValue={pesquisador.sexo}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Nível de formação"
            disabled
            id="outlined-disabled"
            defaultValue={pesquisador.nivel_formacao}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="Coren"
            disabled
            id="outlined-disabled"
            defaultValue={pesquisador.coren}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="CPF"
            disabled
            id="outlined-disabled"
            defaultValue={pesquisador.cpf}
            variant="outlined"
          />
        </section>
        <section>
          <TextField
            className="textarea"
            label="RG"
            disabled
            id="outlined-disabled"
            defaultValue={pesquisador.rg}
            variant="outlined"
          />
        </section>
      </Container>
    </PlataformaPadrao>
  )
}
