import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import './styles.css'
import { UserContext } from '../../contexts/user.js'
import { BsFillPeopleFill, BsFillPersonCheckFill, BsPersonCheckFill } from 'react-icons/bs'
import { FaCalendarAlt } from 'react-icons/fa'

import api from '../../services/api'
import PlataformaPadrao from '../../components/plataforma-padrao'
import CardPrincipalAdm from '../../components/CardPrincipalAdm'
import ItemListaPacientesEspecificos from '../../components/ItemListaPacientesEspecificos'
import ItemListaEstudanteAutenticar from '../../components/ItemListaEstudanteAutenticar'
import ItemListaPesquisadorAutenticar from '../../components/ItemListaPesquisadorAutenticar'
import { FaFontAwesome } from 'react-icons/fa'
export default function PrincipalPesquisador() {
  const { user } = useContext(UserContext)
  let id = user.id_global
  //const [loading, setLoading] = useState(true);
  const [estudante, setEstudante] = useState([])
  const [globalEstudante, setGlobalEstudante] = useState([])
  const [pesquisador, setPesquisador] = useState([])
  const [globalPesquisador, setGlobalPesquisador] = useState([])
  const [diahoraproximaconsulta, setDiahoraproximaconsulta] = useState(
    '05/05/2021 08:00'
  )
  const [profissionalresponsavel, setProfissionalresponsavel] = useState(
    'Gisele Martins'
  )
  const [first, setFirst] = useState(true)
  const [second, setSecond] = useState(true)
  const [third, setThird] = useState(true)
  let pacientes_especificos = `/lista-pacientes-especificos/${id}`
  let novos_pacientes_especificos = `/lista-novo-paciente/${id}`
  let disponibilidade_especifico = `/edicao-disponibilidade/${id}`

  if (first) {
    setFirst(false)
    async function getPesquisadorEstudanteG() {
      //const response1 = await api.get(`autenticacao/2`)
      const response1 = await api.get(`autenticacao`)
      setGlobalPesquisador(response1.data)
      // const response2 = await api.get(`autenticacao/3`)
      const response2 = await api.get(`autenticacao`)
      setGlobalEstudante(response2.data)
    }
    getPesquisadorEstudanteG()
  }

  return (
    <>
      <PlataformaPadrao
        title="Página principal do administrador"
        legend="Página inicial para navegação na plataforma"
      ><Link className="card-meus-pacientes" to={pacientes_especificos}>
          <div className="titulo-card-meus-pacientes"><BsFillPeopleFill /> MEUS PACIENTES</div>
          <div className="descricao-card-meus-pacientes">
            » Acompanhamento das famílias
          </div>
        </Link>
        <Link
          className="card-link-calendario-hora"
          to={disponibilidade_especifico}
        >
          <div className="titulo-card-link-calendario-hora">
            <FaCalendarAlt /> CALENDÁRIO E HORA
          </div>
          <div className="descricao-card-link-calendario-hora">
            » Atualizar disponibilidade
          </div>
        </Link>
        <Link className="card-aceitar-recusar" to={novos_pacientes_especificos}>
          <div className="titulo-card-aceitar-recusar">
            Aceitar/Recusar novas consultas
          </div>
        </Link>
        <Link className="card-pag-prin-adm" to="/lista-autenticar">
          <div>
            <BsFillPersonCheckFill />  Autenticar Novos Cadastros de
            Estudantes e/ou Enfermeiras(os)
          </div>
        </Link>
        <Link className="card-pag-prin-adm" to="/lista-pesquisador">
          <div>
            <i class="fas fa-search"></i> Relação de Enfermeiras(os) Cadastrados
          </div>
        </Link>
        <Link className="card-pag-prin-adm" to="/lista-administrador">
          <div>
            <i class="fas fa-search"></i> Relação de Administradores Cadastrados
          </div>
        </Link>
        <Link className="card-pag-prin-adm" to="/lista-estudante">
          <div>
            <i class="fas fa-search"></i> Relação de Estudantes Cadastrados
          </div>
        </Link>
        <Link className="card-pag-prin-adm" to="/lista-familia">
          <div>
            <i class="fas fa-search"></i> Relação de Pacientes Cadastrados
          </div>
        </Link>
        <Link className="card-pag-prin-adm" to="/lista-pacientes-alta">
          <div>
            <i class="fas fa-search"></i> Relação de Pacientes que Tiveram Alta
          </div>
        </Link>
      </PlataformaPadrao>
    </>
  )
}
