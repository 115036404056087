import Button from '@material-ui/core/Button'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ItemListaPostagem from '../../components/ItemListaPostagem'
import PlataformaPadrao from '../../components/plataforma-padrao'
import api from '../../services/api'
import './styles.css'
import Table from 'react-bootstrap/Table'

export default function ListaPesquisador() {
  //const [loading, setLoading] = useState(true);
  const [postagem, setPostagem] = useState([])
  const [tags, setTags] = useState([])
  const [first, setFirst] = useState(true)
  const [second, setSecond] = useState(true)

  const history = useHistory()

  if (first) {
    setFirst(false)
    async function getPostagem() {
      const response1 = await api.get(`noticias`)
      setPostagem(response1.data)
    }
    getPostagem()
  }

  function handleNovaPostagem(e) {
    e.preventDefault()
    history.push('/cadastro-noticia')
  }

  return (
    <>
      <PlataformaPadrao
        title="Gerenciamento do Blog"
        legend="Lista das postagens"
      >
        <div className="nova-postagem">
          <Button
            style={{ marginBottom: 20 }}
            onClick={handleNovaPostagem}
            variant="contained"
          >
            Nova postagem
          </Button>
        </div>
        <div className="lista-geral-table-wrapper">
          <Table >
            <thead>
              <th>
                <div>TÍTULO</div>
              </th>
              <th>
                <div>DATA</div>
              </th>
              <th>
                <div>TAGS</div>
              </th>
              <th>
              </th>
              <th>
              </th>
              <th>
              </th>
              <th>
              </th>
            </thead>
            <tbody>
              {postagem.map((postagem) => {
                if (second) {
                  setSecond(false)
                  async function getTags() {
                    //AQUI TEM QUE SEPARAR DE ALGUMA FORMA AS TAGS EM postagem.tags
                    const response2 = await api.get(`tags/${postagem.tags}`)
                    setTags(response2.data)
                  }
                  getTags()
                }
                return <ItemListaPostagem postagem={postagem} tags={tags} />
              })}
            </tbody>
          </Table>
        </div>

      </PlataformaPadrao>
    </>
  )
}
