import Modal from 'react-bootstrap/Modal'
import api from '../../services/api'
import './styles.css'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Form, Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    justifyContent: 'center',
    color: '#000000',
  },
  botao: {
    backgroundColor: '#F54C4C',
  },
  botaosecundario: {
    color: '#F54C4C',
  },
}))

export default function ItemListaPesquisador({
  excluir,
  editar,
  olhar,
  pesquisador,
  globalPesquisador,
}) {
  const classes = useStyles()
  const history = useHistory()

  const [showVer, setShowVer] = useState(false)
  const [showEditar, setShowEditar] = useState(false)
  const [showDeletar, setShowDeletar] = useState(false)

  const handleCloseVer = () => setShowVer(false)
  const handleShowVer = () => setShowVer(true)
  const handleCloseEditar = () => setShowEditar(false)
  const handleShowEditar = () => setShowEditar(true)
  const handleCloseDeletar = () => setShowDeletar(false)
  const handleShowDeletar = () => setShowDeletar(true)

  //Cadastro global
  const [id_global, setIdglobal] = useState(globalPesquisador.id_global)
  const [nome, setNome] = useState(globalPesquisador.nome)
  const [data_nascimento, setDataNascimento] = useState(
    globalPesquisador.data_nascimento
  )
  const [email, setEmail] = useState(globalPesquisador.email)
  const [cpf, setCpf] = useState(globalPesquisador.cpf)
  const [senha, setSenha] = useState(globalPesquisador.senha)
  const [telefone1, setTelefone1] = useState(globalPesquisador.telefone1)
  const [telefone2, setTelefone2] = useState(globalPesquisador.telefone2)
  const [sexo, setSexo] = useState(globalPesquisador.sexo) //começa com Feminino pois é a primeira opção do select no front
  const [tipo, setTipo] = useState(globalPesquisador.tipo)
  const [autenticado, setAutenticado] = useState(
    globalPesquisador.autenticado_pelo_admin
  )
  //Cadastro Pesquisador
  const id_pesquisador = pesquisador.id_pesquisador
  const id_paciente = pesquisador.id_paciente
  const [rg, setRg] = useState(pesquisador.rg)
  const [coren, setCoren] = useState(pesquisador.coren)
  const [nivel_formacao, setNivelFormacao] = useState(
    pesquisador.nivel_formacao
  )
  const acesso_cadastros = pesquisador.acesso_cadastros
  const acesso_blog = pesquisador.acesso_blog
  const acesso_designação = pesquisador.acesso_designação
  const acesso_consulta = pesquisador.acesso_consulta
  const quantidade_pacientes = pesquisador.quantidade_pacientes

  async function handleEdicao(f) {
    f.preventDefault()

    const data = {
      id_paciente,
      nome,
      data_nascimento,
      cpf,
      rg,
      coren,
      sexo,
      nivel_formacao,
      acesso_cadastros,
      acesso_blog,
      acesso_designação,
      acesso_consulta,
      quantidade_pacientes,
    }

    const data2 = {
      nome,
      data_nascimento,
      email,
      cpf,
      sexo,
      telefone1,
      telefone2,
    }

    try {
      await api.put(`pesquisador/${id_global}`, data)
      await api.put(`global/${id_global}`, data2)
      setShowEditar(false)
      history.go(0)
    } catch (err) {
      alert('Erro ao editar. Tente Novamente.')
    }
  }

  async function handleExclusao() {
    //! quando excluir, alocar os pacientes deste pesquisador para o administrador (id 1)

    //* Recuperar pacientes do pesquisador escolhido e do administrador
    const pacientesPesquisador = id_paciente.toString()
    let pacientesAdministrador

    //* se o pesquisador escolhido ainda tiver pacientes
    if (pacientesPesquisador) {
      let error = false
      const pacientesPesquisadorArr = pacientesPesquisador.split(',')

      await api
        .get('pesquisador/1')
        .then(async (response) => {
          //* atribua esse paciente ao administrador

          pacientesAdministrador = response.data.id_paciente
            .toString()
            .concat(',' + pacientesPesquisador)

          await api.put('pesquisador/1', {
            id_paciente: pacientesAdministrador,
            quantidade_pacientes:
              response.data.quantidade_pacientes +
              pacientesPesquisadorArr.length,
          })

          //* atribua o administrador como pesquisador desses pacientes

          pacientesPesquisadorArr.map(async (paciente) => {
            await api.put('paciente/' + paciente, { id_pesquisador: '1' })
          })
        })
        .catch((err) => {
          alert('Erro ao deletar. Tente Novamente.\n' + err.toString())
          error = true
        })

      if (error) {
        return
      }
    }

    //! restante

    //* Apaga as disponibilidades desse pesquisador
    await api.delete(`disponibilidadeAll/${id_global}`)
    await api.delete(`/pesquisador/${id_global}`)
    await api.delete(`/global/${id_global}`)

    setShowDeletar(false)
    history.go(0)
  }

  const handleAdmin = () => {
    api
      .put(`turnAdmin/${id_global}`)
      .catch(() => alert('erro ao recuperar informações, tente novamente'))
  }

  const handleNoAdmin = () => {
    api
      .put(`turnNoAdmin/${id_global}`)
      .catch(() => alert('erro ao recuperar informações, tente novamente'))
  }

  return (
    <>
      <tr>
        {/* <div className="itens-da-linha">*/}
        <td>{pesquisador.nome}</td>
        <td>{globalPesquisador.email}</td>
        <td>{globalPesquisador.telefone1}</td>
        {/* </div>*/}
        {/*<div className="botoes-lista">*/}
        <td>
          {' '}
          <Button variant="primary" onClick={handleShowVer}>
            VER
          </Button>
        </td>
        <td>
          {' '}
          <Button
            id="botaopesq"
            variant="primary"
            onClick={() =>
              history.push(
                `/edicao-disponibilidade/${globalPesquisador.id_global}`
              )
            }
          >
            DISPONIBILIDADES
          </Button>
        </td>
        <td>
          <Button variant="primary" onClick={handleShowEditar}>
            EDITAR
          </Button>
        </td>
        <td>
          <Button variant="primary" onClick={handleShowDeletar}>
            EXCLUIR
          </Button>
        </td>
        {/* </div>} */}
      </tr>

      {/* Modal de visualizar dados */}
      <Modal show={showVer} onHide={handleCloseVer}>
        <Modal.Header closeButton>
          <Modal.Title>Dados de {globalPesquisador.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <section>
              <TextField
                className="textarea"
                label="Nome Completo"
                disabled
                id="outlined-disabled"
                defaultValue={pesquisador.nome}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Telefone para contato 1"
                disabled
                id="outlined-disabled"
                defaultValue={globalPesquisador.telefone1}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Telefone para contato 2"
                disabled
                id="outlined-disabled"
                defaultValue={globalPesquisador.telefone2}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="E-mail"
                disabled
                id="outlined-disabled"
                defaultValue={globalPesquisador.email}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Data de Nascimento"
                disabled
                id="outlined-disabled"
                defaultValue={pesquisador.data_nascimento}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Sexo"
                disabled
                id="outlined-disabled"
                defaultValue={pesquisador.sexo}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Nível de formação"
                disabled
                id="outlined-disabled"
                defaultValue={pesquisador.nivel_formacao}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="Coren"
                disabled
                id="outlined-disabled"
                defaultValue={pesquisador.coren}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="CPF"
                disabled
                id="outlined-disabled"
                defaultValue={pesquisador.cpf}
                variant="outlined"
              />
            </section>
            <section>
              <TextField
                className="textarea"
                label="RG"
                disabled
                id="outlined-disabled"
                defaultValue={pesquisador.rg}
                variant="outlined"
              />
            </section>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseVer}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de editar dados */}
      <Modal show={showEditar} onHide={handleCloseEditar}>
        <Modal.Header closeButton>
          <Modal.Title>Editando {globalPesquisador.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form onSubmit={handleEdicao}>
              <section>
                <TextField
                  className="textarea"
                  label="Nome Completo"
                  id="outlined"
                  defaultValue={pesquisador.nome}
                  onChange={(f) => setNome(f.target.value)}
                  variant="outlined"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  label="Telefone para contato 1"
                  id="outlined"
                  defaultValue={globalPesquisador.telefone1}
                  onChange={(f) => setTelefone1(f.target.value)}
                  variant="outlined"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  label="Telefone para contato 2"
                  id="outlined"
                  defaultValue={globalPesquisador.telefone2}
                  onChange={(f) => setTelefone2(f.target.value)}
                  variant="outlined"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  label="E-mail"
                  id="outlined"
                  defaultValue={globalPesquisador.email}
                  onChange={(f) => setEmail(f.target.value)}
                  variant="outlined"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  label="Data de Nascimento"
                  id="outlined"
                  defaultValue={pesquisador.data_nascimento}
                  onChange={(f) => setDataNascimento(f.target.value)}
                  variant="outlined"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  label="Sexo"
                  id="outlined"
                  defaultValue={pesquisador.sexo}
                  onChange={(f) => setSexo(f.target.value)}
                  variant="outlined"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  label="Nível de formação"
                  id="outlined"
                  defaultValue={pesquisador.nivel_formacao}
                  onChange={(f) => setNivelFormacao(f.target.value)}
                  variant="outlined"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  label="Coren"
                  id="outlined"
                  defaultValue={pesquisador.coren}
                  onChange={(f) => setCoren(f.target.value)}
                  variant="outlined"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  label="CPF"
                  id="outlined"
                  defaultValue={pesquisador.cpf}
                  onChange={(f) => setCpf(f.target.value)}
                  variant="outlined"
                />
              </section>
              <section>
                <TextField
                  className="textarea"
                  label="RG"
                  id="outlined"
                  defaultValue={pesquisador.rg}
                  onChange={(f) => setRg(f.target.value)}
                  variant="outlined"
                />
              </section>
              {globalPesquisador.tipo === 'admin' ? (
                <div className="btn-send">
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={handleNoAdmin}
                  >
                    Revogar permissões de administrador
                  </Button>
                </div>
              ) : (
                <div className="btn-send">
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={handleAdmin}
                  >
                    Tornar administrador
                  </Button>
                </div>
              )}
              <div className="btn-send">
                <Button variant="contained" type="submit">
                  Salvar Mudanças
                </Button>
              </div>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditar}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal de deletar */}
      <Modal show={showDeletar} onHide={handleCloseDeletar}>
        <Modal.Header closeButton>
          <Modal.Title>Excluindo {globalPesquisador.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja excluir {globalPesquisador.nome}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeletar}>
            FECHAR
          </Button>
          <Button
            style={{ color: 'red' }}
            variant="primary"
            onClick={handleExclusao}
          >
            EXCLUIR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
