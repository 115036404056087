import React, { useState, useContext } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import './styles.css'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Modal from 'react-bootstrap/Modal'
import api from '../../services/api'
import { Form, Spinner, Container } from 'react-bootstrap'
import { Alert } from '@material-ui/lab'
import ItemListaMensagem from '../../components/ItemListaMensagem'
import PlataformaPadrao from '../../components/plataforma-padrao'
import queryString from 'query-string'
import { useParams } from 'react-router'
import { UserContext } from '../../contexts/user.js'
import { AiFillMessage } from 'react-icons/ai'

export default function ListaPesquisador() {
  //const [loading, setLoading] = useState(true);
  let { id } = useParams()
  const { user } = useContext(UserContext)
  //const [id, setId] = useState(1) //ISSO TEM QUE VIR NA URL tipo "..../forum/$id_paciente"
  const [mensagem, setMensagem] = useState([])
  const [first, setFirst] = useState(true)

  const [showNovaMensagem, setShowNovaMensagem] = useState(false)
  const handleCloseNovaMensagem = () => setShowNovaMensagem(false)
  const handleShowNovaMensagem = () => setShowNovaMensagem(true)

  const history = useHistory()

  const [globalName, setGlobalName] = useState([])
  const [id_forum, setIdForum] = useState(id)
  const [autor, setAutor] = useState(user.nome)
  const [anexo, setAnexo] = useState('')
  const [conteudo, setConteudo] = useState('')
  const [titulo, setTitulo] = useState('')
  const [nome, setNome] = useState(user.nome)
  const [id_resposta, setIdResposta] = useState(0)
  const [fileCollection, setFileCollection] = useState([])

  // const QueryParams = window.location.search
  // let id = ''
  // if(QueryParams !== ''){
  //     const values = queryString.parse(QueryParams)
  //     id = values.id
  // Daqui eu já posso usar o id pra fazer requisições tipo:
  // async function getServicos(){
  //     if(first){
  //         try{
  //             const response = await api.get(`servicos/${id}`)
  //             const resp = await api.get(`cozinheiros/${response.data[0].cozinheiro}`)
  //             setServ(response.data[0])
  //             setImagens(response.data[0].imagens)
  //             setMenu(response.data[0].menu)
  //             setFirst(false)
  //         }catch(err){}
  //     }
  // }
  // getServicos()
  // }

  async function fileUpload() {
    let uploadError = false,
      filenamesArray = [],
      allowedTypes = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'video/3gpp',
        'video/mp4',
        'video/ogg',
        'video/quicktime',
        'video/webm',
        'video/x-ms-wmv',
        'video/x-msvideo',
      ]
    const allowedFiles = fileCollection.filter((f) =>
      allowedTypes.includes(f.type)
    )

    if (allowedFiles.length !== fileCollection.length) {
      alert('Erro ao fazer upload, verifique o formato do arquivo.')
      setFileCollection([])
      uploadError = true
      return [uploadError, filenamesArray]
    }
    const formData = new FormData()
    allowedFiles.forEach((f) => formData.append('file', f))
    await api
      .post('/uploads', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        filenamesArray = res.data
      })

    return [uploadError, filenamesArray]
  }

  async function handleCadastro(e) {
    e.preventDefault()
    const [uploadError, filenamesArray] = await fileUpload()

    if (uploadError) {
      // if (uploadError || !filenamesArray.length) {
      return
    } else {
      const hora_data = new Date()

      const data = {
        autor,
        id_forum,
        nome,
        titulo,
        conteudo,
        hora_data,
        id_resposta,
        anexo: filenamesArray.join(','),
      }

      try {
        await api.post('mensagem_forum', data)
        setShowNovaMensagem(false)
        alert('Mensagem enviada com sucesso.')
        history.go(0)
      } catch (err) {
        alert('Erro ao Enviar Mensagem. Tente Novamente.')
      }
    }
  }

  if (first) {
    setFirst(false)
    async function getMensagem() {
      const response1 = await api.get(`mensagem_forum/${id}`)
      setMensagem(response1.data)
    }
    getMensagem()
    async function getGlobalName() {
      const response2 = await api.get(`global/${id}`)
      setGlobalName(response2.data)
    }
    getGlobalName()
  }
  return (
    <>
      <PlataformaPadrao title="FÓRUM" legend="Ambiente de comunicação oficial">
        <div className="titulo-linha-da-lista">
          Aqui fica a comunicação entre enfermeiras(os) e o paciente{' '}
          {globalName.nome}.
          <Button variant="contained" onClick={handleShowNovaMensagem}>
            <span>
              <AiFillMessage className="icon-right" color="primary" />
              Nova Mensagem
            </span>
          </Button>
        </div>
        <div className="forum-messages">

          {mensagem.map((mensagem, index) => {
            return <ItemListaMensagem mensagem={mensagem} key={index} />
          })}
        </div>
      </PlataformaPadrao>
      {/* Modal para criar uma mensagem */}
      <Modal show={showNovaMensagem} onHide={handleCloseNovaMensagem}>
        <Modal.Header closeButton>
          <Modal.Title>Nova mensagem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form onSubmit={handleCadastro}>
              <section>
                <TextField
                  className="textarea"
                  value={titulo}
                  onChange={(e) => setTitulo(e.target.value)}
                  id="outlined-basic"
                  label="Assunto (opcional)"
                  variant="outlined"
                  inputProps={{ maxLength: 25 }} />
                Max. 25 caracteres

              </section>
              <section>
                <TextField
                  className="textarea"
                  value={conteudo}
                  onChange={(e) => setConteudo(e.target.value)}
                  required
                  id="outlined-basic"
                  label="Conteúdo/Mensagem"
                  variant="outlined"
                  multiline
                  rows={16}
                />
              </section>
              <div id="inputfont">
                <section>
                  <p>Imagens: </p>
                  <input
                    accept="image/jpeg,image/jpg,image/png"
                    type="file"
                    multiple
                    onChange={(e) =>
                      setFileCollection(Array.from(e.target.files))
                    }
                  />
                </section>
                {/* <section>
                  <p>Vídeo: </p>
                  <input
                    accept="video/*"
                    type="file"
                  //onChange={(e) => setVideoCollection(Array.from(e.target.files))}
                  />
                </section> */}
              </div>
              <div className="btn-send">
                <Button variant="contained" type="submit">
                  {' '}
                  Enviar Mensagem
                </Button>
              </div>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNovaMensagem}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
