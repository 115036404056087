import './styles.css'

import { Form, Spinner } from 'react-bootstrap'
import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import ItemListaEstudanteAutenticar from '../../components/ItemListaEstudanteAutenticar'
import ItemListaPesquisadorAutenticar from '../../components/ItemListaPesquisadorAutenticar'
import { Link } from 'react-router-dom'
import PlataformaPadrao from '../../components/plataforma-padrao'
import TextField from '@material-ui/core/TextField'
import api from '../../services/api'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router'

export default function ListaPesquisador() {
  let { id } = useParams()
  //const [loading, setLoading] = useState(true);
  const [paciente, setPaciente] = useState([])
  const [horariosConsultas, setHorariosConsultas] = useState([])
  const [diahoraproximaconsulta, setDiahoraproximaconsulta] =
    useState('05/05/2021')
  const titulo = `Página de ${paciente.nome}` //+
  const [first, setFirst] = useState(true)
  const [second, setSecond] = useState(true)
  const [third, setThird] = useState(true)
  let forum_especifico = `/forum/${id}`
  let consulta_especifica = `/forum/${id}`

  if (first) {
    setFirst(false)
    async function getHorariosConsultas() {
      const response2 = await api.get(`horarios_consultas/${id}`)
      setHorariosConsultas(response2.data)
      const response3 = await api.get(`global/${id}`)
      setPaciente(response3.data)
    }
    getHorariosConsultas()
  }
  return (
    <>
      <PlataformaPadrao
        title={titulo}
        legend='Página inicial para navegação na plataforma'
      >
        <Link className='card-link-para-forum' to={forum_especifico}>
          FÓRUM DE COMUNICAÇÃO
        </Link>
        <div className='container-pastas-cada-consulta'>
          <div className='titulo-container-pastas-cada-consulta'>
            Pastas de consulta:
          </div>
          {horariosConsultas.map((horarioDaConsulta) => {
            return (
              <Link
                className='cada-pasta-consulta'
                to={'/pasta-consulta/' + horarioDaConsulta.id_consulta}
              >
                {new Date(horarioDaConsulta.hora_inicio).toLocaleString(
                  'pt-BR'
                )}
              </Link>
            )
          })}
        </div>
      </PlataformaPadrao>
    </>
  )
}
