import './styles.css'

import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { Container, Form } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'

import Modal from 'react-bootstrap/Modal'
import api from '../../services/api'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    justifyContent: 'center',
    color: '#000000',
  },
  botao: {
    backgroundColor: '#F54C4C',
  },
  botaosecundario: {
    color: '#F54C4C',
  },
}))

export default function ItemListaFamilia({
  excluir,
  editar,
  olhar,
  paciente,
  globalPaciente,
}) {
  const classes = useStyles()
  const history = useHistory()

  const [showVer, setShowVer] = useState(false)
  const [showEditar, setShowEditar] = useState(false)
  const [showPesquisador, setShowPesquisador] = useState(false)

  const handleCloseVer = () => setShowVer(false)
  const handleShowVer = () => setShowVer(true)
  const handleCloseEditar = () => setShowEditar(false)
  const handleShowEditar = () => setShowEditar(true)

  //Cadastro global
  const [id_global, setIdglobal] = useState(globalPaciente.id_global)
  const [pesquisador_atual, setPesquisador_atual] = useState({})
  const [pesquisadores, setPesquisadores] = useState([])
  const [id_pesquisador, setId_pesquisador] = useState(paciente.id_pesquisador)

  const [proxConsulta, setProxConsulta] = useState({ hora_inicio: new Date() })

  useEffect(async () => {
    await axios
      .all([
        api.get(`pesquisador/${id_pesquisador}`),
        api.get('pesquisador'),
        api.get(`proxima_consulta/${globalPaciente.id_global}`),
      ])
      .then(
        ([
          pesquisadorResponse,
          pesquisadoresResponse,
          proxConsultaResponse,
        ]) => {
          setPesquisador_atual(pesquisadorResponse.data)
          setPesquisadores(pesquisadoresResponse.data)
          setProxConsulta(proxConsultaResponse.data)
        }
      )
  }, [])

  const handleClosePesquisador = () => {
    setId_pesquisador(pesquisador_atual.id_global)
    setShowPesquisador(false)
  }
  const handleShowPesquisador = () => setShowPesquisador(true)

  const [nome, setNome] = useState(globalPaciente.nome)
  const [data_nascimento, setDataNascimento] = useState(
    globalPaciente.data_nascimento
  )
  const [email, setEmail] = useState(globalPaciente.email)
  const [cpf, setCpf] = useState(globalPaciente.cpf)
  const [senha, setSenha] = useState(globalPaciente.senha)
  const [telefone1, setTelefone1] = useState(globalPaciente.telefone1)
  const [telefone2, setTelefone2] = useState(globalPaciente.telefone2)
  const [sexo, setSexo] = useState(globalPaciente.sexo) //começa com Feminino pois é a primeira opção do select no front
  const [tipo, setTipo] = useState(globalPaciente.tipo)
  const [autenticado, setAutenticado] = useState(
    globalPaciente.autenticado_pelo_admin
  )
  const [nivel_desenvolvimento, setNivelDesenvolvimento] = useState(
    paciente.nivel_desenvolvimento
  )
  const [escola, setEscola] = useState(paciente.escola)
  const [rg, setRg] = useState(paciente.rg)
  const [peso, setPeso] = useState(paciente.peso)
  const [altura, setAltura] = useState(paciente.altura)
  const [imc, setImc] = useState(paciente.imc)
  const [cidade_estado, setCidadeEstado] = useState(paciente.cidade_estado)
  const [nome_responsavel, setNomeResponsavel] = useState(
    paciente.nome_responsavel
  )
  const [email_responsavel, setEmailResponsavel] = useState(
    paciente.email_responsavel
  )
  const [cpf_responsavel, setCpfResponsavel] = useState(
    paciente.cpf_responsavel
  )
  const [data_nascimento_responsavel, setDataNascimentoResponsavel] = useState(
    paciente.data_nascimento_responsavel
  )
  const [parentesco, setParentesco] = useState(paciente.parentesco)
  const [status, setStatus] = useState(paciente.status)
  //Cadastro Paciente
  async function handleEdicao(f) {
    f.preventDefault()

    const data = {
      nome,
      data_nascimento,
      nivel_desenvolvimento,
      escola,
      cpf,
      rg,
      peso,
      altura,
      imc,
      sexo,
      cidade_estado,
      nome_responsavel,
      email_responsavel,
      cpf_responsavel,
      data_nascimento_responsavel,
      parentesco,
      status,
    }
    const data2 = {
      nome,
      data_nascimento,
      email,
      cpf,
      sexo,
      telefone1,
      telefone2,
    }

    try {
      const response = await api.put(`paciente/${id_global}`, data)
      const response1 = await api.put(`global/${id_global}`, data2)
      setShowEditar(false)
      //history.go(0)
    } catch (err) {
      alert('Erro ao editar. Tente Novamente.')
    }
  }

  async function handleAlteracaoPesquisador() {
    let id_paciente = ''
    const dados_pesquisador = await api.get(`/pesquisador/${id_pesquisador}`)
    if (!dados_pesquisador.data.id_paciente.toString().length) {
      id_paciente = globalPaciente.id_global.toString()
    } else {
      id_paciente = [
        ...new Set(
          dados_pesquisador.data.id_paciente
            .toString()
            .split(',')
            .concat(globalPaciente.id_global.toString())
        ),
      ].join(',')
    }
    const id_paciente_excluido = pesquisador_atual.id_paciente
      .toString()
      .split(',')
      .filter((e) => e !== globalPaciente.id_global.toString())
      .join(',')

    await axios.all([
      await api.put(`paciente/${globalPaciente.id_global}`, { id_pesquisador }),
      await api.put(`pesquisador/${id_pesquisador}`, {
        id_paciente,
        quantidade_pacientes: id_paciente.split(',').length,
      }),
      await api.put(`pesquisador/${pesquisador_atual.id_global}`, {
        id_paciente: id_paciente_excluido,
        quantidade_pacientes: id_paciente_excluido.split(',').length,
      }),
    ])
    alert('Pesquisador alterado com sucesso!')
    window.location.reload()
  }

  return (
    <>
      <tr>
        {/* <div className="itens-da-linha">*/}
        <td>{paciente.nome}</td>
        <td>{globalPaciente.email}</td>
        <td>{globalPaciente.telefone1}</td>
        <td>{new Date(proxConsulta.hora_inicio).toLocaleString('pt-BR')}</td>
        {/* </div>*/}
        {/*<div className="botoes-lista">*/}
        <td>
          {' '}
          <Button variant='primary' onClick={handleShowVer}>
            VER
          </Button>
        </td>
        <td>
          {' '}
          <Button variant='primary' onClick={handleShowEditar}>
            EDITAR
          </Button>
        </td>
        <td>
          {' '}
          <Button
            variant='primary'
            onClick={() => history.push(`cada-paciente/${id_global}`)}
          >
            Pasta
          </Button>
        </td>
        <td>
          <Button variant='primary' onClick={handleShowPesquisador}>
            ENFERMEIRA(O)
          </Button>
        </td>
        {/* </div>} */}
      </tr>

      {/* Modal de visualizar dados */}
      <Modal show={showVer} onHide={handleCloseVer}>
        <Modal.Header closeButton>
          <Modal.Title>Dados de {globalPaciente.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <section>
              <TextField
                className='textarea'
                label='Nome Completo do Paciente'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.nome}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Nome Completo do Responsável'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.nome_responsavel}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Telefone para contato 1'
                disabled
                id='outlined-disabled'
                defaultValue={globalPaciente.telefone1}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Telefone para contato 2'
                disabled
                id='outlined-disabled'
                defaultValue={globalPaciente.telefone2}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Cidade/Estado'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.cidade_estado}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='E-mail do Paciente'
                disabled
                id='outlined-disabled'
                defaultValue={email}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='E-mail do Responsável'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.email_responsavel}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Data de Nascimento do Paciente'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.data_nascimento}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Data de Nascimento do Responsável'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.data_nascimento_responsavel}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Sexo'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.sexo}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Altura do Paciente (em centímetros)'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.altura}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Peso do Paciente (em quilogramas)'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.peso}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Escola do Paciente'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.escola}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='CPF do Paciente'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.cpf}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='RG do Paciente'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.rg}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='Grau de Parentesco entre Responsável e Paciente'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.parentesco}
                variant='outlined'
              />
            </section>
            <section>
              <TextField
                className='textarea'
                label='CPF do Responsável'
                disabled
                id='outlined-disabled'
                defaultValue={paciente.cpf_responsavel}
                variant='outlined'
              />
            </section>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseVer}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de editar dados */}
      <Modal show={showEditar} onHide={handleCloseEditar}>
        <Modal.Header closeButton>
          <Modal.Title>Editando {globalPaciente.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form onSubmit={handleEdicao}>
              <section>
                <TextField
                  className='textarea'
                  label='Nome Completo do Paciente'
                  id='outlined'
                  defaultValue={paciente.nome}
                  onChange={(f) => setNome(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='Nome Completo do Responsável'
                  id='outlined'
                  defaultValue={paciente.nome_responsavel}
                  onChange={(f) => setNomeResponsavel(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='Telefone para contato 1'
                  id='outlined'
                  defaultValue={globalPaciente.telefone1}
                  onChange={(f) => setTelefone1(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='Telefone para contato 2'
                  id='outlined'
                  defaultValue={globalPaciente.telefone2}
                  onChange={(f) => setTelefone2(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='Cidade/Estado'
                  id='outlined'
                  defaultValue={paciente.cidade_estado}
                  onChange={(f) => setCidadeEstado(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='E-mail do paciente'
                  id='outlined'
                  defaultValue={email}
                  onChange={(f) => setEmail(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='E-mail do Responsável'
                  id='outlined'
                  defaultValue={paciente.email_responsavel}
                  onChange={(f) => setEmailResponsavel(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='Data de Nascimento do Paciente'
                  id='outlined'
                  defaultValue={paciente.data_nascimento}
                  onChange={(f) => setDataNascimento(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='Data de Nascimento do Responsável'
                  id='outlined'
                  defaultValue={paciente.data_nascimento_responsavel}
                  onChange={(f) => setDataNascimentoResponsavel(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='Sexo'
                  id='outlined'
                  defaultValue={paciente.sexo}
                  onChange={(f) => setSexo(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='Altura do Paciente (em centímetros)'
                  id='outlined'
                  defaultValue={paciente.altura}
                  onChange={(f) => setAltura(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='Peso do Paciente (em quilogramas)'
                  id='outlined'
                  defaultValue={paciente.peso}
                  onChange={(f) => setPeso(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='Escola do Paciente'
                  id='outlined'
                  defaultValue={paciente.escola}
                  onChange={(f) => setEscola(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='CPF do Paciente'
                  id='outlined'
                  defaultValue={paciente.cpf}
                  onChange={(f) => setCpf(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='RG do Paciente'
                  id='outlined'
                  defaultValue={paciente.rg}
                  onChange={(f) => setRg(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='Grau de Parentesco entre Responsável e Paciente'
                  id='outlined'
                  defaultValue={paciente.parentesco}
                  onChange={(f) => setParentesco(f.target.value)}
                  variant='outlined'
                />
              </section>
              <section>
                <TextField
                  className='textarea'
                  label='CPF do Responsável'
                  id='outlined'
                  defaultValue={paciente.cpf_responsavel}
                  onChange={(f) => setCpfResponsavel(f.target.value)}
                  variant='outlined'
                />
              </section>
              <div className='btn-send'>
                <Button variant='contained' type='submit'>
                  Salvar Mudanças
                </Button>
              </div>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseEditar}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de visualizar e alterar pesquisador */}
      <Modal
        id='modalesc'
        show={showPesquisador}
        onHide={handleClosePesquisador}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Enfermeira(o) Atual de {globalPaciente.nome}:{' '}
            {pesquisador_atual.nome}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Para mudar de enfermeira(o) associado a esse paciente, selecione
            a(o) nova(o) enfermeira(o) abaixo e clique no botão para confirmar:
          </p>
          <FormControl className={classes.formControl}>
            <Select
              value={id_pesquisador}
              onChange={(e) => setId_pesquisador(e.target.value)}
            >
              {pesquisadores.map((pesquisador) => (
                <MenuItem value={pesquisador.id_global}>
                  {pesquisador.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              id='btndisp'
              variant='contained'
              color='primary'
              onClick={handleAlteracaoPesquisador}
            >
              confirmar
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClosePesquisador}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
