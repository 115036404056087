import './styles.css'
import React from 'react'
import {
  Button,

} from '@material-ui/core'


export default function ItemListaFamilia({ data }) {


  return (
    <>
      <tr>
        {/* <div className="itens-da-linha">*/}
        <td >{data.name}</td>
        <td >{data.email}</td>
        <td >{data.number}</td>
        {/* </div>*/}
        {/*<div className="botoes-lista">*/}
        <td> <Button variant="primary" onClick={() => alert("Em obras!")}>
          VER
        </Button></td>
        <td> <Button variant="primary" onClick={() => alert("Em obras!")}>
          EDITAR
        </Button></td>
        <td><Button variant="primary" onClick={() => alert("Em obras!")}>
          PESQ
        </Button></td>
        <td><Button variant="primary" onClick={() => alert("Em obras!")}>
          EXCLUIR
        </Button></td>
        {/* </div>} */}
      </tr>
    </>
  )
}
