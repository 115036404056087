import './styles.css'

import React, { useContext, useState } from 'react'

import { Link } from 'react-router-dom'
import PlataformaPadrao from '../../components/plataforma-padrao'
import { UserContext } from '../../contexts/user.js'
import api from '../../services/api'

export default function PrincipalFamilia() {
  const { user } = useContext(UserContext)
  let id = user.id_global
  //const [loading, setLoading] = useState(true);
  const [paciente, setPaciente] = useState([])
  const [pesquisador, setPesquisador] = useState([])
  const [globalPesquisador, setGlobalPesquisador] = useState([])
  const [proximaconsulta, setProximaconsulta] = useState({})
  const [profissionalresponsavel, setProfissionalresponsavel] = useState('')
  const [first, setFirst] = useState(true)
  const [second, setSecond] = useState(true)
  const [third, setThird] = useState(true)

  let forum_da_familia = `/forum/${id}`
  let instrumento_a_preencher = `/instrumentos-preencher/${proximaconsulta.id_consulta}`

  if (first) {
    setFirst(false)
    async function getDados() {
      //const response1 = await api.get(`autenticacao/2`)
      const response1 = await api.get(`/paciente/${id}`)
      setPaciente(response1.data)
      // const response2 = await api.get(`autenticacao/3`)
      const response2 = await api.get(
        `/pesquisador/${response1.data.id_pesquisador}`
      )
      setPesquisador(response2.data)
      const response3 = await api.get(`/proxima_consulta/${id}`)
      setProximaconsulta(response3.data)
    }
    getDados()
  }
  return (
    <>
      <PlataformaPadrao
        title='Página principal da família'
        legend='Página inicial para navegação na plataforma'
      >
        <div className='card-lembrete'>
          <div className='data-proxima-consulta'>
            Próxima consulta:{' '}
            {new Date(proximaconsulta.hora_inicio).toLocaleString('pt-BR')}
          </div>
          <div className='pesquisador-responsavel-consulta'>
            Profissional responsável: {pesquisador.nome}
          </div>
        </div>
        <Link
          className='link-instrumentos-preencher card-link-instrumentos'
          to={instrumento_a_preencher}
        >
          » Clique aqui para preencher os instrumentos da próxima consulta
        </Link>
        <Link
          style={{ color: 'black', textDecoration: 'none' }}
          to={forum_da_familia}
          className='card-link-para-forum'
        >
          FÓRUM DE COMUNICAÇÃO
        </Link>
      </PlataformaPadrao>
    </>
  )
}
