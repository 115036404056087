import {
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { Form, Image, Spinner } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import PlataformaPadrao from '../../components/plataforma-padrao'
import api from '../../services/api'
import './styles.css'
import tipoCocoImage from '../../assets/bristoll.png'
import { UserContext } from '../../contexts/user'

export default function FormularioRomaIV() {
  const { user } = useContext(UserContext)
  const history = useHistory()
  const [pergunta_1, setPergunta_1] = useState(0)
  const [pergunta_2, setPergunta_2] = useState(0)
  const [pergunta_3, setPergunta_3] = useState(0)
  const [pergunta_4, setPergunta_4] = useState(0)
  const [pergunta_5, setPergunta_5] = useState(0)
  const [pergunta_6, setPergunta_6] = useState(0)
  const [data_inicial, setDataInicial] = useState([])
  const [idPaciente, setIdPaciente] = useState('')

  let { id } = useParams()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getData() {
      await api.get(`roma_iv/${id}`).then((response) => {
        setPergunta_1(response.data.pergunta_1.toString())
        setPergunta_2(response.data.pergunta_2.toString())
        setPergunta_3(response.data.pergunta_3.toString())
        setPergunta_4(response.data.pergunta_4.toString())
        setPergunta_5(response.data.pergunta_5.toString())
        setPergunta_6(response.data.pergunta_6.toString())
        setDataInicial(response.data.data_inicial.toString())
        setIdPaciente(response.data.id_paciente)
        api.get(`paciente/${response.data.id_paciente}`).then(({ data }) => {
          if (
            user.id_global === response.data.id_paciente ||
            user.id_global === data.id_pesquisador
          ) {
            setLoading(false)
          } else {
            history.push(`/sem-permissao`)
          }
        })
      })
    }
    getData()
  }, [])

  function handleSubmit(e) {
    e.preventDefault()

    const data = {
      pergunta_1,
      pergunta_2,
      pergunta_3,
      pergunta_4,
      pergunta_5,
      pergunta_6,
      data_inicial,
    }

    api
      .put(`roma_iv/${id}`, data)
      .then(() => {
        api.post('roma_warn', { id_paciente: idPaciente }).catch((error) => {
          console.log(error)
          alert('Erro ao notificar a(o) enfermeira(o)! Contate a administração.')
        })
      })
      .catch((error) => {
        console.log(error)
        alert('Erro! Tente novamente.')
      })

    alert('Operação realizada com sucesso')
    history.goBack()
  }

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  return (
    <PlataformaPadrao title="Roma IV">
      <div className="subtitulo_instrucoes">
        <p id="datainicial">
          Com base nos últimos 30 dias, considerar a data de HOJE que você está
          preenchendo o Roma IV”, responda as 6 perguntas a seguir:
          {/* Semana do dia: {new Date(data_inicial).toLocaleString()} */}
        </p>
      </div>
      <Form>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Duas ou menos evacuações no toalete por semana, em crianças com
            desenvolvimento igual ou maior ao esperado para quatro anos de
            idade.
          </FormLabel>
          <RadioGroup
            aria-label="pergunta_1"
            name="pergunta_1"
            value={pergunta_1}
            onChange={(e) => setPergunta_1(e.target.value)}
          >
            <FormControlLabel value="0.1" control={<Radio />} label="Não" />
            <FormControlLabel value="1" control={<Radio />} label="Sim" />
          </RadioGroup>
          <FormLabel component="legend">
            Pelo menos um episódio de incontinência fecal por semana.
          </FormLabel>
          <RadioGroup
            aria-label="pergunta_2"
            name="pergunta_2"
            value={pergunta_2}
            onChange={(e) => setPergunta_2(e.target.value)}
          >
            <FormControlLabel value="0.1" control={<Radio />} label="Não" />
            <FormControlLabel value="1" control={<Radio />} label="Sim" />
          </RadioGroup>
          <FormLabel component="legend">Retenção fecal.</FormLabel>
          <RadioGroup
            aria-label="pergunta_3"
            name="pergunta_3"
            value={pergunta_3}
            onChange={(e) => setPergunta_3(e.target.value)}
          >
            <FormControlLabel value="0.1" control={<Radio />} label="Não" />
            <FormControlLabel value="1" control={<Radio />} label="Sim" />
          </RadioGroup>
          <FormLabel component="legend">
            Dor ao evacuar ou fezes endurecidas.
          </FormLabel>
          <RadioGroup
            aria-label="pergunta_4"
            name="pergunta_4"
            value={pergunta_4}
            onChange={(e) => setPergunta_4(e.target.value)}
          >
            <FormControlLabel value="0.1" control={<Radio />} label="Não" />
            <FormControlLabel value="1" control={<Radio />} label="Sim" />
          </RadioGroup>
          <FormLabel component="legend">
            Presença de massa fecal palpável no reto.
          </FormLabel>
          <RadioGroup
            aria-label="pergunta_5"
            name="pergunta_5"
            value={pergunta_5}
            onChange={(e) => setPergunta_5(e.target.value)}
          >
            <FormControlLabel value="0.1" control={<Radio />} label="Não" />
            <FormControlLabel value="1" control={<Radio />} label="Sim" />
          </RadioGroup>
          <FormLabel component="legend">
            Relato de fezes de grosso calibre capazes de entupir vaso sanitário
            (consulte a Escala Bristol abaixo caso não se recorde qual tipo de
            cocô é o grosso calibre.)
          </FormLabel>
          <Image className="w-100" src={tipoCocoImage} thumbnail />
          <RadioGroup
            aria-label="pergunta_6"
            name="pergunta_6"
            value={pergunta_6}
            onChange={(e) => setPergunta_6(e.target.value)}
          >
            <FormControlLabel value="0.1" control={<Radio />} label="Não" />
            <FormControlLabel value="1" control={<Radio />} label="Sim" />
          </RadioGroup>
          <div className="btn-send">
            <Button
              onClick={handleSubmit}
              color="secondary"
              variant="contained"
            >
              concluir
            </Button>
          </div>
        </FormControl>
      </Form>
    </PlataformaPadrao>
  )
}
