import './styles.css'

import React, { useContext, useEffect, useState } from 'react'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Axios from 'axios'
import { Button } from '@material-ui/core'
import PlataformaPadrao from '../../components/plataforma-padrao'
import { Spinner } from 'react-bootstrap'
import TextField from '@material-ui/core/TextField'
import { UserContext } from '../../contexts/user.js'
import add from 'date-fns/add'
import api from '../../services/api'
import { email_primeira_consulta } from '../../emails'
import lastDayOfWeek from 'date-fns/lastDayOfWeek'
import set from 'date-fns/set'
import startOfWeek from 'date-fns/startOfWeek'
import sub from 'date-fns/sub'
import { useHistory } from 'react-router-dom'

export default function MarcarPrimeiraConsulta() {
  const history = useHistory()
  const { user } = useContext(UserContext)

  const [selectedDate, setSelectedDate] = useState('')
  const [horariosDisponiveis, setHorariosDisponiveis] = useState([])
  const [loading, setLoading] = useState(true)

  const id_paciente = user.id_global
  const instrumentos = '1,1,1,1'

  useEffect(() => {
    const getHorariosDisponiveis = async () => {
      await api.get('disponibilidade').then(({ data }) => {
        setHorariosDisponiveis(
          //! filtra as disponibilidades para mostrar somente as disponibilidades 48h depois da hora atual
          data.filter((horario) => {
            const prox48h = new Date()
            prox48h.setDate(prox48h.getDate() + 2)

            return horario.hora_inicio > prox48h.toISOString()
          })
        )
        setLoading(false)
      })
    }
    getHorariosDisponiveis()
  }, [])

  const handleInserirPacientenoPesquisador = () => {
    //! Recupera o pesquisador escolhido e armazena seus pacientes (com o novo incluso)
    //! na variável pacientes_deste_pesquisador
    api.get(`/pesquisador/${selectedDate.id_pesquisador}`).then(({ data }) => {
      let pacientes_deste_pesquisador = ''
      //? caso não existam pacientes neste pesquisador
      if (!data.id_paciente.toString().length) {
        pacientes_deste_pesquisador = id_paciente.toString()
      } else {
        pacientes_deste_pesquisador = [
          ...new Set(
            data.id_paciente
              .toString()
              .split(',')
              .concat(id_paciente.toString())
          ),
        ].join(',')
      }
      api.put(`pesquisador/${selectedDate.id_pesquisador}`, {
        id_paciente: pacientes_deste_pesquisador,
      })
    })
  }

  const handleInserirPesquisadornoPaciente = () => {
    api.put(`paciente/${id_paciente}`, {
      id_pesquisador: selectedDate.id_pesquisador,
    })
  }

  const handleInstrumentosPrimeiraConsulta = async (
    id_consulta,
    data_consulta
  ) => {
    const agora = set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })

    const data_consulta_menos_um_mes = sub(data_consulta, { months: 1 })

    const tokensConsultas = {
      sol_chuva: [],
      roma_iv: [],
      dvss: [],
      diario: [],
    }

    //! Sol e Chuva
    let inicio_semana = startOfWeek(data_consulta_menos_um_mes) //domingo
    let final_semana = lastDayOfWeek(data_consulta_menos_um_mes) //sábado
    const idConsultasSolChuva = []

    while (inicio_semana <= data_consulta) {
      //pré-cria só das obrigatórias
      if (inicio_semana >= startOfWeek(data_consulta_menos_um_mes)) {
        await api
          .post('sol_chuva', {
            id_paciente,
            id_consulta,
            data_inicial: inicio_semana,
            data_final: final_semana,
            segunda: 0,
            terca: 0,
            quarta: 0,
            quinta: 0,
            sexta: 0,
            sabado: 0,
            domingo: 0,
          })
          .then(({ data }) => {
            idConsultasSolChuva.push(data.id)
          })
          .catch((err) => {
            alert('Erro ao Cadastrar Formulário. Tente Novamente.')
          })
      }
      inicio_semana = add(inicio_semana, { weeks: 1 })
      final_semana = add(final_semana, { weeks: 1 })
    }

    //? Cadastra tokens para cada consulta
    const tokenSolChuva = {
      id_global: id_paciente,
      data_hoje: new Date(),
      tipo: 'sol_chuva',
      ids_formularios: idConsultasSolChuva,
    }

    await api
      .post('createForms', tokenSolChuva)
      .then(({ data }) => {
        tokensConsultas.sol_chuva = data
      })
      .catch(console.log)

    //! Roma IV

    inicio_semana = startOfWeek(data_consulta_menos_um_mes) //domingo
    final_semana = lastDayOfWeek(data_consulta_menos_um_mes) //sábado

    const idConsultasRoma = []

    while (inicio_semana <= data_consulta) {
      //pré-cria só das obrigatórias
      if (inicio_semana >= startOfWeek(data_consulta_menos_um_mes)) {
        await api
          .post('roma_iv', {
            id_paciente,
            id_consulta,
            data_inicial: inicio_semana,
            data_final: final_semana,
            pergunta_1: 0,
            pergunta_2: 0,
            pergunta_3: 0,
            pergunta_4: 0,
            pergunta_5: 0,
            pergunta_6: 0,
          })
          .then(({ data }) => {
            idConsultasRoma.push(data.id)
          })
          .catch((err) => {
            alert('Erro ao Cadastrar Formulário. Tente Novamente.')
          })
      }
      inicio_semana = add(inicio_semana, { weeks: 1 })
      final_semana = add(final_semana, { weeks: 1 })
    }

    //? Cadastra tokens para cada consulta
    const tokenRoma = {
      id_global: id_paciente,
      data_hoje: new Date(),
      tipo: 'romaiv',
      ids_formularios: idConsultasRoma,
    }

    await api
      .post('createForms', tokenRoma)
      .then(({ data }) => {
        tokensConsultas.roma_iv = data
      })
      .catch(console.log)

    //! DVSS
    await api
      .post('dvss', {
        id_paciente,
        id_consulta,
        data_inicial: data_consulta_menos_um_mes,
        data_final: data_consulta,
        pergunta_1: 0,
        pergunta_2: 0,
        pergunta_3: 0,
        pergunta_4: 0,
        pergunta_5: 0,
        pergunta_6: 0,
        pergunta_7: 0,
        pergunta_8: 0,
        pergunta_9: 0,
        pergunta_10: 0,
        total: 0,
      })
      .then(({ data }) => {
        api
          .post('token', {
            id_global: id_paciente,
            expiracao: data_consulta,
            tipo: 'dvss',
            id_formulario: data.id,
          })
          .then((response) => {
            tokensConsultas.dvss = response.data.token
          })
          .catch(console.log)
      })
      .catch((err) => {
        alert('Erro ao Cadastrar Formulário. Tente Novamente.')
      })

    //! DIARIO DE ELIMINAÇÕES
    let domingo_anterior_data_consulta = startOfWeek(data_consulta)
    let sabado_anterior_data_consulta = sub(domingo_anterior_data_consulta, {
      days: 1,
    })

    //ACHO QUE STRING VAZIA É DIFERENTE DE NULO, E PORTANTO A LINHA (ingestao_liquido_qualbebeida: "",) FUNCIONA
    await Axios.all(
      [sabado_anterior_data_consulta, domingo_anterior_data_consulta].map(
        (data) =>
          api
            .post('diario_eliminacao', {
              id_paciente,
              id_consulta,
              data,
              ingestao_liquido_hora: '',
              ingestao_liquido_qualbebeida: '',
              ingestao_liquido_quantidademl: '',
              urina_hora: '',
              urina_quantidademl: '',
              urina_urgencia: '',
              urina_quantasvezesmolhou: '',
              urina_volumequemolhou: '',
              eliminacao_intestinal_hora: '',
              eliminacao_intestinal_tipo: '',
            })
            .then((response) => {
              return api
                .post('token', {
                  id_global: id_paciente,
                  expiracao: data_consulta,
                  tipo: 'diario',
                  id_formulario: response.data.id,
                })
                .catch(console.log)
            })
      )
    )
      .then((response) => {
        response.forEach(({ data }) => {
          tokensConsultas.diario.push(data.token)
        })
      })
      .catch((error) => {
        console.log(error)
        alert('Não foi possível notificar o paciente, contate o administrador')
      })

    handleEnviosEmail(tokensConsultas)
  }

  const handleEnviosEmail = (tokens) => {
    try {
      //! Envia email para o paciente sobre a marcação da consulta e os tokens
      sendTokens(tokens)

      //! Envia o email para o pesquisador responsável sobre a marcação da consulta
      api.get(`global/${selectedDate.id_pesquisador}`).then(({ data }) => {
        api.post('/send_mail', {
          nome_destinatario: data.nome,
          endereco_email: data.email,
          assunto_do_email: 'Nova primeira consulta',
          texto_do_email: `O paciente "${user.nome}" acabou de marcar sua primeira consulta, valide-a na plataforma.`,
        })
      })

      //! Envia email ao administrador sobre a marcação da primeira consulta
      api.get('global/1').then(({ data }) => {
        api.post('/send_mail', {
          nome_destinatario: data.nome,
          endereco_email: data.email,
          assunto_do_email: 'Nova primeira consulta',
          texto_do_email: `O paciente "${user.nome}" acabou de marcar sua primeira consulta.`,
        })
      })
    } catch (error) {
      console.log(error)
      alert(
        'Não foi possível notificar as(os) enfermeiras(os), por favor contate o suporte.'
      )
    }
  }

  const sendTokens = (tokens) => {
    tokens = [tokens.sol_chuva, tokens.roma_iv, [tokens.dvss], tokens.diario]

    console.log(tokens)

    const mailData = {
      nome_destinatario: user.nome,
      endereco_email: user.email,
      assunto_do_email: 'Preencha seus formulários toda semana',
      html: email_primeira_consulta(tokens).html,
    }

    api.post('send_mail', mailData).catch(console.log)
  }

  const handlePrimeiraConsulta = () => {
    if (!selectedDate) {
      return
    }

    const dados = {
      id_paciente,
      id_pesquisador: selectedDate.id_pesquisador,
      hora_inicio: selectedDate.hora_inicio,
      instrumentos,
    }

    try {
      api.post('horarios_consultas', dados).then(({ data }) => {
        handleInstrumentosPrimeiraConsulta(
          data.id_consulta,
          new Date(data.hora_inicio)
        )
      })
      handleInserirPacientenoPesquisador()
      handleInserirPesquisadornoPaciente()

      //! Deleta a disponibilidade automaticamente quando a consulta é marcada
      api.delete('/disponibilidade/' + selectedDate.id_disponibilidade)

      //! Notifica e fecha a página, o resto é feito sem mostrar visual
      alert('Consulta cadastrada!')
      history.push(`/principal-familia`)
    } catch (err) {
      alert('Erro ao Cadastrar Consulta. Tente Novamente.\n' + err)
    }
  }

  return loading ? (
    <Spinner />
  ) : (
    <PlataformaPadrao>
      <p id='tituloprox'>
        Marque sua consulta<br></br>
      </p>
      <div className='container'>
        <p id='textoprox2'>
          Selecione o Dia e o Horário da sua Primeira Consulta Dentre os Dias
          Disponíveis
        </p>

        <Autocomplete
          id='escolha-disponibilidade'
          options={horariosDisponiveis}
          getOptionLabel={(horario) =>
            new Date(horario.hora_inicio).toLocaleString()
          }
          style={{ width: 220 }}
          onChange={(_, newInputValue) => setSelectedDate(newInputValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Selecione o horário'
              variant='outlined'
              fullWidth
            />
          )}
        />
      </div>
      <div className='btn-send'>
        <Button variant='contained' onClick={handlePrimeiraConsulta}>
          Marcar sua Primeira Consulta
        </Button>
      </div>
    </PlataformaPadrao>
  )
}
