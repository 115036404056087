import React, {  useState } from 'react'
import Button from '@material-ui/core/Button'
import './styles.css'
import ItemListaGeral from '../../components/ItemListaGeral'
import PlataformaPadrao from '../../components/plataforma-padrao'
import Table from 'react-bootstrap/Table'

export default function ListaPaciente() {
  const [paciente, setPaciente] = useState([])
  const [globalPaciente, setGlobalPaciente] = useState([])
const list = [{
  name:"Antônio Vinícius Nunes de Alencar",
  email:"antonio.alencar@engnetconsultoria.com.br",
  number:"61 99250-1839"
},{
  name:"nome",
  email:"email",
  number:"61 99250-1839"
},{
  name:"nome",
  email:"email",
  number:"61 99250-1839"
},{
  name:"nome",
  email:"email",
  number:"61 99250-1839"
},{
  name:"nome",
  email:"email",
  number:"61 99250-1839"
},{
  name:"nome",
  email:"email",
  number:"61 99250-1839"
},]

  return (
    <>
      <PlataformaPadrao
        title="Pacientes"
        legend="Lista de pacientes cadastrados"
      >
       <div className="lista-geral-table-wrapper">
       <Table >
          <thead>
            <th>
              <div>NOME</div>
            </th>
            <th>
              <div>E-MAIL</div>
            </th>
            <th>
              <div>TELEFONE</div>
            </th>
            <th>
            </th>
            <th>
            </th>
            <th>
            </th>
            <th>
            </th>
          </thead>
          <tbody>
          {
          list.map((c, i) => {
            return (
              <ItemListaGeral
                data={c}
              />
            )
          })}
          </tbody>
        </Table>
       </div>
       
      </PlataformaPadrao>
    </>
  )
}
