import './styles.css'

import { Button, Container } from '@material-ui/core'
import { Col, Modal, Row, Spinner } from 'react-bootstrap'
import {
  FaCalendarPlus,
  FaChild,
  FaClipboardCheck,
  FaMedkit,
} from 'react-icons/fa'
import { Link, useHistory } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Axios from 'axios'
import PlataformaPadrao from '../../components/plataforma-padrao'
import TextField from '@material-ui/core/TextField'
import { UserContext } from '../../contexts/user'
import api from '../../services/api'
import { email_primeira_consulta } from '../../emails'
import { set } from 'date-fns'
import { useParams } from 'react-router'

export default function PastaConsulta() {
  let { id_consulta } = useParams()
  const [disponibilidade, setDisponibilidade] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date())
  const history = useHistory()
  const agora = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
  const [first, setFirst] = useState(true)
  const [third, setThird] = useState(true)
  const { user } = useContext(UserContext)
  const [consulta, setconsulta] = useState([])
  const [sae, setSae] = useState({})
  const [paciente, setPaciente] = useState([])
  const [dvssConsultaEspecifica, setDvssConsultaEspecifica] = useState([])
  const [solChuvaConsultaEspecifica, setSolChuvaConsultaEspecifica] = useState(
    []
  )
  const [romaIVConsultaEspecifica, setRomaIVConsultaEspecifica] = useState([])
  const [
    diarioEliminacaoConsultaEspecifica,
    setDiarioEliminacaoConsultaEspecifica,
  ] = useState([])
  const [showModalAlta, setShowModalAlta] = useState(false)
  const [showModalSolChuva, setShowModalSolChuva] = useState(false)
  const [showModalRemarcar, setShowModalRemarcar] = useState(false)
  const [showModalDvss, setShowModalDvss] = useState(false)
  const [showModalRomaIV, setShowModalRomaIV] = useState(false)
  const [showModalInstrumentos, setShowModalInstrumentos] = useState(false)
  const [showModalDiarioEliminacao, setShowModalDiarioEliminacao] =
    useState(false)
  const [instrumentos_dessa_consulta, setinstrumentos_dessa_consulta] =
    useState([])
  const [loading_animation, setLoading_animation] = useState(false)
  if (first) {
    setFirst(false)
    async function getconsulta() {
      const response = await api.get(`consulta_especifica/${id_consulta}`)
      setconsulta(response.data)
      setinstrumentos_dessa_consulta(response.data.instrumentos.split(','))
      const response2 = await api.get(`global/${response.data.id_paciente}`)
      setPaciente(response2.data)
      // consulta.instrumentos ordem = "1,1,1,1" (solchuva, roma, dvss, diarioeliminacoes)
    }
    getconsulta()
    async function getsae() {
      api
        .get(`consulta/${id_consulta}`)
        .then((response) => setSae(response.data))
    }
    getsae()
  }

  if (third) {
    setThird(false)
    async function getInstrumentos() {
      Promise.all([
        await api.get(`dvss_da_consulta_especifica/${id_consulta}`),
        await api.get(`sol_chuva_da_consulta_especifica/${id_consulta}`),
        await api.get(`roma_iv_da_consulta_especifica/${id_consulta}`),
        await api.get(
          `diario_eliminacao_da_consulta_especifica/${id_consulta}`
        ),
      ]).then(
        ([
          dvssDiaEspecificoResponse,
          solChuvaDiaEspecificoResponse,
          romaIVDiaEspecificoResponse,
          diarioEliminacaoDiaEspecificoResponse,
        ]) => {
          setDvssConsultaEspecifica(dvssDiaEspecificoResponse.data)
          setSolChuvaConsultaEspecifica(solChuvaDiaEspecificoResponse.data)
          setRomaIVConsultaEspecifica(romaIVDiaEspecificoResponse.data)
          setDiarioEliminacaoConsultaEspecifica(
            diarioEliminacaoDiaEspecificoResponse.data
          )
        }
      )
    }
    getInstrumentos()
  }
  useEffect(() => {
    const getData = async () => {
      await api
        .get(`disponibilidade-pesquisador/${user.id_global}`)
        .then((response) => {
          //! filtra as disponibilidades para mostrar somente as disponibilidades 48h depois da hora atual

          let disp = response.data.filter((horario) => {
            let prox48h = new Date()
            prox48h.setDate(prox48h.getDate() + 2)

            return horario.hora_inicio > prox48h.toISOString()
          })

          setDisponibilidade(disp)
        })
    }
    getData()
  }, [])

  const handleEnviosEmail = (tokens) => {
    const mailData = {
      nome_destinatario: paciente.nome,
      endereco_email: paciente.email,
      assunto_do_email: 'Enf-UROPED: Preencha seus formulários toda semana',
      html: email_primeira_consulta(tokens).html,
    }

    api.post('send_mail', mailData).catch(console.log)
  }

  async function handleRemarcarConsulta(e) {
    e.preventDefault()
    setLoading_animation(true)

    const tokensConsultas = {
      sol_chuva: { tokens: [], idFormulario: [] },
      romaiv: { tokens: [], idFormulario: [] },
      dvss: [],
      diario: [],
    }

    const data_consulta = new Date(selectedDate.hora_inicio)

    try {
      //! Delete a disponibilidade
      api
        .delete('/disponibilidade/' + selectedDate.id_disponibilidade)
        .catch((error) => {
          console.log(error)
          alert('Erro ao remarcar consulta.')
        })

      //! Atualize as informações da consulta
      api
        .put(`/horarios_consultas/${id_consulta}`, {
          id_paciente: consulta.id_paciente,
          id_pesquisador: user.id_global,
          hora_inicio: data_consulta.toISOString(),
        })
        .catch((error) => {
          console.log(error)
          alert('Erro ao remarcar consulta.')
        })

      //! ROMA IV
      // Roma IV: tem que reenviar o token da semana atual (mudar a data limite do token), caso a consulta seja remarcada para um prazo menor que 1 mês
      //   e criar Romas das semanas seguintes no prazo de data_consulta-1mês
      await Axios.all([
        api
          .post('roma_iv_remarcar', {
            id_paciente: consulta.id_paciente,
            id_consulta,
            data_consulta: data_consulta.toISOString(),
            data_consulta_anterior: consulta.hora_inicio,
          })
          .then(({ data }) => {
            tokensConsultas.romaiv.tokens = data.tokens
            tokensConsultas.romaiv.idFormulario = data.idFormulario
          }),

        //! Sol e Chuva
        // Sol e Chuva: tem que reenviar o token da semana atual (mudar a data limite do token), caso a consulta seja remarcada para um prazo menor que 1 mês
        //   e criar SoleChuvas das semanas seguintes no prazo de data_consulta-1mês
        api
          .post('sol_chuva_remarcar', {
            id_paciente: consulta.id_paciente,
            id_consulta,
            data_consulta: data_consulta.toISOString(),
            data_consulta_anterior: consulta.hora_inicio,
          })
          .then(({ data }) => {
            tokensConsultas.sol_chuva.tokens = data.tokens
            tokensConsultas.sol_chuva.idFormulario = data.idFormulario
          }),

        //! DVSS
        // DVSS: Apenas reenviar o e-mail com o token desse DVSS (colocando o prazo para a nova data), indicando que as respostas devem ser alteradas caso necessário.
        api
          .get(`/dvss_da_consulta_especifica/${id_consulta}`)
          .then(async ({ data }) => {
            let token = await api.get('token').then((resp) => resp.data)
            try {
              token = token.filter(
                (val) => val.id_global == consulta.id_paciente
              )
              token = token.filter((val) => val.tipo == 'dvss')
              token = token.filter(
                (val) => val.token.split('++++++++')[1] == data[0].id_dvss
              )
              tokensConsultas.dvss.push(token[0].token)

              api.put(`token/${token[0].id_token}`, {
                expiracao: data_consulta,
              })
            } catch (error) {
              console.log(error)
            }
          }),

        //! Diário de Eliminações
        // Diário de Eliminações: Só mudar a data de expiração do token e não fazer nada. Indicar que se não tiver preenchido, tem que preencher até a próxima consulta
        api
          .get(`diario_eliminacao_da_consulta_especifica/${id_consulta}`)
          .then(({ data }) => {
            data.forEach(async (diario) => {
              let token = await api.get('token').then(({ data }) => data)
              try {
                token = token.filter(
                  (val) => val.id_global == consulta.id_paciente
                )
                token = token.filter((val) => val.tipo == 'diario')
                token = token.filter(
                  (val) => val.token.split('++++++++')[1] == diario.id_diario
                )
                tokensConsultas.diario.push(token[0].token)

                api.put(`token/${token[0].id_token}`, {
                  expiracao: data_consulta,
                })
              } catch (error) {
                console.log(error)
              }
            })
          }),
      ]).then(() => {
        Axios.all(
          Object.entries(tokensConsultas).map(async (val) => {
            if (val[0] == 'diario' || val[0] == 'dvss') {
              return val[1]
            } else {
              const result = val[1].tokens

              const forms = await api
                .post('createFormsProx', {
                  id_global: consulta.id_paciente,
                  data_consulta,
                  tipo: val[0],
                  ids_formularios: val[1].idFormulario,
                })
                .then(({ data }) => data)

              forms.forEach((val) => result.push(val))

              return result
            }
          })
        ).then(handleEnviosEmail)
      })

      alert('Consulta remarcada com sucesso.')
      setLoading_animation(false)
      history.go()
    } catch (err) {
      alert('Erro ao Cadastrar Consulta. Tente Novamente.')
    }
  }

  const [mostrarInstrumentos, setMostrarInstrumentos] = useState(false)
  async function handleDarAlta() {
    //! Recupera informações necessárias
    //

    const oldPaciente = await api
      .get(`paciente/${paciente.id_global}`)
      .then(({ data }) => data)
      .catch(console.log)

    const oldPesquisador = await api
      .get(`pesquisador/${oldPaciente.id_pesquisador}`)
      .then(({ data }) => data)
      .catch(console.log)

    const administrador = await api
      .get(`global/1`)
      .then(({ data }) => data)
      .catch(console.log)
    //
    //! Só pode dar alta se o paciente tiver sido aceito
    //
    const ehApto =
      oldPesquisador.id_paciente.toString().split(',').length ===
      oldPesquisador.quantidade_pacientes
    if (!ehApto) {
      alert(
        'Você tem pacientes que não foram aceitos ou recusados. ' +
          'Complete esse pré-requisito e tente dar alta novamente'
      )
      return
    }
    //
    //? Inicie a animação de loading
    setLoading_animation(true)
    //
    //! muda o status do paciente para alta
    //
    await api.post(`/dar-alta/${oldPaciente.id_global}`).catch(console.log)
    //
    //! notifique o administrador sobre a alta
    //
    api
      .post(`send_mail`, {
        nome_destinatario: administrador.nome,
        endereco_email: administrador.email,
        assunto_do_email: `O paciente ${oldPaciente.nome} recebeu alta`,
        texto_do_email: `O paciente ${oldPaciente.nome} recebeu alta na plataforma Enf_UROPED!`,
      })
      .catch(console.log)
    //
    //! retira o paciente do antigo pesquisador
    //
    //? Crio um novo id_paciente e quantidade_pacientes
    const arrayAtualizadaIdsPacientes = oldPesquisador.id_paciente
      .toString()
      .split(',')
      .filter((item) => item !== oldPaciente.id_global.toString())

    const informacoesAtualizadasIdPaciente =
      arrayAtualizadaIdsPacientes.join(',')

    const informacoesAtualizadasQuantidadePacientes =
      arrayAtualizadaIdsPacientes.length

    //? Atualizo o pesquisador
    await api
      .put(`pesquisador/${oldPesquisador.id_global}`, {
        id_paciente: informacoesAtualizadasIdPaciente,
        quantidade_pacientes: informacoesAtualizadasQuantidadePacientes,
      })
      .catch(console.log)
    //
    //! adiciona o paciente ao administrador
    //
    //? Crio um novo id_paciente e quantidade_pacientes para o admin
    //
    const admin = await api
      .get('pesquisador/1')
      .then(({ data }) => data)
      .catch(console.log)

    let arrayAtualizadaIdsPacientesAdmin = admin.id_paciente
      .toString()
      .split(',')

    if (
      arrayAtualizadaIdsPacientesAdmin.length === 1 &&
      arrayAtualizadaIdsPacientesAdmin[0] === ''
    ) {
      arrayAtualizadaIdsPacientesAdmin = []
    }

    arrayAtualizadaIdsPacientesAdmin.push(oldPaciente.id_global)

    const informacoesAtualizadasIdPacienteAdmin =
      arrayAtualizadaIdsPacientesAdmin.join(',')

    let informacoesAtualizadasQuantidadePacientesAdmin =
      arrayAtualizadaIdsPacientesAdmin.length

    //
    await api
      .put('pesquisador/1', {
        id_paciente: informacoesAtualizadasIdPacienteAdmin,
        quantidade_pacientes: informacoesAtualizadasQuantidadePacientesAdmin,
      })
      .catch(console.log)
    //

    alert('Alta dada')
    history.goBack()
  }

  return (
    <div className='top'>
      <PlataformaPadrao
        title={`Consulta do dia ${new Date(consulta.hora_inicio).toLocaleString(
          'pt-BR'
        )}`}
      >
        {!(sae?.id_sae === undefined) ? (
          //se tiver preenchido faz isso

          <Link target='_blank' to={'/sae-preenchido/' + id_consulta}>
            <Button variant='contained'>
              <span>
                <FaClipboardCheck className='icon-right' color='primary' />
                Visualizar Sistematização da Assistência de Enfermagem (SAE)
              </span>
            </Button>
          </Link>
        ) : (
          // se nao tiver preenchido faz isso
          <div className='itenspastaconsulta'>
            <Link target='_blank' to={'/sae/' + id_consulta}>
              <Button variant='contained'>
                <span>
                  <FaClipboardCheck className='icon-right' color='primary' />
                  Preeencher Sistematização da Assistência de Enfermagem (SAE)
                </span>
              </Button>
            </Link>
            <Button
              variant='contained'
              onClick={() => setShowModalRemarcar(true)}
            >
              <pre></pre>
              <pre></pre>
              <span>
                <FaCalendarPlus className='icon-right' color='primary' />
                Remarcar Consulta
              </span>
            </Button>
          </div>
        )}
        <div className='lista-instrumentos-preenchidos'>
          <Button
            variant='contained'
            onClick={() => setShowModalInstrumentos(!mostrarInstrumentos)}
          >
            Mostrar Instrumentos
          </Button>
        </div>
        <Modal
          show={showModalRemarcar}
          onHide={() => setShowModalRemarcar(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Selecione o dia e o horário para remarcar a consulta de{' '}
              {paciente.nome}.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='container'>
              <Autocomplete
                id='escolha-disponibilidade'
                options={disponibilidade}
                getOptionLabel={(horario) =>
                  new Date(horario.hora_inicio).toLocaleString('pt-BR')
                }
                style={{ width: 220 }}
                onChange={(_, newInputValue) => setSelectedDate(newInputValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Selecione o horário'
                    variant='outlined'
                    fullWidth
                  />
                )}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='btn-send'>
              <Button variant='contained' onClick={handleRemarcarConsulta}>
                Remarcar Consulta
              </Button>
              {loading_animation && <Spinner animation='border' />}
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModalInstrumentos}
          onHide={() => setShowModalInstrumentos(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Instrumentos de consulta do paciente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id='instmodal'>
              {showModalInstrumentos && instrumentos_dessa_consulta[0] === '1' && (
                <Button
                  variant='contained'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowModalSolChuva(true)}
                >
                  Sol e Chuva
                </Button>
              )}
              <pre></pre>
              {showModalInstrumentos && instrumentos_dessa_consulta[1] === '1' && (
                <Button
                  variant='contained'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowModalRomaIV(true)}
                >
                  Roma IV
                </Button>
              )}
              <pre></pre>
              {showModalInstrumentos &&
                instrumentos_dessa_consulta[2] === '1' &&
                dvssConsultaEspecifica.map((instrumento, i) => (
                  <Button
                    variant='contained'
                    key={i}
                    className='cada-formulario'
                    href={'/dvss-preenchido/' + instrumento.id_dvss}
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Escore de Sintoma de Disfunção Miccional
                  </Button>
                ))}
              <pre></pre>
              {showModalInstrumentos && instrumentos_dessa_consulta[3] === '1' && (
                <Button
                  variant='contained'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowModalDiarioEliminacao(true)}
                >
                  Diário de Eliminações
                </Button>
              )}
              <pre></pre>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='contained'
              onClick={() => setShowModalInstrumentos(false)}
            >
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModalSolChuva}
          onHide={() => setShowModalSolChuva(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Instrumentos Sol e Chuva dessa consulta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {solChuvaConsultaEspecifica.map((instrumento, i) => (
              <Link
                target='_blank'
                key={i}
                className='cada-formulario'
                to={'/sol-chuva-preenchido/' + instrumento.id_sol_chuva}
              >
                <Button variant='contained'>
                  <span>semana {i + 1}</span>
                </Button>
              </Link>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => setShowModalSolChuva(false)}
            >
              Voltar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModalRomaIV} onHide={() => setShowModalRomaIV(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Instrumentos Roma IV dessa consulta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {romaIVConsultaEspecifica.map((instrumento, i) => (
              <Link
                target='_blank'
                key={i}
                className='cada-formulario'
                to={'/romaiv-preenchido/' + instrumento.id_roma}
              >
                <Button variant='contained'>
                  <span>semana {i + 1}</span>
                </Button>
              </Link>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => setShowModalRomaIV(false)}
            >
              Voltar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModalDvss} onHide={() => setShowModalDvss(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Escore de Sintoma de Disfunção Miccional</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {dvssConsultaEspecifica.map((instrumento, i) => (
              <Link
                target='_blank'
                key={i}
                className='cada-formulario'
                to={'/dvss-preenchido/' + instrumento.id_dvss}
              >
                <Button variant='contained'>
                  <span>escore {i + 1}</span>
                </Button>
              </Link>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setShowModalDvss(false)}>
              Voltar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showModalDiarioEliminacao}
          onHide={() => setShowModalDiarioEliminacao(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Instrumentos Diário de Eliminações dessa consulta
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {diarioEliminacaoConsultaEspecifica.map((instrumento, i) => (
              <Link
                target='_blank'
                key={i}
                className='cada-formulario'
                to={'/diario-eliminacoes-preenchido/' + instrumento.id_diario}
              >
                <Button variant='contained'>
                  <span>dia {i + 1}</span>
                </Button>
              </Link>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => setShowModalDiarioEliminacao(false)}
            >
              Voltar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModalAlta} onHide={() => setShowModalAlta(false)}>
          <Modal.Header id='mdaltapaciente' closeButton>
            <Modal.Title>Dar Alta a esse paciente?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Voce está dando alta a este paciente. Deseja continuar?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => handleDarAlta()}
            >
              DAR ALTA
            </Button>
            {loading_animation && <Spinner animation='border' />}
          </Modal.Footer>
        </Modal>
        <Container>
          <Row>
            <Col>
              {agora.getTime() <
              set(new Date(consulta.hora_inicio), {
                hours: 23,
                minutes: 59,
                seconds: 59,
              }).getTime() ? (
                <Link
                  target='_blank'
                  to={`/marcar-proxima-consulta/${consulta.id_paciente}`}
                >
                  <Button variant='contained'>
                    <span>
                      <FaMedkit className='icon-right' color='primary' />
                      Marcar nova consulta
                    </span>
                  </Button>
                </Link>
              ) : (
                <Button
                  variant='contained'
                  onClick={() => {
                    alert(
                      'A data desta consulta já passou. Você deve remarcar esta consulta, caso a mesma não tenha ocorrido, ou marcar o retorno no mesmo dia que a consulta ocorreu.'
                    )
                  }}
                >
                  <span>
                    <FaMedkit className='icon-right' color='primary' />
                    Marcar nova consulta
                  </span>
                </Button>
              )}
            </Col>
            <Col>
              <Button
                variant='contained'
                onClick={() => setShowModalAlta(true)}
              >
                <span>
                  <FaChild className='icon-right' color='primary' />
                  Dar alta para {paciente.nome}
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </PlataformaPadrao>
    </div>
  )
}
