import { makeStyles } from '@material-ui/core/styles'
import { UserContext } from '../../contexts/user.js'
import TextField from '@material-ui/core/TextField'
import React, { useState, useContext } from 'react'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router'
import api from '../../services/api'
import './styles.css'
import { Spinner } from 'react-bootstrap'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    justifyContent: 'center',
    color: '#000000',
  },
  botao: {
    backgroundColor: '#F54C4C',
  },
  botaosecundario: {
    color: '#F54C4C',
  },
  backLogin: {
    marginTop: '1.2rem',
    cursor: 'pointer',
  },
}))

export default function RedefinicaoSenha() {
  const { user } = useContext(UserContext)
  const history = useHistory()
  const classes = useStyles()

  const [senhaConfirmar, setSenhaConfirmar] = useState('')
  const [senha, setSenha] = useState('')
  const [loading_animation, setLoading_animation] = useState(false)

  const handleRedefinir = (event) => {
    event.preventDefault()

    if (senha === senhaConfirmar) {
      setLoading_animation(true)
      console.log(loading_animation)
      api
        .put(`changePass/${user.id_global}`, { senha })
        .then(() => {
          history.push('/')
        })
        .catch(alert)
    } else {
      alert('As senhas não coincidem')
    }
  }

  return (
    <div className="login-container">
      <div className="lateral-esquerda-login">
        <h1 className="titulo-lateral">BEM-VINDO À PLATAFORMA ENF-UROPED</h1>
      </div>
      <div className="lateral-direita-login">
        <h1 className="titulo-login">Redefinir senha</h1>
        <form className={classes.root} onSubmit={handleRedefinir}>
          <div className="entrada">
            <TextField
              onChange={(e) => setSenha(e.target.value)}
              className={classes.input}
              label="Senha"
              type="password"
              variant="outlined"
              autoComplete="new-password"
              required
            />
          </div>
          <div className="entrada">
            <TextField
              onChange={(e) => setSenhaConfirmar(e.target.value)}
              className={classes.input}
              label="Confirmar senha"
              type="password"
              variant="outlined"
              autoComplete="new-password"
              required
            />
          </div>
          <div className="botoes-entrada">
            <Button
              type="submit"
              className={classes.botao}
              variant="contained"
              color="secondary"
            >
              Redefinir
            </Button>
            {loading_animation && (
              <Spinner animation="border" />
            )
            }
          </div>
        </form>
      </div>
    </div>
  )
}
