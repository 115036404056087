import './styles.css'

import React, { useEffect, useState } from 'react'

import Axios from 'axios'
import ItemListaEstudanteAutenticar from '../../components/ItemListaEstudanteAutenticar'
import ItemListaPesquisadorAutenticar from '../../components/ItemListaPesquisadorAutenticar'
import PlataformaPadrao from '../../components/plataforma-padrao'
import Table from 'react-bootstrap/Table'
import api from '../../services/api'

export default function ListaPesquisador() {
  const [estudante, setEstudante] = useState([])
  const [globalEstudante, setGlobalEstudante] = useState([])
  const [pesquisador, setPesquisador] = useState([])
  const [globalPesquisador, setGlobalPesquisador] = useState([])

  async function refreshList() {
    const dataGlobalPesquisador = await api
      .get(`autenticacao/pesquisador`)
      .then((response1) => response1.data)
      .catch(console.log)

    const dataGlobalEstudante = await api
      .get(`autenticacao/estudante`)
      .then((response2) => response2.data)
      .catch(console.log)

    const dataPesquisador = await Axios.all(
      dataGlobalPesquisador.map((item) =>
        api.get(`pesquisador/${item.id_global}`).then(({ data }) => data)
      )
    )

    const dataEstudante = await Axios.all(
      dataGlobalEstudante.map((item) =>
        api.get(`alunos_graduacao/${item.id_global}`).then(({ data }) => data)
      )
    )

    setEstudante(dataEstudante)
    setGlobalEstudante(dataGlobalEstudante)
    setPesquisador(dataPesquisador)
    setGlobalPesquisador(dataGlobalPesquisador)
  }

  useEffect(refreshList, [])

  return (
    <>
      <PlataformaPadrao title='Autenticação de cadastros'>
        <div className='titulo-da-lista-pesquisadoresE'>
          Enfermeiras(os) a autenticar
        </div>
        <div className='lista-geral-table-wrapper'>
          <Table>
            <thead>
              <th>
                <div>NOME</div>
              </th>
              <th>
                <div>E-MAIL</div>
              </th>
              <th>
                <div>TELEFONE</div>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              {globalPesquisador.map((_, idx) => (
                <ItemListaPesquisadorAutenticar
                  key={idx}
                  excluir={false}
                  editar={false}
                  olhar={true}
                  pesquisador={pesquisador[idx]}
                  globalPesquisador={globalPesquisador[idx]}
                />
              ))}
            </tbody>
          </Table>
        </div>

        <div className='titulo-da-lista-estudantesE'>
          Estudantes a autenticar
        </div>
        <div className='lista-geral-table-wrapper'>
          <Table>
            <thead>
              <th>
                <div>NOME</div>
              </th>
              <th>
                <div>E-MAIL</div>
              </th>
              <th>
                <div>TELEFONE</div>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              {globalEstudante.map((_, idx) => (
                <ItemListaEstudanteAutenticar
                  key={idx}
                  excluir={false}
                  editar={false}
                  olhar={true}
                  estudante={estudante[idx]}
                  globalEstudante={globalEstudante[idx]}
                />
              ))}
            </tbody>
          </Table>
        </div>
      </PlataformaPadrao>
    </>
  )
}
