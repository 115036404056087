import React, { useState, useEffect } from 'react'
import { Navbar, Nav, Form } from 'react-bootstrap'
import './styles.css'
import Logo from '../../assets/logo.png'
import { Link } from 'react-router-dom'
import { FiMenu, FiX } from 'react-icons/fi'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import api from '../../services/api'

export default function NavbarBlog() {
  const [tags, setTags] = useState([])
  const [toggleVariable, setToggleVariable] = useState(true)

  useEffect(async () => {
    await api.get('tags').then((response) => {
      let tagsArray = response.data.map((id) => id['tag'])
      setTags(tagsArray)
    })
  }, [])

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="navbar-main fixed-top"
      variant="dark"
    >
      <Navbar.Brand>
        <div className="navbar-row">
          <Link to="/">
            <img
              alt=""
              src={Logo}
              width="60"
              height="60"
              className="d-inline-block align-top"
            />
          </Link>
          <Link to="/" className="navbar-slogan">
            Prática Avançada de Enfermagem
            <br />
            em Uropediatria
          </Link>
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={()=>{setToggleVariable(!toggleVariable)}}>
        {toggleVariable ? <FiMenu size={30} color="#ffffff" /> : <FiX size={30} color="#ffffff" />}
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <Link to="/" className="navbar-slogan-mobile">
            Prática Avançada de Enfermagem em Uropediatria
          </Link>
          {/* <Link to="/" className="navbar-links" id="link-borda">Nossa História</Link> */}
          <Link to="/" className="navbar-links portal-informacoes">
            Página inicial
          </Link>
          <Link to="/login" className="navbar-links login-cadastro">
            {' '}
            <i class="fas fa-sign-in-alt"></i> Login/Cadastro
          </Link>
          <Form inline>
              <Autocomplete
                id="pesquisa-tags"
                options={tags}
                getOptionLabel={(option) => option}
                style={{ width: 150 }}
                onChange={(_, newInputValue) => {
                  window.location.href = '/blog/' + newInputValue
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pesquisar"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Form>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
