import React, { useState } from 'react'
import './styles.css'
import api from '../../services/api'
import PlataformaPadrao from '../../components/plataforma-padrao'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import ptlocale from 'date-fns/locale/pt-BR'
import nextDay from 'date-fns/nextDay'
import add from 'date-fns/add'
import set from 'date-fns/set'
import Button from '@material-ui/core/Button'

export default function DisponibilidadePesquisador() {
  const history = useHistory()
  let { id_pesquisador } = useParams()

  const [seg7, setSeg7] = useState(false)
  const [seg8, setSeg8] = useState(false)
  const [seg9, setSeg9] = useState(false)
  const [seg10, setSeg10] = useState(false)
  const [seg11, setSeg11] = useState(false)
  const [seg12, setSeg12] = useState(false)
  const [seg13, setSeg13] = useState(false)
  const [seg14, setSeg14] = useState(false)
  const [seg15, setSeg15] = useState(false)
  const [seg16, setSeg16] = useState(false)
  const [seg17, setSeg17] = useState(false)
  const [seg18, setSeg18] = useState(false)
  const [seg19, setSeg19] = useState(false)
  const [seg20, setSeg20] = useState(false)
  const [seg21, setSeg21] = useState(false)
  const [seg22, setSeg22] = useState(false)

  const [ter7, setTer7] = useState(false)
  const [ter8, setTer8] = useState(false)
  const [ter9, setTer9] = useState(false)
  const [ter10, setTer10] = useState(false)
  const [ter11, setTer11] = useState(false)
  const [ter12, setTer12] = useState(false)
  const [ter13, setTer13] = useState(false)
  const [ter14, setTer14] = useState(false)
  const [ter15, setTer15] = useState(false)
  const [ter16, setTer16] = useState(false)
  const [ter17, setTer17] = useState(false)
  const [ter18, setTer18] = useState(false)
  const [ter19, setTer19] = useState(false)
  const [ter20, setTer20] = useState(false)
  const [ter21, setTer21] = useState(false)
  const [ter22, setTer22] = useState(false)

  const [qua7, setQua7] = useState(false)
  const [qua8, setQua8] = useState(false)
  const [qua9, setQua9] = useState(false)
  const [qua10, setQua10] = useState(false)
  const [qua11, setQua11] = useState(false)
  const [qua12, setQua12] = useState(false)
  const [qua13, setQua13] = useState(false)
  const [qua14, setQua14] = useState(false)
  const [qua15, setQua15] = useState(false)
  const [qua16, setQua16] = useState(false)
  const [qua17, setQua17] = useState(false)
  const [qua18, setQua18] = useState(false)
  const [qua19, setQua19] = useState(false)
  const [qua20, setQua20] = useState(false)
  const [qua21, setQua21] = useState(false)
  const [qua22, setQua22] = useState(false)

  const [qui7, setQui7] = useState(false)
  const [qui8, setQui8] = useState(false)
  const [qui9, setQui9] = useState(false)
  const [qui10, setQui10] = useState(false)
  const [qui11, setQui11] = useState(false)
  const [qui12, setQui12] = useState(false)
  const [qui13, setQui13] = useState(false)
  const [qui14, setQui14] = useState(false)
  const [qui15, setQui15] = useState(false)
  const [qui16, setQui16] = useState(false)
  const [qui17, setQui17] = useState(false)
  const [qui18, setQui18] = useState(false)
  const [qui19, setQui19] = useState(false)
  const [qui20, setQui20] = useState(false)
  const [qui21, setQui21] = useState(false)
  const [qui22, setQui22] = useState(false)

  const [sex7, setSex7] = useState(false)
  const [sex8, setSex8] = useState(false)
  const [sex9, setSex9] = useState(false)
  const [sex10, setSex10] = useState(false)
  const [sex11, setSex11] = useState(false)
  const [sex12, setSex12] = useState(false)
  const [sex13, setSex13] = useState(false)
  const [sex14, setSex14] = useState(false)
  const [sex15, setSex15] = useState(false)
  const [sex16, setSex16] = useState(false)
  const [sex17, setSex17] = useState(false)
  const [sex18, setSex18] = useState(false)
  const [sex19, setSex19] = useState(false)
  const [sex20, setSex20] = useState(false)
  const [sex21, setSex21] = useState(false)
  const [sex22, setSex22] = useState(false)

  const [sab7, setSab7] = useState(false)
  const [sab8, setSab8] = useState(false)
  const [sab9, setSab9] = useState(false)
  const [sab10, setSab10] = useState(false)
  const [sab11, setSab11] = useState(false)
  const [sab12, setSab12] = useState(false)
  const [sab13, setSab13] = useState(false)
  const [sab14, setSab14] = useState(false)
  const [sab15, setSab15] = useState(false)
  const [sab16, setSab16] = useState(false)
  const [sab17, setSab17] = useState(false)
  const [sab18, setSab18] = useState(false)
  const [sab19, setSab19] = useState(false)
  const [sab20, setSab20] = useState(false)
  const [sab21, setSab21] = useState(false)
  const [sab22, setSab22] = useState(false)

  const [selectedDate, setSelectedDate] = useState(new Date())
  const agora = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
  let proximasDatas = []

  function setRealDates() {
    if (seg7) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 7 }))
    }
    if (seg8) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 8 }))
    }
    if (seg9) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 9 }))
    }
    if (seg10) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 10 }))
    }
    if (seg11) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 11 }))
    }
    if (seg12) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 12 }))
    }
    if (seg13) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 13 }))
    }
    if (seg14) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 14 }))
    }
    if (seg15) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 15 }))
    }
    if (seg16) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 16 }))
    }
    if (seg17) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 17 }))
    }
    if (seg18) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 18 }))
    }
    if (seg19) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 19 }))
    }
    if (seg20) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 20 }))
    }
    if (seg21) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 21 }))
    }
    if (seg22) {
      proximasDatas.push(add(nextDay(agora, 1), { hours: 22 }))
    }
    if (ter7) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 7 }))
    }
    if (ter8) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 8 }))
    }
    if (ter9) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 9 }))
    }
    if (ter10) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 10 }))
    }
    if (ter11) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 11 }))
    }
    if (ter12) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 12 }))
    }
    if (ter13) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 13 }))
    }
    if (ter14) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 14 }))
    }
    if (ter15) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 15 }))
    }
    if (ter16) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 16 }))
    }
    if (ter17) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 17 }))
    }
    if (ter18) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 18 }))
    }
    if (ter19) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 19 }))
    }
    if (ter20) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 20 }))
    }
    if (ter21) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 21 }))
    }
    if (ter22) {
      proximasDatas.push(add(nextDay(agora, 2), { hours: 22 }))
    }
    if (qua7) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 7 }))
    }
    if (qua8) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 8 }))
    }
    if (qua9) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 9 }))
    }
    if (qua10) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 10 }))
    }
    if (qua11) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 11 }))
    }
    if (qua12) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 12 }))
    }
    if (qua13) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 13 }))
    }
    if (qua14) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 14 }))
    }
    if (qua15) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 15 }))
    }
    if (qua16) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 16 }))
    }
    if (qua17) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 17 }))
    }
    if (qua18) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 18 }))
    }
    if (qua19) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 19 }))
    }
    if (qua20) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 20 }))
    }
    if (qua21) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 21 }))
    }
    if (qua22) {
      proximasDatas.push(add(nextDay(agora, 3), { hours: 22 }))
    }
    if (qui7) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 7 }))
    }
    if (qui8) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 8 }))
    }
    if (qui9) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 9 }))
    }
    if (qui10) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 10 }))
    }
    if (qui11) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 11 }))
    }
    if (qui12) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 12 }))
    }
    if (qui13) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 13 }))
    }
    if (qui14) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 14 }))
    }
    if (qui15) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 15 }))
    }
    if (qui16) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 16 }))
    }
    if (qui17) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 17 }))
    }
    if (qui18) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 18 }))
    }
    if (qui19) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 19 }))
    }
    if (qui20) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 20 }))
    }
    if (qui21) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 21 }))
    }
    if (qui22) {
      proximasDatas.push(add(nextDay(agora, 4), { hours: 22 }))
    }
    if (sex7) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 7 }))
    }
    if (sex8) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 8 }))
    }
    if (sex9) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 9 }))
    }
    if (sex10) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 10 }))
    }
    if (sex11) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 11 }))
    }
    if (sex12) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 12 }))
    }
    if (sex13) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 13 }))
    }
    if (sex14) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 14 }))
    }
    if (sex15) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 15 }))
    }
    if (sex16) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 16 }))
    }
    if (sex17) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 17 }))
    }
    if (sex18) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 18 }))
    }
    if (sex19) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 19 }))
    }
    if (sex20) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 20 }))
    }
    if (sex21) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 21 }))
    }
    if (sex22) {
      proximasDatas.push(add(nextDay(agora, 5), { hours: 22 }))
    }
    if (sab7) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 7 }))
    }
    if (sab8) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 8 }))
    }
    if (sab9) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 9 }))
    }
    if (sab10) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 10 }))
    }
    if (sab11) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 11 }))
    }
    if (sab12) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 12 }))
    }
    if (sab13) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 13 }))
    }
    if (sab14) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 14 }))
    }
    if (sab15) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 15 }))
    }
    if (sab16) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 16 }))
    }
    if (sab17) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 17 }))
    }
    if (sab18) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 18 }))
    }
    if (sab19) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 19 }))
    }
    if (sab20) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 20 }))
    }
    if (sab21) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 21 }))
    }
    if (sab22) {
      proximasDatas.push(add(nextDay(agora, 6), { hours: 22 }))
    }
  }

  function registroDisponibilidade() {
    setRealDates()
    proximasDatas.map((hora_inicio) => {
      while (hora_inicio <= selectedDate) {
        //registrar no banco
        const dados = {
          id_pesquisador,
          hora_inicio,
        }
        try {
          const response = api.post('disponibilidade', dados)
        } catch (err) {
          alert('Erro ao cadastrar disponibilidade, tente novamente.')
        }
        //adicionar 1 semana na selectedDate
        hora_inicio = add(hora_inicio, { weeks: 1 })
      }
    })
    alert('Disponibilidades cadastradas com sucesso!')
    history.goBack()
  }

  const handleDateChange = (date) => {
    setSelectedDate(
      set(date, { hours: 22, minutes: 0, seconds: 0, milliseconds: 0 })
    )
  }

  return (
    <PlataformaPadrao
      title="Informar disponibilidade"
      legend="Informar disponibilidade semanal"
    >
      <div className="dia-disponibilidade">
        <div className="texto-pícker">Informar disponibilidade até</div>
        <div className="picker">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptlocale}>
            <DatePicker
              disablePast
              format="eeee, dd/MM/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      <div className="grade-horaria">
        <div className="linha-grade dias-da-semana">
          <div className="dias-da-semana-seg item-grade-titulo">Segunda</div>
          <div className="dias-da-semana-ter item-grade-titulo">Terça</div>
          <div className="dias-da-semana-qua item-grade-titulo">Quarta</div>
          <div className="dias-da-semana-qui item-grade-titulo">Quinta</div>
          <div className="dias-da-semana-sex item-grade-titulo">Sexta</div>
          <div className="dias-da-semana-sab item-grade-titulo">Sábado</div>
        </div>
        <div className="linha-grade sete-horas">
          <div
            onClick={() => setSeg7(!seg7)}
            className={
              seg7 === true
                ? 'sete-horas-seg item-grade marcado'
                : 'sete-horas-seg item-grade'
            }
          >
            07:00
          </div>
          <div
            onClick={() => setTer7(!ter7)}
            className={
              ter7 === true
                ? 'sete-horas-ter item-grade marcado'
                : 'sete-horas-ter item-grade'
            }
          >
            07:00
          </div>
          <div
            onClick={() => setQua7(!qua7)}
            className={
              qua7 === true
                ? 'sete-horas-qua item-grade marcado'
                : 'sete-horas-qua item-grade'
            }
          >
            07:00
          </div>
          <div
            onClick={() => setQui7(!qui7)}
            className={
              qui7 === true
                ? 'sete-horas-qui item-grade marcado'
                : 'sete-horas-qui item-grade'
            }
          >
            07:00
          </div>
          <div
            onClick={() => setSex7(!sex7)}
            className={
              sex7 === true
                ? 'sete-horas-sex item-grade marcado'
                : 'sete-horas-sex item-grade'
            }
          >
            07:00
          </div>
          <div
            onClick={() => setSab7(!sab7)}
            className={
              sab7 === true
                ? 'sete-horas-sab item-grade marcado'
                : 'sete-horas-sab item-grade'
            }
          >
            07:00
          </div>
        </div>
        <div className="linha-grade oito-horas">
          <div
            onClick={() => setSeg8(!seg8)}
            className={
              seg8 === true
                ? 'oito-horas-seg item-grade marcado'
                : 'oito-horas-seg item-grade'
            }
          >
            08:00
          </div>
          <div
            onClick={() => setTer8(!ter8)}
            className={
              ter8 === true
                ? 'oito-horas-ter item-grade marcado'
                : 'oito-horas-ter item-grade'
            }
          >
            08:00
          </div>
          <div
            onClick={() => setQua8(!qua8)}
            className={
              qua8 === true
                ? 'oito-horas-qua item-grade marcado'
                : 'oito-horas-qua item-grade'
            }
          >
            08:00
          </div>
          <div
            onClick={() => setQui8(!qui8)}
            className={
              qui8 === true
                ? 'oito-horas-qui item-grade marcado'
                : 'oito-horas-qui item-grade'
            }
          >
            08:00
          </div>
          <div
            onClick={() => setSex8(!sex8)}
            className={
              sex8 === true
                ? 'oito-horas-sex item-grade marcado'
                : 'oito-horas-sex item-grade'
            }
          >
            08:00
          </div>
          <div
            onClick={() => setSab8(!sab8)}
            className={
              sab8 === true
                ? 'oito-horas-sab item-grade marcado'
                : 'oito-horas-sab item-grade'
            }
          >
            08:00
          </div>
        </div>
        <div className="linha-grade nove-horas">
          <div
            onClick={() => setSeg9(!seg9)}
            className={
              seg9 === true
                ? 'nove-horas-seg item-grade marcado'
                : 'nove-horas-seg item-grade'
            }
          >
            09:00
          </div>
          <div
            onClick={() => setTer9(!ter9)}
            className={
              ter9 === true
                ? 'nove-horas-ter item-grade marcado'
                : 'nove-horas-ter item-grade'
            }
          >
            09:00
          </div>
          <div
            onClick={() => setQua9(!qua9)}
            className={
              qua9 === true
                ? 'nove-horas-qua item-grade marcado'
                : 'nove-horas-qua item-grade'
            }
          >
            09:00
          </div>
          <div
            onClick={() => setQui9(!qui9)}
            className={
              qui9 === true
                ? 'nove-horas-qui item-grade marcado'
                : 'nove-horas-qui item-grade'
            }
          >
            09:00
          </div>
          <div
            onClick={() => setSex9(!sex9)}
            className={
              sex9 === true
                ? 'nove-horas-sex item-grade marcado'
                : 'nove-horas-sex item-grade'
            }
          >
            09:00
          </div>
          <div
            onClick={() => setSab9(!sab9)}
            className={
              sab9 === true
                ? 'nove-horas-sab item-grade marcado'
                : 'nove-horas-sab item-grade'
            }
          >
            09:00
          </div>
        </div>
        <div className="linha-grade dez-horas">
          <div
            onClick={() => setSeg10(!seg10)}
            className={
              seg10 === true
                ? 'dez-horas-seg item-grade marcado'
                : 'dez-horas-seg item-grade'
            }
          >
            10:00
          </div>
          <div
            onClick={() => setTer10(!ter10)}
            className={
              ter10 === true
                ? 'dez-horas-ter item-grade marcado'
                : 'dez-horas-ter item-grade'
            }
          >
            10:00
          </div>
          <div
            onClick={() => setQua10(!qua10)}
            className={
              qua10 === true
                ? 'dez-horas-qua item-grade marcado'
                : 'dez-horas-qua item-grade'
            }
          >
            10:00
          </div>
          <div
            onClick={() => setQui10(!qui10)}
            className={
              qui10 === true
                ? 'dez-horas-qui item-grade marcado'
                : 'dez-horas-qui item-grade'
            }
          >
            10:00
          </div>
          <div
            onClick={() => setSex10(!sex10)}
            className={
              sex10 === true
                ? 'dez-horas-sex item-grade marcado'
                : 'dez-horas-sex item-grade'
            }
          >
            10:00
          </div>
          <div
            onClick={() => setSab10(!sab10)}
            className={
              sab10 === true
                ? 'dez-horas-sab item-grade marcado'
                : 'dez-horas-sab item-grade'
            }
          >
            10:00
          </div>
        </div>
        <div className="linha-grade onze-horas">
          <div
            onClick={() => setSeg11(!seg11)}
            className={
              seg11 === true
                ? 'onze-horas-seg item-grade marcado'
                : 'onze-horas-seg item-grade'
            }
          >
            11:00
          </div>
          <div
            onClick={() => setTer11(!ter11)}
            className={
              ter11 === true
                ? 'onze-horas-ter item-grade marcado'
                : 'onze-horas-ter item-grade'
            }
          >
            11:00
          </div>
          <div
            onClick={() => setQua11(!qua11)}
            className={
              qua11 === true
                ? 'onze-horas-qua item-grade marcado'
                : 'onze-horas-qua item-grade'
            }
          >
            11:00
          </div>
          <div
            onClick={() => setQui11(!qui11)}
            className={
              qui11 === true
                ? 'onze-horas-qui item-grade marcado'
                : 'onze-horas-qui item-grade'
            }
          >
            11:00
          </div>
          <div
            onClick={() => setSex11(!sex11)}
            className={
              sex11 === true
                ? 'onze-horas-sex item-grade marcado'
                : 'onze-horas-sex item-grade'
            }
          >
            11:00
          </div>
          <div
            onClick={() => setSab11(!sab11)}
            className={
              sab11 === true
                ? 'onze-horas-sab item-grade marcado'
                : 'onze-horas-sab item-grade'
            }
          >
            11:00
          </div>
        </div>
        <div className="linha-grade doze-horas">
          <div
            onClick={() => setSeg12(!seg12)}
            className={
              seg12 === true
                ? 'doze-horas-seg item-grade marcado'
                : 'doze-horas-seg item-grade'
            }
          >
            12:00
          </div>
          <div
            onClick={() => setTer12(!ter12)}
            className={
              ter12 === true
                ? 'doze-horas-ter item-grade marcado'
                : 'doze-horas-ter item-grade'
            }
          >
            12:00
          </div>
          <div
            onClick={() => setQua12(!qua12)}
            className={
              qua12 === true
                ? 'doze-horas-qua item-grade marcado'
                : 'doze-horas-qua item-grade'
            }
          >
            12:00
          </div>
          <div
            onClick={() => setQui12(!qui12)}
            className={
              qui12 === true
                ? 'doze-horas-qui item-grade marcado'
                : 'doze-horas-qui item-grade'
            }
          >
            12:00
          </div>
          <div
            onClick={() => setSex12(!sex12)}
            className={
              sex12 === true
                ? 'doze-horas-sex item-grade marcado'
                : 'doze-horas-sex item-grade'
            }
          >
            12:00
          </div>
          <div
            onClick={() => setSab12(!sab12)}
            className={
              sab12 === true
                ? 'doze-horas-sab item-grade marcado'
                : 'doze-horas-sab item-grade'
            }
          >
            12:00
          </div>
        </div>
        <div className="linha-grade treze-horas">
          <div
            onClick={() => setSeg13(!seg13)}
            className={
              seg13 === true
                ? 'treze-horas-seg item-grade marcado'
                : 'treze-horas-seg item-grade'
            }
          >
            13:00
          </div>
          <div
            onClick={() => setTer13(!ter13)}
            className={
              ter13 === true
                ? 'treze-horas-ter item-grade marcado'
                : 'treze-horas-ter item-grade'
            }
          >
            13:00
          </div>
          <div
            onClick={() => setQua13(!qua13)}
            className={
              qua13 === true
                ? 'treze-horas-qua item-grade marcado'
                : 'treze-horas-qua item-grade'
            }
          >
            13:00
          </div>
          <div
            onClick={() => setQui13(!qui13)}
            className={
              qui13 === true
                ? 'treze-horas-qui item-grade marcado'
                : 'treze-horas-qui item-grade'
            }
          >
            13:00
          </div>
          <div
            onClick={() => setSex13(!sex13)}
            className={
              sex13 === true
                ? 'treze-horas-sex item-grade marcado'
                : 'treze-horas-sex item-grade'
            }
          >
            13:00
          </div>
          <div
            onClick={() => setSab13(!sab13)}
            className={
              sab13 === true
                ? 'treze-horas-sab item-grade marcado'
                : 'treze-horas-sab item-grade'
            }
          >
            13:00
          </div>
        </div>
        <div className="linha-grade catorze-horas">
          <div
            onClick={() => setSeg14(!seg14)}
            className={
              seg14 === true
                ? 'catorze-horas-seg item-grade marcado'
                : 'catorze-horas-seg item-grade'
            }
          >
            14:00
          </div>
          <div
            onClick={() => setTer14(!ter14)}
            className={
              ter14 === true
                ? 'catorze-horas-ter item-grade marcado'
                : 'catorze-horas-ter item-grade'
            }
          >
            14:00
          </div>
          <div
            onClick={() => setQua14(!qua14)}
            className={
              qua14 === true
                ? 'catorze-horas-qua item-grade marcado'
                : 'catorze-horas-qua item-grade'
            }
          >
            14:00
          </div>
          <div
            onClick={() => setQui14(!qui14)}
            className={
              qui14 === true
                ? 'catorze-horas-qui item-grade marcado'
                : 'catorze-horas-qui item-grade'
            }
          >
            14:00
          </div>
          <div
            onClick={() => setSex14(!sex14)}
            className={
              sex14 === true
                ? 'catorze-horas-sex item-grade marcado'
                : 'catorze-horas-sex item-grade'
            }
          >
            14:00
          </div>
          <div
            onClick={() => setSab14(!sab14)}
            className={
              sab14 === true
                ? 'catorze-horas-sab item-grade marcado'
                : 'catorze-horas-sab item-grade'
            }
          >
            14:00
          </div>
        </div>
        <div className="linha-grade quinze-horas">
          <div
            onClick={() => setSeg15(!seg15)}
            className={
              seg15 === true
                ? 'quinze-horas-seg item-grade marcado'
                : 'quinze-horas-seg item-grade'
            }
          >
            15:00
          </div>
          <div
            onClick={() => setTer15(!ter15)}
            className={
              ter15 === true
                ? 'quinze-horas-ter item-grade marcado'
                : 'quinze-horas-ter item-grade'
            }
          >
            15:00
          </div>
          <div
            onClick={() => setQua15(!qua15)}
            className={
              qua15 === true
                ? 'quinze-horas-qua item-grade marcado'
                : 'quinze-horas-qua item-grade'
            }
          >
            15:00
          </div>
          <div
            onClick={() => setQui15(!qui15)}
            className={
              qui15 === true
                ? 'quinze-horas-qui item-grade marcado'
                : 'quinze-horas-qui item-grade'
            }
          >
            15:00
          </div>
          <div
            onClick={() => setSex15(!sex15)}
            className={
              sex15 === true
                ? 'quinze-horas-sex item-grade marcado'
                : 'quinze-horas-sex item-grade'
            }
          >
            15:00
          </div>
          <div
            onClick={() => setSab15(!sab15)}
            className={
              sab15 === true
                ? 'quinze-horas-sab item-grade marcado'
                : 'quinze-horas-sab item-grade'
            }
          >
            15:00
          </div>
        </div>
        <div className="linha-grade dezesseis-horas">
          <div
            onClick={() => setSeg16(!seg16)}
            className={
              seg16 === true
                ? 'dezesseis-horas-seg item-grade marcado'
                : 'dezesseis-horas-seg item-grade'
            }
          >
            16:00
          </div>
          <div
            onClick={() => setTer16(!ter16)}
            className={
              ter16 === true
                ? 'dezesseis-horas-ter item-grade marcado'
                : 'dezesseis-horas-ter item-grade'
            }
          >
            16:00
          </div>
          <div
            onClick={() => setQua16(!qua16)}
            className={
              qua16 === true
                ? 'dezesseis-horas-qua item-grade marcado'
                : 'dezesseis-horas-qua item-grade'
            }
          >
            16:00
          </div>
          <div
            onClick={() => setQui16(!qui16)}
            className={
              qui16 === true
                ? 'dezesseis-horas-qui item-grade marcado'
                : 'dezesseis-horas-qui item-grade'
            }
          >
            16:00
          </div>
          <div
            onClick={() => setSex16(!sex16)}
            className={
              sex16 === true
                ? 'dezesseis-horas-sex item-grade marcado'
                : 'dezesseis-horas-sex item-grade'
            }
          >
            16:00
          </div>
          <div
            onClick={() => setSab16(!sab16)}
            className={
              sab16 === true
                ? 'dezesseis-horas-sab item-grade marcado'
                : 'dezesseis-horas-sab item-grade'
            }
          >
            16:00
          </div>
        </div>
        <div className="linha-grade dezessete-horas">
          <div
            onClick={() => setSeg17(!seg17)}
            className={
              seg17 === true
                ? 'dezessete-horas-seg item-grade marcado'
                : 'dezessete-horas-seg item-grade'
            }
          >
            17:00
          </div>
          <div
            onClick={() => setTer17(!ter17)}
            className={
              ter17 === true
                ? 'dezessete-horas-ter item-grade marcado'
                : 'dezessete-horas-ter item-grade'
            }
          >
            17:00
          </div>
          <div
            onClick={() => setQua17(!qua17)}
            className={
              qua17 === true
                ? 'dezessete-horas-qua item-grade marcado'
                : 'dezessete-horas-qua item-grade'
            }
          >
            17:00
          </div>
          <div
            onClick={() => setQui17(!qui17)}
            className={
              qui17 === true
                ? 'dezessete-horas-qui item-grade marcado'
                : 'dezessete-horas-qui item-grade'
            }
          >
            17:00
          </div>
          <div
            onClick={() => setSex17(!sex17)}
            className={
              sex17 === true
                ? 'dezessete-horas-sex item-grade marcado'
                : 'dezessete-horas-sex item-grade'
            }
          >
            17:00
          </div>
          <div
            onClick={() => setSab17(!sab17)}
            className={
              sab17 === true
                ? 'dezessete-horas-sab item-grade marcado'
                : 'dezessete-horas-sab item-grade'
            }
          >
            17:00
          </div>
        </div>
        <div className="linha-grade dezoito-horas">
          <div
            onClick={() => setSeg18(!seg18)}
            className={
              seg18 === true
                ? 'dezoito-horas-seg item-grade marcado'
                : 'dezoito-horas-seg item-grade'
            }
          >
            18:00
          </div>
          <div
            onClick={() => setTer18(!ter18)}
            className={
              ter18 === true
                ? 'dezoito-horas-ter item-grade marcado'
                : 'dezoito-horas-ter item-grade'
            }
          >
            18:00
          </div>
          <div
            onClick={() => setQua18(!qua18)}
            className={
              qua18 === true
                ? 'dezoito-horas-qua item-grade marcado'
                : 'dezoito-horas-qua item-grade'
            }
          >
            18:00
          </div>
          <div
            onClick={() => setQui18(!qui18)}
            className={
              qui18 === true
                ? 'dezoito-horas-qui item-grade marcado'
                : 'dezoito-horas-qui item-grade'
            }
          >
            18:00
          </div>
          <div
            onClick={() => setSex18(!sex18)}
            className={
              sex18 === true
                ? 'dezoito-horas-sex item-grade marcado'
                : 'dezoito-horas-sex item-grade'
            }
          >
            18:00
          </div>
          <div
            onClick={() => setSab18(!sab18)}
            className={
              sab18 === true
                ? 'dezoito-horas-sab item-grade marcado'
                : 'dezoito-horas-sab item-grade'
            }
          >
            18:00
          </div>
        </div>
        <div className="linha-grade dezenove-horas">
          <div
            onClick={() => setSeg19(!seg19)}
            className={
              seg19 === true
                ? 'dezenove-horas-seg item-grade marcado'
                : 'dezenove-horas-seg item-grade'
            }
          >
            19:00
          </div>
          <div
            onClick={() => setTer19(!ter19)}
            className={
              ter19 === true
                ? 'dezenove-horas-ter item-grade marcado'
                : 'dezenove-horas-ter item-grade'
            }
          >
            19:00
          </div>
          <div
            onClick={() => setQua19(!qua19)}
            className={
              qua19 === true
                ? 'dezenove-horas-qua item-grade marcado'
                : 'dezenove-horas-qua item-grade'
            }
          >
            19:00
          </div>
          <div
            onClick={() => setQui19(!qui19)}
            className={
              qui19 === true
                ? 'dezenove-horas-qui item-grade marcado'
                : 'dezenove-horas-qui item-grade'
            }
          >
            19:00
          </div>
          <div
            onClick={() => setSex19(!sex19)}
            className={
              sex19 === true
                ? 'dezenove-horas-sex item-grade marcado'
                : 'dezenove-horas-sex item-grade'
            }
          >
            19:00
          </div>
          <div
            onClick={() => setSab19(!sab19)}
            className={
              sab19 === true
                ? 'dezenove-horas-sab item-grade marcado'
                : 'dezenove-horas-sab item-grade'
            }
          >
            19:00
          </div>
        </div>
        <div className="linha-grade vinte-horas">
          <div
            onClick={() => setSeg20(!seg20)}
            className={
              seg20 === true
                ? 'vinte-horas-seg item-grade marcado'
                : 'vinte-horas-seg item-grade'
            }
          >
            20:00
          </div>
          <div
            onClick={() => setTer20(!ter20)}
            className={
              ter20 === true
                ? 'vinte-horas-ter item-grade marcado'
                : 'vinte-horas-ter item-grade'
            }
          >
            20:00
          </div>
          <div
            onClick={() => setQua20(!qua20)}
            className={
              qua20 === true
                ? 'vinte-horas-qua item-grade marcado'
                : 'vinte-horas-qua item-grade'
            }
          >
            20:00
          </div>
          <div
            onClick={() => setQui20(!qui20)}
            className={
              qui20 === true
                ? 'vinte-horas-qui item-grade marcado'
                : 'vinte-horas-qui item-grade'
            }
          >
            20:00
          </div>
          <div
            onClick={() => setSex20(!sex20)}
            className={
              sex20 === true
                ? 'vinte-horas-sex item-grade marcado'
                : 'vinte-horas-sex item-grade'
            }
          >
            20:00
          </div>
          <div
            onClick={() => setSab20(!sab20)}
            className={
              sab20 === true
                ? 'vinte-horas-sab item-grade marcado'
                : 'vinte-horas-sab item-grade'
            }
          >
            20:00
          </div>
        </div>
        <div className="linha-grade vinteuma-horas">
          <div
            onClick={() => setSeg21(!seg21)}
            className={
              seg21 === true
                ? 'vinteuma-horas-seg item-grade marcado'
                : 'vinteuma-horas-seg item-grade'
            }
          >
            21:00
          </div>
          <div
            onClick={() => setTer21(!ter21)}
            className={
              ter21 === true
                ? 'vinteuma-horas-ter item-grade marcado'
                : 'vinteuma-horas-ter item-grade'
            }
          >
            21:00
          </div>
          <div
            onClick={() => setQua21(!qua21)}
            className={
              qua21 === true
                ? 'vinteuma-horas-qua item-grade marcado'
                : 'vinteuma-horas-qua item-grade'
            }
          >
            21:00
          </div>
          <div
            onClick={() => setQui21(!qui21)}
            className={
              qui21 === true
                ? 'vinteuma-horas-qui item-grade marcado'
                : 'vinteuma-horas-qui item-grade'
            }
          >
            21:00
          </div>
          <div
            onClick={() => setSex21(!sex21)}
            className={
              sex21 === true
                ? 'vinteuma-horas-sex item-grade marcado'
                : 'vinteuma-horas-sex item-grade'
            }
          >
            21:00
          </div>
          <div
            onClick={() => setSab21(!sab21)}
            className={
              sab21 === true
                ? 'vinteuma-horas-sab item-grade marcado'
                : 'vinteuma-horas-sab item-grade'
            }
          >
            21:00
          </div>
        </div>
        <div className="linha-grade vinteduas-horas">
          <div
            onClick={() => setSeg22(!seg22)}
            className={
              seg22 === true
                ? 'vinteduas-horas-seg item-grade marcado'
                : 'vinteduas-horas-seg item-grade'
            }
          >
            22:00
          </div>
          <div
            onClick={() => setTer22(!ter22)}
            className={
              ter22 === true
                ? 'vinteduas-horas-ter item-grade marcado'
                : 'vinteduas-horas-ter item-grade'
            }
          >
            22:00
          </div>
          <div
            onClick={() => setQua22(!qua22)}
            className={
              qua22 === true
                ? 'vinteduas-horas-qua item-grade marcado'
                : 'vinteduas-horas-qua item-grade'
            }
          >
            22:00
          </div>
          <div
            onClick={() => setQui22(!qui22)}
            className={
              qui22 === true
                ? 'vinteduas-horas-qui item-grade marcado'
                : 'vinteduas-horas-qui item-grade'
            }
          >
            22:00
          </div>
          <div
            onClick={() => setSex22(!sex22)}
            className={
              sex22 === true
                ? 'vinteduas-horas-sex item-grade marcado'
                : 'vinteduas-horas-sex item-grade'
            }
          >
            22:00
          </div>
          <div
            onClick={() => setSab22(!sab22)}
            className={
              sab22 === true
                ? 'vinteduas-horas-sab item-grade marcado'
                : 'vinteduas-horas-sab item-grade'
            }
          >
            22:00
          </div>
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => registroDisponibilidade()}
      >
        Enviar
      </Button>
    </PlataformaPadrao>
  )
}
