import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import './styles.css'
import api from '../../services/api'
import { Form, Spinner } from 'react-bootstrap'
import ItemListaPacientesAlta from '../../components/ItemListaPacientesAlta'
import PlataformaPadrao from '../../components/plataforma-padrao'
import { useParams } from 'react-router'
import Table from 'react-bootstrap/Table'

export default function ListaPacientesAlta() {
  //const [loading, setLoading] = useState(true);
  const [pacientes_alta, setPacientes_alta] = useState([])

  useEffect(async () => {
    api
      .get('pacientes-alta')
      .then((response) => setPacientes_alta(response.data))
  }, [])

  return (
    <>
      <PlataformaPadrao title="Pacientes que Tiveram Alta">
        <p id="aceitarcn">Lista de pacientes que tiveram alta</p>
        <div className="lista-geral-table-wrapper">
          <Table >
            <thead>
              <th>
                <div>NOME</div>
              </th>
              <th>
                <div>E-MAIL</div>
              </th>
              <th>
                <div>ID</div>
              </th>
              <th>
              </th>
              <th>
              </th>
              <th>
              </th>
              <th>
              </th>
            </thead>
            <tbody>
              {pacientes_alta.map((paciente) => (
                <>
                  <ItemListaPacientesAlta
                    excluir={false}
                    editar={false}
                    olhar={true}
                    paciente={paciente}
                  />
                </>
              ))}
            </tbody>
          </Table>
        </div>

      </PlataformaPadrao>
    </>
  )
}
